/*
    The below method generates array of Action Creators on input of array of Action Types
    Action Types should be in Screaming Snake Case and starting with SET or any other one word prefix, e.g SET_MY_DATA 
*/
export const getActionCreators = (arrayOfActionTypes) => {
    let objectOfActionCreators = {};
    for(let i=0; i<arrayOfActionTypes.length; i++){
        objectOfActionCreators[convertActionTypeToActionCreatorName(arrayOfActionTypes[i])] = (data) => {
            return({
                type: arrayOfActionTypes[i],
                data: data
            })
        }
    }
    return objectOfActionCreators;
}

function convertActionTypeToActionCreatorName(actionType){
    actionType = actionType.toLowerCase();
    let words = actionType.split("_");
    let functionName = "";
    for(let i=0; i<words.length; i++){
        if(i==0){
            functionName = words[i];
        }
        else{
            functionName = functionName + capitalizeFirstLetter(words[i]);
        }
    }
    return functionName;
}

function capitalizeFirstLetter(word){
    return word.charAt(0).toUpperCase() + word.slice(1);
}

/*
    The below method generates two important elements of a reducer method on input of array of Action Types.
    (Action Types should be in Screaming Snake Case and starting with SET or any other one word prefix, e.g SET_MY_DATA )
    1> reducerState - This is an object having properties derived from action types e.g SET_MY_DATA outputs property "myData"
    2> propertyObject - THis is an object containing key-value pairs where key is action type and value is reducer state property.
*/
export const getReducerData = (arrayOfActionTypes) => {
    let reducerState = {};
    let propertyNameObject = {};
    for(let i=0; i<arrayOfActionTypes.length; i++){
        let propertyName = convertActionTypeToReducerStateProperty(arrayOfActionTypes[i])
        reducerState[propertyName] = undefined;
        propertyNameObject[arrayOfActionTypes[i]] = propertyName;
    }
    return {"initialState": reducerState, "actionsProperties": propertyNameObject};
}

function convertActionTypeToReducerStateProperty(actionType){
    actionType = actionType.toLowerCase();
    let words = actionType.split("_");
    let reducerProperty = "";
    for(let i=1; i<words.length; i++){
        if(i==1){
            reducerProperty = words[i];
        }
        else{
            reducerProperty = reducerProperty + capitalizeFirstLetter(words[i]);
        }
    }
    return reducerProperty;
}

export default {
    getActionCreators,
    getReducerData,
}