import { SET_APP_HEADER_DATA } from "../actionTypes";

export default function appHeaderReducer(
  state = {
  },
  action
) {
  switch (action.type) {
    case SET_APP_HEADER_DATA:
      return Object.assign({}, state, {
        ...action.data,
      });

    default:
      return state;
  }
}
