import React from "react";
import { injectIntl } from "react-intl";
import TextField from "../TextField";

const Signup = (props) => {
  const { username, password, confirmPassword, firstName, lastName, phoneNumber, gender, dob, errors, handleSubmit, handleChange } = props;
  return (
    <div className="Flex-col">
      <div className="login-card">
        <div class="Flex-col">
          <h1>
          サインアップ
          </h1>
        </div>

        <TextField name="username"
          value={username}
          onChange={handleChange}
          errorText={errors?.username} placeholder="Eメールアドレス" />

        <TextField type="password"
          name="password"
          value={password}
          onChange={handleChange}
          errorText={errors?.password} placeholder="パスワード" />

        <TextField type="password"
          name="confirmPassword"
          value={confirmPassword}
          onChange={handleChange}
          errorText={errors?.confirmPassword} placeholder="パスワード（確認用）" />

        <TextField type="text"
          name="lastName"
          value={lastName}
          onChange={handleChange}
          errorText={errors?.lastName} placeholder="姓" />

        <TextField type="text"
          name="firstName"
          value={firstName}
          onChange={handleChange}
          errorText={errors?.firstName} placeholder="名" />


        <div>
          <button onClick={handleSubmit} className="btn btn-primary custom-btn" >サインアップ</button>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(Signup);
