import React, { Component } from 'react';
import MyCardDetailsTemplete from '../../components/MyCard/myCardDetailTemplete';
import history from '../../history';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as myCardActions from '../../redux/actions/myCardActions';
import * as appHeaderActions from "../../redux/actions/appHeaderActions";


class MyCardDetails extends Component {
    constructor(props, context) {
        super(props, context);

    }

    componentDidMount() {
        if (this.props.location.state) {
            let orderId = this.props.location.state.orderId;
            this.props.mycardActions.getOrderDetails(orderId);
        }
        else {
            history.push("my-card");
        }
        this.props.appHeaderActions.setData({ title: null, isBack: true });
    }

    render() {
        const isActiveCardShown = this.props?.location?.state?.isActiveCardShown;
        const isSent = this.props?.location?.state?.isSent;
        return (
            <>
                <MyCardDetailsTemplete
                    orderDetails={this.props.orderDetails}
                    isActiveCardShown={isActiveCardShown}
                    isSent={isSent}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        orderDetails: state.myCardReducer.orderDetails,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        mycardActions: bindActionCreators(myCardActions, dispatch),
        appHeaderActions: bindActionCreators(appHeaderActions, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MyCardDetails);