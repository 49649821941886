import * as apiService from "../../services/apiService";
import { getActionCreators } from "../../services/reduxCodeGenerator";
import history from "../../history";
import { SET_USER_PROFILE_DATA, } from "../actionTypes";
import { deleteLocalStorage, setLocalStorageAsJSON } from "../../storage";
import { LS_USER_DETAILS_KEY } from "../../App.config";
import * as loaderActions from "./loaderActions";
import * as confirmDialogActions from "./confirmDialogActions";

const actionTypes = [SET_USER_PROFILE_DATA];

export const actions = getActionCreators(actionTypes);
//console.log(actions)

export function doLogin(params) {
  return (dispatch, store) => {
    dispatch(loaderActions.showLoader());
    apiService.login(params).then(
      (response) => {
        dispatch(loaderActions.hideLoader());
        response = response.data;
        if (response?.errorMessages && response?.errorMessages?.length > 0) {
          alert(response?.errorMessages[0]);
          return;
        }
        if (response?.response) {
          setLocalStorageAsJSON(LS_USER_DETAILS_KEY, response?.response);
          history.push("/");
        }
      },
      (error) => {
        dispatch(loaderActions.hideLoader());
      }
    );
  };
}

export function doForgotPassword(params) {
  return (dispatch, store) => {
    dispatch(loaderActions.showLoader());
    apiService.forgotPassword(params).then(
      (response) => {
        dispatch(loaderActions.hideLoader());
        response = response.data;
        if (response?.errorMessages && response?.errorMessages?.length > 0) {
          dispatch(confirmDialogActions.show({
            header: response?.errorMessages[0],
            buttons: [
              {
                label: "OK",
              },
            ],
          }));
          return;
        }

        dispatch(confirmDialogActions.show({
          header: "ログイン情報を送信しましたので、メールをご確認ください。",
          buttons: [
            {
              label: "OK",
            },
          ],
        }));
        history.push("/login");

      },
      (error) => {
        dispatch(loaderActions.hideLoader());
      }
    );
  };
}

export function getUserProfile(callBackFn) {
  return (dispatch, store) => {
    dispatch(loaderActions.showLoader());
    apiService.getUserProfile().then(
      (response) => {
        dispatch(loaderActions.hideLoader());
        response = response.data;
        if (response?.errorMessages && response?.errorMessages?.length > 0) {
          alert(response?.errorMessages[0]);
          return;
        }
        dispatch(actions.setUserProfileData(response));
        callBackFn && callBackFn(response)
      },
      (error) => {
        dispatch(loaderActions.hideLoader());
      }
    );
  };
}

export function doRegister(params) {
  return (dispatch, store) => {
    dispatch(loaderActions.showLoader());
    apiService.register(params).then(
      (response) => {
        dispatch(loaderActions.hideLoader());
        response = response.data;
        if (response?.errorMessages && response?.errorMessages?.length > 0) {
          alert(response?.errorMessages[0]);
          return;
        }
        if (response?.response) {
          setLocalStorageAsJSON(LS_USER_DETAILS_KEY, response?.response);
          history.push("/send-card");
        }
      },
      (error) => {
        dispatch(loaderActions.hideLoader());
      }
    );
  };
}

export function doUpdateProfile(params) {
  return (dispatch, store) => {
    dispatch(loaderActions.showLoader());
    apiService.updateProfile(params).then(
      (response) => {
        dispatch(loaderActions.hideLoader());
        response = response.data;
        if (response?.errorMessages && response?.errorMessages?.length > 0) {
          dispatch(confirmDialogActions.show({
            header: response?.errorMessages[0],
            buttons: [
              {
                label: "OK",
              },
            ],
          }));
          return;
        }
        dispatch(getUserProfile())
        dispatch(confirmDialogActions.show({
          header: "プロフィールが正常に更新されました。",
          buttons: [
            {
              label: "OK",
            },
          ],
        }));
        history.push("/");
      },
      (error) => {
        dispatch(loaderActions.hideLoader());
      }
    );
  };
}

export function doLogout() {
  return (dispatch, store) => {
    deleteLocalStorage(LS_USER_DETAILS_KEY);
    history.push("/login");
  }
}
