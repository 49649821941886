import { 
    SET_TRANSACTIONS_DATA,
    SET_TRANSACTIONS_DATA_GOT_RESPONSE,

    SET_TRANSACTION_DETAILS_DATA,
    SET_TRANSACTION_DETAILS_DATA_GOT_RESPONSE

} from '../actionTypes';
import { getReducerData } from '../../services/reduxCodeGenerator';

const actionTypes = [
    SET_TRANSACTIONS_DATA,
    SET_TRANSACTIONS_DATA_GOT_RESPONSE,

    SET_TRANSACTION_DETAILS_DATA,
    SET_TRANSACTION_DETAILS_DATA_GOT_RESPONSE
];

const {initialState, actionsProperties} = getReducerData(actionTypes);

export default function transactionReducer(state = initialState, action) {
    switch (true) {
        case actionTypes.indexOf(action.type) != -1:
            return Object.assign({}, state, { [actionsProperties[action.type]]: action.data })
        default:
            return state;
    }
}
