import * as apiService from "../../services/apiService";
import { SET_DMR_TXS_DATA, SET_SDM_TXS_DATA } from "../actionTypes";
import * as loaderActions from "./loaderActions";
import * as confirmDialogActions from "./confirmDialogActions";

export function setDmrTxsData(data) {
  return {
    type: SET_DMR_TXS_DATA,
    data: data,
  };
}
export function setSdmTxsData(data) {
  return {
    type: SET_SDM_TXS_DATA,
    data: data,
  };
}

export function doConvertToDM(callBackFn) {
  return (dispatch, store) => {
    dispatch(loaderActions.showLoader());
    apiService.generateDMTransaction().then(
      (response) => {
        dispatch(loaderActions.hideLoader());
        response = response.data;
        if (response?.errorMessages.length <= 0 && response) {
          callBackFn && callBackFn(true, "");
        } else {
          callBackFn && callBackFn(false, response?.errorMessages[0]);
        }
      },
      (error) => {
        dispatch(loaderActions.hideLoader());
        callBackFn && callBackFn(false);
      }
    );
  };
}

export function doGetAndSetDmrTransactions() {
  return (dispatch, store) => {
    dispatch(loaderActions.showLoader());
    dispatch(setDmrTxsData({ gotResponse: false }));
    apiService.getDmrTransactions().then(
      (response) => {
        dispatch(loaderActions.hideLoader());
        response = response.data;
        if (response?.transactions && response?.transactions.length > 0) {
          dispatch(
            setDmrTxsData({ gotResponse: true, txs: response?.transactions })
          );
        } else {
          dispatch(setDmrTxsData({ gotResponse: true, txs: [] }));
        }
      },
      (error) => {
        dispatch(loaderActions.hideLoader());
        dispatch(setDmrTxsData({ gotResponse: true, txs: [] }));
      }
    );
  };
}

export function doGetAndSetSdmTransactions(data, isLoadMore) {
  return (dispatch, store) => {
    dispatch(loaderActions.showLoader());
    dispatch(setSdmTxsData({ ...data, gotResponse: false }));
    apiService.getSdmTransactions({ params: data }).then(
      (response) => {
        dispatch(loaderActions.hideLoader());
        response = response.data;
        const {
          dmData: {
            sdmTxsData: { txs },
          },
        } = store();

        let transactions = isLoadMore ? txs : [];

        if (response?.transactions && response?.transactions.length > 0) {
          if (isLoadMore) {
            transactions = [...transactions, ...response?.transactions];
          } else {
            transactions = response?.transactions;
          }
        }
        dispatch(
          setSdmTxsData({
            gotResponse: true,
            txs: transactions,
            totalCount: response?.totalCount || 0,
          })
        );
      },
      (error) => {
        dispatch(loaderActions.hideLoader());
        dispatch(setSdmTxsData({ gotResponse: true, txs: [] }));
      }
    );
  };
}

export function doPaySdm(data, callBackFn) {
  return (dispatch, store) => {
    dispatch(loaderActions.showLoader());
    apiService.saveSdmTransaction(data).then(
      (response) => {
        dispatch(loaderActions.hideLoader());
        response = response.data;
        if (response?.errorMessages && response?.errorMessages?.length > 0) {
          dispatch(
            confirmDialogActions.show({
              header: response?.errorMessages[0],
              buttons: [
                {
                  label: "OK",
                },
              ],
            })
          );
        } else {
          callBackFn(true, response);
        }
      },
      (error) => {
        dispatch(loaderActions.hideLoader());
        callBackFn(false, error);
      }
    );
  };
}

export function doValidateShopQR(qrDetail, callBack) {
  return (dispatch, store) => {
    dispatch(loaderActions.showLoader());
    apiService.validateShopQR(qrDetail).then(
      (response) => {
        dispatch(loaderActions.hideLoader());
        callBack(response);
      },
      (error) => {
        dispatch(loaderActions.hideLoader());
        callBack(error);
      }
    );
  };
}
