// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-field-root {
  width: 100%;
}

.text-field-input {
  width: 100%;
  height: 32px;
  padding: 4px 10px;
  background: #fff;
  border: 1px #d7dae2 solid;
  border-radius: 4px;
  font-size: 13px;
  font-weight: normal;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.05);
}`, "",{"version":3,"sources":["webpack://./src/components/TextField/style.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,gBAAgB;EAChB,yBAAyB;EACzB,kBAAkB;EAClB,eAAe;EACf,mBAAmB;EACnB,2CAA2C;AAC7C","sourcesContent":[".text-field-root {\r\n  width: 100%;\r\n}\r\n\r\n.text-field-input {\r\n  width: 100%;\r\n  height: 32px;\r\n  padding: 4px 10px;\r\n  background: #fff;\r\n  border: 1px #d7dae2 solid;\r\n  border-radius: 4px;\r\n  font-size: 13px;\r\n  font-weight: normal;\r\n  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.05);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
