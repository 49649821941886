import React from "react";
import BackButton from "../BackButton";
import { FormattedMessage } from "react-intl";
const FM = FormattedMessage;

const DmTxDetailPage = (props) => {
  let { data, onGoBack } = props;
  if (!data) {
    return <></>;
  }
  return (
    <>
      <div class="Card-panel">
        {/* <div class="Flex-col heading-col">
          <BackButton></BackButton>
          <h1></h1>
          <img src={""}></img>
        </div> */}
        <div class="tab-content" id="myTabContent">
          <div class="dm-card-view">
            <div class="dm-card">DIGITAL MONEY CARD</div>
            <div class="dm-card-info">{data.shopName || "-"}</div>
            <div class="dm-card-details">
              <div>
                <div class="dm-card-label">
                  <FM id="Transaction Reference Number"></FM>
                </div>
                <div class="dm-card-value">
                  {data.transactionDetailsId || ""}
                </div>
              </div>
              <div>
                <div class="dm-card-label">
                  <FM id="Total1"></FM>
                </div>
                <div class="dm-card-currency">
                  <span class="">¥</span>
                  {data.amount || ""}
                </div>
              </div>
            </div>
          </div>
          <div class="btn-footer">
                <button
                  class="btn btn-primary custom-btn"
                  onClick={onGoBack}
                >
                  <FM id="Go Back"></FM>
                </button>
              </div>
        </div>
      </div>
    </>
  );
};

export default DmTxDetailPage;
