import { SHOW_CONFIRM_DIALOG, HIDE_CONFIRM_DIALOG } from "../actionTypes";
import { FormattedMessage } from "react-intl";
const FM = FormattedMessage;

export function show(data) {
  return {
    type: SHOW_CONFIRM_DIALOG,
    data: data,
  };
}
export function hide() {
  return {
    type: HIDE_CONFIRM_DIALOG,
  };
}
