// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.radio-group-field-root {
  width: 100%;
}

.radio-group-field-radio-group-container {
  display: flex;
  gap: 10px;
}
.radio-group-field-radio-option input {
  margin-right: 5px;
  width: 20px;
  height: 20px;
}
.radio-group-field-radio-option label {
  vertical-align: middle;

}`, "",{"version":3,"sources":["webpack://./src/components/RadioGroupField/style.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,SAAS;AACX;AACA;EACE,iBAAiB;EACjB,WAAW;EACX,YAAY;AACd;AACA;EACE,sBAAsB;;AAExB","sourcesContent":[".radio-group-field-root {\r\n  width: 100%;\r\n}\r\n\r\n.radio-group-field-radio-group-container {\r\n  display: flex;\r\n  gap: 10px;\r\n}\r\n.radio-group-field-radio-option input {\r\n  margin-right: 5px;\r\n  width: 20px;\r\n  height: 20px;\r\n}\r\n.radio-group-field-radio-option label {\r\n  vertical-align: middle;\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
