import React, { Component } from "react";
import SendCardPage from "../../components/SendCardPage";
import "../../css/style.css";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as sendCardsActions from "../../redux/actions/sendCardActions";
import * as dashboardActions from "../../redux/actions/dashboardActions";
import * as appHeaderActions from "../../redux/actions/appHeaderActions";
import history from "../../history";

export const filterEmployeeList = (employees, searchString) => {
  if (searchString) {
    if (employees && employees.length && employees.length > 0) {
      return employees.filter((employee) => {
        return (
          (employee?.user?.lastName &&
            employee.user.lastName.includes(searchString)) ||
          (employee?.user?.firstName &&
            employee.user.firstName.includes(searchString))
        );
      });
    }
  }
  return employees || [];
};

class SendCard extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      cardSendStep: 1,
      selectedEmployee: null,
      selectedCardIndex: 0,
      showConfirmPopup: undefined,

      employeeListCurrentPage: 1,
      employeeListPageSize: 1000,
      employeeSearchString: "",
    };
  }

  componentDidMount() {
    this.getAllCards();
    this.props.appHeaderActions.setData({ title: 'Select', isBack: false, backClick: null })
  }

  componentWillUnmount() {
    this.props.appHeaderActions.setData({ title: null, isBack: false, backClick: null })
  }

  goToMycards = () => {
    history.push("/my-card");
  };

  getAllCards = () => {
    this.props.sendCardsActions.getAndSetAllCards(this.state.hashVWUserID);
    this.getAllEmployees();
  };

  getAllEmployees = () => {
    this.props.dashboardActions.getAndSetAllEmployees();
  };

  sendCard = () => {
    const { selectedCardIndex, selectedEmployee } = this.state;
    const { allEmployees, allCards } = this.props;
    this.props.sendCardsActions.sendCard(
      {
        order: {
          cardID: allCards[selectedCardIndex].cardID,
          receiverEmployeeEIN: selectedEmployee.ein,
          message: "test",
        },
      },
      () => {
        this.setState({ cardSendStep: 4 });
      }
    );
  };

  chageCardSendStep = (step) => {
    if (
      step === 4 &&
      this.state.selectedEmployee &&
      this.state.selectedCardIndex != undefined
    ) {
      this.props.appHeaderActions.setData({ title: 'Card Sent', isBack: false, backClick: null })
      this.sendCard();
      return;
    }

    if (step === 1) {
      this.setState({
        selectedEmployee: null,
        selectedCardIndex: 0,
        employeeSearchString: ""
      });
      this.props.appHeaderActions.setData({ title: 'Select', isBack: false, backClick: null })
    }

    if (step === 2) {
      this.props.appHeaderActions.setData({ title: 'Contact', isBack: true, backClick: () => { this.chageCardSendStep(1) } })
    }

    if (step === 3) {
      this.props.appHeaderActions.setData({ title: 'Review', isBack: true, backClick: () => { this.chageCardSendStep(2) } })
    }

    this.setState({ cardSendStep: step });
  };

  onChangeEmployeeListPageNumber = (pageNo) => {
    this.setState(
      {
        employeeListCurrentPage: pageNo,
      }
      // () => this.getAllEmployees()
    );
  };

  doSearchEmployees = (searchString, fromEnter) => {
    // if (fromEnter || !searchString || searchString.length >= 3) {
    this.setState(
      {
        employeeSearchString: searchString,
        employeeListCurrentPage: 1,
        selectedEmployee: null,
      }
      // () => this.getAllEmployees()
    );
    // }
  };

  afterChangeCard = (currentSlide) => {
    if (currentSlide != undefined) {
      this.setState({ selectedCardIndex: currentSlide });
    }
  };

  render() {
    return (
      <>
        <SendCardPage
          goToMycards={this.goToMycards}
          afterChangeCard={this.afterChangeCard}
          cardSendStep={this.state.cardSendStep}
          showConfirmPopup={this.state.showConfirmPopup}
          chageCardSendStep={this.chageCardSendStep}
          allCards={this.props.allCards}
          allEmployees={this.props.allEmployees}
          selectedEmployee={this.state.selectedEmployee}
          selectedCardIndex={this.state.selectedCardIndex}
          onChangeSelectedCard={(index) =>
            this.setState({ selectedCardIndex: index })
          }
          onChangeSelectedEmployee={(employee) => {
            this.setState({ selectedEmployee: employee });
          }}
          onChangeEmployeeListPageNumber={this.onChangeEmployeeListPageNumber}
          doSearchEmployees={(searchString, fromEnter) =>
            this.doSearchEmployees(searchString, fromEnter)
          }
          employeeSearchString={this.state.employeeSearchString}
          employeeListCurrentPage={this.state.employeeListCurrentPage}
          employeeListPageSize={this.state.employeeListPageSize}
          employeeListTotalRows={this.props.allEmployeesTotalRows}
        ></SendCardPage>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allCards: state.sendCardData.allCards,
    allEmployees: state.dashboard.allEmployees,
    allEmployeesTotalRows: state.dashboard.allEmployeesTotalRows,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendCardsActions: bindActionCreators(sendCardsActions, dispatch),
    dashboardActions: bindActionCreators(dashboardActions, dispatch),
    appHeaderActions: bindActionCreators(appHeaderActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SendCard);
