import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import TextField from "../TextField";
const FM = FormattedMessage;

const Login = (props) => {
  const { username, password, errors, handleSubmit, handleChange, handleNewAccount, handleForgotPwd } = props;
  return (
    <div className="Flex-col">
      <div className="login-card">
        <div class="Flex-col">
          <h1>
            <FM id={"Rewards App"} />
          </h1>

        </div>

        <TextField name="username"
          value={username}
          onChange={handleChange}
          errorText={errors?.username} placeholder="Eメールアドレス" />

        <TextField type="password"
          name="password"
          value={password}
          onChange={handleChange}
          errorText={errors?.password} placeholder="パスワード" />

        <div>
          <button onClick={handleSubmit} className="btn btn-primary custom-btn" ><FM id={"Login"} /></button>
        </div>

        <div className="login-links">
          <div>
            <a href="" onClick={handleForgotPwd}><FM id={"Forgot Password"} /></a>
          </div>
          <div><a href="" onClick={handleNewAccount}><FM id={"New User Registration"} /></a></div>
        </div>
      </div>
    </div >
  );
};

export default injectIntl(Login);
