import { request } from "../utilities/request";
import {
  API_URL,
  OS_TYPE,
  POST_DEPT_ID,
  LS_AUTH_DETAILS_KEY,
  LS_USER_DETAILS_KEY,
} from "../App.config";
import { getLocalStorageAsJSON } from "../storage";

function getAuthDetails() {
  // return JSON.parse(localStorage.getItem(LS_) || "{}");
  return getLocalStorageAsJSON(LS_USER_DETAILS_KEY);
}

function getOtherData() {
  const authDetails = getAuthDetails();
  return {
    header: {
      osType: OS_TYPE,
      key: authDetails?.companyId,
      hashVWUserID: authDetails?.vcaId,
      token: authDetails?.token,
    },
    dashboardAuth: {
      sessionID: authDetails?.sesstionId,
      companyID: authDetails?.companyId,
    },
  };
}

export function login(params = {}) {
  return request(
    API_URL + "rewards/mobile/login",
    "POST",
    {
      "Content-Type": "application/json",
    },
    {
      ...getOtherData(),
      ...params,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export function forgotPassword(params = {}) {
  return request(
    API_URL + "rewards/mobile/forgotpassword",
    "POST",
    {
      "Content-Type": "application/json",
    },
    {
      ...getOtherData(),
      ...params,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export function getUserProfile(params = {}) {
  return request(
    API_URL + "rewards/mobile/getProfile",
    "POST",
    {
      "Content-Type": "application/json",
    },
    {
      ...getOtherData(),
      ...params,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export function register(params = {}) {
  return request(
    API_URL + "rewards/mobile/register",
    "POST",
    {
      "Content-Type": "application/json",
    },
    {
      ...getOtherData(),
      ...params,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export function updateProfile(params = {}) {
  return request(
    API_URL + "rewards/mobile/updateProfile",
    "POST",
    {
      "Content-Type": "application/json",
    },
    {
      ...getOtherData(),
      ...params,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export function getAllEmployeesToDepartmentsData(duration) {
  return request(
    API_URL + "dashboard/getAllEmployeesToDepartmentTransactions",
    "POST",
    {
      "Content-Type": "application/json",
    },
    {
      month: duration,
      ...getOtherData(),
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export function getAllEmployeesToDepartmentsChartData(duration) {
  return request(
    API_URL + "dashboard/getAllEmployeeToDepartmentChartData",
    "POST",
    {
      "Content-Type": "application/json",
    },
    {
      ...getOtherData(),
      timeSpan: duration,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export function getAllEmployeesTrendChartData(duration) {
  return request(
    API_URL + "dashboard/trandAnalysis",
    "POST",
    {
      "Content-Type": "application/json",
    },
    {
      ...getOtherData(),
      month: duration,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export function getTransactionsData(params = {}) {
  return request(
    API_URL + "dashboard/getTransactions",
    "POST",
    {
      "Content-Type": "application/json",
    },
    {
      ...getOtherData(),
      ...params,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export function getTransactionsDataFileToExport(params = {}) {
  return request(
    API_URL + "dashboard/getCSVFilePath",
    "POST",
    {
      "Content-Type": "application/json",
    },
    {
      ...getOtherData(),
      ...params,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export function getUsersData(params = {}) {
  return request(
    API_URL + "dashboard/getStatusWiseUserList",
    "POST",
    {
      "Content-Type": "application/json",
    },
    {
      ...getOtherData(),
      ...params,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export function getUsersDataFileToExport(params = {}) {
  return request(
    API_URL + "dashboard/getStatusWiseUserListCSVPath",
    "POST",
    {
      "Content-Type": "application/json",
    },
    {
      ...getOtherData(),
      ...params,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export function getmyCardsDetails(paginationPayLoad) {
  return request(
    API_URL + "rewards/mobile/getSentStatusOrders",
    "POST",
    {
      "Content-Type": "application/json",
    },
    {
      ...getOtherData(),
      ...paginationPayLoad,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export function validateUser(vwUserId) {
  return request(
    API_URL + "rewards/mobile/validateuser",
    "POST",
    {
      "Content-Type": "application/json",
    },
    {
      vwUserId,
      ...getOtherData(),
    },
    true
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export function validateShopQR(qrDetail) {
  return request(
    API_URL + "rewards/mobile/validateShopQR",
    "POST",
    {
      "Content-Type": "application/json",
    },
    {
      qrDetail,
      ...getOtherData(),
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export function getCashoutOrdersDetails(paginationPayLoad) {
  return request(
    API_URL + "rewards/mobile/getRedeemedStatusOrders",
    "POST",
    {
      "Content-Type": "application/json",
    },
    {
      ...getOtherData(),
      ...paginationPayLoad,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export function getReceivedStatusOrders(paginationPayLoad) {
  return request(
    API_URL + "rewards/mobile/getReceivedStatusOrders",
    "POST",
    {
      "Content-Type": "application/json",
    },
    {
      ...getOtherData(),
      ...paginationPayLoad,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export function getQrDetailsById(qrId) {
  return request(
    API_URL + "rewards/mobile/getQRCodeDetails",
    "POST",
    {
      "Content-Type": "application/json",
    },
    {
      ...getOtherData(),
      qrCodeId: qrId,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export function getStatusOfEmp() {
  return request(
    API_URL + "rewards/mobile/getReceivedStatus",
    "POST",
    {
      "Content-Type": "application/json",
    },
    {
      ...getOtherData(),
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export function getRedeemedStatusOrder(paginationPayLoad) {
  return request(
    API_URL + "rewards/mobile/getRedeemedStatusQRs",
    "POST",
    {
      "Content-Type": "application/json",
    },
    {
      ...getOtherData(),
      ...paginationPayLoad,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export function getClaimCardOrders(payload) {
  return request(
    API_URL + "rewards/mobile/getClaimDetails",
    "POST",
    {
      "Content-Type": "application/json",
    },
    {
      ...getOtherData(),
      ...payload,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export function getClaimCardOrderCards(payload) {
  return request(
    API_URL + "rewards/mobile/getClaimStatusOrders",
    "POST",
    {
      "Content-Type": "application/json",
    },
    {
      ...getOtherData(),
      ...payload,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export function getOrderById(orderId) {
  return request(
    API_URL + "rewards/mobile/getOrderById",
    "POST",
    {
      "Content-Type": "application/json",
    },
    {
      ...getOtherData(),
      orderId,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export function generateQRCode() {
  return request(
    API_URL + "rewards/mobile/generateQRCode",
    "POST",
    {
      "Content-Type": "application/json",
    },
    {
      ...getOtherData(),
    },
    true
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export function initiateClaims() {
  return request(
    API_URL + "rewards/mobile/claimInititated",
    "POST",
    {
      "Content-Type": "application/json",
    },
    {
      ...getOtherData(),
    },
    true
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export function generateDMTransaction() {
  return request(
    API_URL + "rewards/mobile/generateDMTransaction",
    "POST",
    {
      "Content-Type": "application/json",
    },
    {
      ...getOtherData(),
    },
    true
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export function getDmrTransactions() {
  return request(
    API_URL + "rewards/mobile/getDmrTransaction",
    "POST",
    {
      "Content-Type": "application/json",
    },
    {
      ...getOtherData(),
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export function getSdmTransactions(data) {
  return request(
    API_URL + "rewards/mobile/getSdmTransaction",
    "POST",
    {
      "Content-Type": "application/json",
    },
    {
      ...getOtherData(),
      ...data,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export function saveSdmTransaction(data) {
  return request(
    API_URL + "rewards/mobile/saveSDMTransaction",
    "POST",
    {
      "Content-Type": "application/json",
    },
    {
      ...data,
      ...getOtherData(),
    },
    true
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export function getHashData(data) {
  return request(
    API_URL + "rewards/mobile/gethashdata",
    "POST",
    {
      "Content-Type": "application/json",
    },
    {
      ...data,
      ...getOtherData(),
    },
    true
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export function getTransactionDetailsData(transactionId) {
  return request(
    API_URL + "dashboard/getTransactionDetails",
    "POST",
    {
      "Content-Type": "application/json",
    },
    {
      ...getOtherData(),
      transactionId: transactionId,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export function getTopDashboardData(duration) {
  return request(
    API_URL + "dashboard/getDashboardPoints",
    "POST",
    {
      "Content-Type": "application/json",
    },
    {
      ...getOtherData(),
      month: duration,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export function getTrendChartData(duration) {
  return request(
    API_URL + "dashboard/trandAnalysis",
    "POST",
    {
      "Content-Type": "application/json",
    },
    {
      ...getOtherData(),
      month: duration,
    }
  )
    .then((response) => {
      /*
        response = { data : {
            response: [
                {
                    month: 1,
                    sent: 50,
                    received: 500,
                    redeemed: 300,
                    cashOut: 50
                },
                {
                    month: 2,
                    sent: 150,
                    received: 400,
                    redeemed: 50,
                    cashOut: 300
                },
                {
                    month: 3,
                    sent: 50,
                    received: 150,
                    redeemed: 3000,
                    cashOut: 650
                },
                {
                    month: 4,
                    sent: 150,
                    received: 500,
                    redeemed: 50,
                    cashOut: 800
                }
            ]
        }}
        */
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export function getUsersCountsData(duration) {
  return request(
    API_URL + "dashboard/getUsersCounts",
    "POST",
    {
      "Content-Type": "application/json",
    },
    {
      ...getOtherData(),
      month: duration,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export function getEmployeeTrendChartData(employeeId, duration) {
  return request(
    API_URL + "dashboard/trandAnalysisEmployee",
    "POST",
    {
      "Content-Type": "application/json",
    },
    {
      ...getOtherData(),
      month: duration,
      employeeEIN: employeeId,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export function getDeptTrendChartData(departmentId, duration) {
  return request(
    API_URL + "dashboard/trandAnalysisDepartment",
    "POST",
    {
      "Content-Type": "application/json",
    },
    {
      ...getOtherData(),
      month: duration,
      departmentId: departmentId,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export function getTopEmployeesPointsData(
  noOfEmployees,
  durationSent,
  durationReceived
) {
  return request(
    API_URL + "dashboard/emplyeeWisePointTransaction",
    "POST",
    {
      "Content-Type": "application/json",
    },
    {
      timeSpanSent: durationSent,
      timeSpanReceive: durationReceived,
      ...getOtherData(),
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export function getDepartmentWisePointsData(durationSent, durationReceived) {
  return request(
    API_URL + "dashboard/departmentWisePointTransaction",
    "POST",
    {
      "Content-Type": "application/json",
    },
    {
      timeSpanSent: durationSent,
      timeSpanReceive: durationReceived,
      ...getOtherData(),
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export function getEmployeeDetailsWithDepartmentWiseData(employeeId, duration) {
  return request(
    API_URL + "dashboard/getEmployeeToDepartmentTransactions",
    "POST",
    {
      "Content-Type": "application/json",
    },
    {
      month: duration,
      employeeEIN: employeeId,
      ...getOtherData(),
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export function getEmployeeWiseDepartmentsAnalysisChartData(
  employeeId,
  duration
) {
  return request(
    API_URL + "dashboard/getEmployeeToDepartmentChartData",
    "POST",
    {
      "Content-Type": "application/json",
    },
    {
      timeSpan: duration,
      employeeEIN: employeeId,
      ...getOtherData(),
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export function getDepartmentToDepartmentsData(deptId, duration) {
  return request(
    API_URL + "dashboard/getDepartmentTransactionDetails",
    "POST",
    {
      "Content-Type": "application/json",
    },
    {
      departmentId: deptId,
      month: duration,
      ...getOtherData(),
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export function getDepartmentToDepartmentsAnalysisData(deptId, duration) {
  return request(
    API_URL + "dashboard/pointTransactionByDeptartment",
    "POST",
    {
      "Content-Type": "application/json",
    },
    {
      month: duration,
      departmentId: deptId,
      ...getOtherData(),
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export function getEmployeeDiffTypesOfPoints(employeeId) {
  return request(
    API_URL + "dashboard/getReceivedStatus",
    "POST",
    {
      "Content-Type": "application/json",
    },
    {
      ein: employeeId,
      ...getOtherData(),
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export function getEmployeeConnData(employeeId, duration) {
  return request(
    API_URL + "dashboard/getEmployeeConnections",
    "POST",
    {
      "Content-Type": "application/json",
    },
    {
      employeeEIN: employeeId,
      month: duration,
      ...getOtherData(),
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export function getAllEmployeesConnData(duration) {
  return request(
    API_URL + "dashboard/getDepartmentAllConnections",
    "POST",
    {
      "Content-Type": "application/json",
    },
    {
      month: duration,
      ...getOtherData(),
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export function getDeptConnData(departmentId, duration) {
  return request(
    API_URL + "dashboard/getDepartmentConnection",
    "POST",
    {
      "Content-Type": "application/json",
    },
    {
      departmentID: departmentId,
      month: duration,
      ...getOtherData(),
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export function getAllEmployees() {
  return request(
    API_URL + "rewards/mobile/employees",
    "POST",
    {
      "Content-Type": "application/json",
    },
    {
      // filter: searchString,
      ...getOtherData(),
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export function getAllEmployeesSearch(params) {
  return request(
    API_URL + "dashboard/getDashboardEmployees",
    "POST",
    {
      "Content-Type": "application/json",
    },
    {
      // filter: searchString,
      ...getOtherData(),
      ...params,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export function sendCard(cardData) {
  return request(
    API_URL + "rewards/mobile/CreateOrder",
    "POST",
    {
      "Content-Type": "application/json",
    },
    {
      // filter: searchString,
      ...getOtherData(),
      ...cardData,
    },
    true
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export function getAllCards() {
  return request(
    API_URL + "rewards/mobile/getGiftingCards",
    "POST",
    {
      "Content-Type": "application/json",
    },
    {
      // filter: searchString,
      ...getOtherData(),
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export function searchEmployees(searchString) {
  return request(
    API_URL + "dashboard/searchEmployee",
    "POST",
    {
      "Content-Type": "application/json",
    },
    {
      filter: searchString,
      ...getOtherData(),
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export function getDepartments() {
  return request(
    API_URL + "dashboard/departments",
    "POST",
    {
      "Content-Type": "application/json",
    },
    {
      ...getOtherData(),
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export function getAllMenuItems(data) {
  return request(
    API_URL + "v1/menu-item",
    "POST",
    { "Content-Type": "application/json" },
    {
      posDepartmentId: POST_DEPT_ID,
      storeId: window.STORE_ID,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export function getAllMenuItems1() {
  return request(
    API_URL + "v1/menu-list",
    "POST",
    { "Content-Type": "application/json" },
    {
      kioskId: window.KIOSK_ID,
      storeId: window.STORE_ID,
      languageCode: window.LANGUAGE,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export function getPaymentTenderByKid(kId) {
  return request(
    API_URL + "v1/payment-tender/" + kId,
    "GET",
    { "Content-Type": "application/json" },
    {}
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export function confirmOrder(data) {
  return request(
    API_URL + "v1/confirm-order/" + window.STORE_ID,
    "POST",
    { "Content-Type": "application/json" },
    data //{"orderId":"531C9EA3-22A5-400F-B59E-6B7428BE86BF"}
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}
export function createOrder(data) {
  return request(
    API_URL + "v1/place-order/" + window.STORE_ID,
    "POST",
    { "Content-Type": "application/json" },
    data
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}
export function updateOrder(data) {
  return request(
    API_URL + "v1/update-order/" + window.STORE_ID,
    "PUT",
    { "Content-Type": "application/json" },
    data
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export function confirmPayment(data) {
  return request(
    API_URL + "v1/confirm-payment/" + window.STORE_ID,
    "POST",
    { "Content-Type": "application/json" },
    data

    /* data for testing fail scenario
        {'Content-Type': 'application/json'},
        {"orderId":"B814A67F-E3B0-45CF-8DD5-D8814E431E022",
        "paymentStatus":"PAID","total":100,"tokenId":"101",
        "externalId":"IPAPI6"}
        */
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export function getOrderConfigFromApi() {
  return request(
    API_URL + "v1/kiosk-config/" + window.KIOSK_ID + "/" + window.LANGUAGE,
    "GET",
    { "Content-Type": "application/json" },
    {}
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export function getProfileImage(vwUserId) {
  return request(
    API_URL + "rewards/mobile/getProfileImage",
    "POST",
    {
      "Content-Type": "application/json",
    },
    {
      vwUserId,
      ...getOtherData(),
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}