import React, { Component } from "react";
import "../../css/style.css";
import { connect } from "react-redux";
import * as myCardActions from "../../redux/actions/myCardActions";
import * as appHeaderActions from "../../redux/actions/appHeaderActions";
import * as dmActions from "../../redux/actions/dmActions";
import DigitalMoneyPage from "../../components/DigitalMoneyPage";
import { bindActionCreators } from "redux";
import history from "../../history";
import * as qs from "qs";

export const DM_LIST_TABS = {
  RECEIVED: "RECEIVED",
  CONSUMED: "CONSUMED",
};

class DigitalMoney extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      activeTab: DM_LIST_TABS.RECEIVED,
      isSdmTxLoadedOnce: false
    };
  }

  componentDidMount() {
    let objQueryParams = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    this.setState(
      {
        activeTab:
          objQueryParams.tab != undefined
            ? objQueryParams.tab
            : this.state.activeTab,
      },
      () => {
        this.onChangeTab(this.state.activeTab);
      }
    );

    this.props.mycardActions.getReceivedStatus();
    this.props.dmActions.doGetAndSetDmrTransactions();

    this.props.appHeaderActions.setData({ title: "My Cards", isBack: false })

  }

  updateUrl = () => {
    const onlyPath = history.location.pathname.substr(
      0,
      history.location.pathname.lastIndexOf("/")
    );
    const { activeTab } = this.state;
    const newUrl = onlyPath + "?tab=" + activeTab;
    history.replace(newUrl);
  };

  onChangeTab = (selectedTab) => {
    if (selectedTab == DM_LIST_TABS.CONSUMED && !this.state.isSdmTxLoadedOnce) {
      this.getSdmTxs();
      this.setState({ isSdmTxLoadedOnce: true });
    }
    this.setState({ activeTab: selectedTab }, () => this.updateUrl());
  };

  getSdmTxs = (isLoadMore) => {
    const { dmActions, dmData } = this.props;
    // console.log(dmData);
    const {
      sdmTxsData: { pageNo, pageSize },
    } = dmData;
    dmActions.doGetAndSetSdmTransactions(
      {
        pageNo: isLoadMore ? pageNo + 1 : 1,
        pageSize: pageSize,
      },
      isLoadMore
    );
  };

  doLoadMore = () => {
    this.getSdmTxs(true);
  };

  goToDetail = (data) => {
    const { activeTab } = this.state;
    if (activeTab == DM_LIST_TABS.RECEIVED) {
      history.push({
        pathname: "/send-dm",
        state: {
          data: data,
        },
      });
    } else if (activeTab == DM_LIST_TABS.CONSUMED) {
      history.push({
        pathname: "/dm-tx-detail",
        state: {
          data: data,
        },
      });
    }
  };

  onGoBack = () => {
    history.goBack();
  };

  render() {
    const { receivedStatus, dmData } = this.props;
    const { onChangeTab, goToDetail, doLoadMore, onGoBack } = this;
    const propsForward = {
      receivedStatus,
      dmData,
      onChangeTab,
      goToDetail,
      doLoadMore,
      onGoBack,
      ...this.state,
    };
    return (
      <>
        <DigitalMoneyPage {...propsForward}></DigitalMoneyPage>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    receivedStatus: state.myCardReducer.receivedStatus,
    dmData: state.dmData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    mycardActions: bindActionCreators(myCardActions, dispatch),
    dmActions: bindActionCreators(dmActions, dispatch),
    appHeaderActions: bindActionCreators(appHeaderActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DigitalMoney);
