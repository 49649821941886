import React, { Component } from 'react';
import "./style.css";
import userDefaultImg from "../../images/user-default-img.jpeg";
import history from '../../history';
import * as authActions from '../../redux/actions/authActions';
import * as confirmDialogActions from '../../redux/actions/confirmDialogActions';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
const FM = FormattedMessage;
class UserMenu extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            displayUserMenu: false,
        }
    }
    handleLogout = () => {
        this.props.confirmDialogActions.show({
            header: "ログアウトしてもよろしいですか?",
            buttons: [
                {
                    label: "いいえ",
                },
                {
                    label: "はい",
                    onClick: () => {
                        this.props.authActions.doLogout();
                    },
                },
            ],
        });
    }
    handleEditProfile = () => {
        history.push("/edit-profile");
    }

    toggleUserMenu = () => {
        this.setState({ 'displayUserMenu': !this.state.displayUserMenu })
    }

    render() {
        const { handleEditProfile, handleLogout, toggleUserMenu, props } = this;
        const { profileImageUrl } = props;
        const { displayUserMenu } = this.state;
        return (
            <div class="dropdown">
                <img src={profileImageUrl || userDefaultImg} className={'user-menu-img'} onClick={toggleUserMenu} />
                <div class={`dropdown-content ${displayUserMenu ? 'show' : ''}`} onClick={toggleUserMenu}>
                    <a onClick={handleEditProfile}><FM id="Edit Profile"></FM></a>
                    <a onClick={handleLogout}><FM id="Logout"></FM></a>
                </div>
                {displayUserMenu && <div className={'overlay'} onClick={toggleUserMenu}></div>}
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    return {

    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        authActions: bindActionCreators(authActions, dispatch),
        confirmDialogActions: bindActionCreators(confirmDialogActions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(UserMenu));