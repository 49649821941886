import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import myCard from "../../images/mycards.svg";
import sendCardImg from "../../images/sendcard.svg";

import { FormattedMessage } from 'react-intl';
import MyCard from '../MyCard';
import SendCard from '../SendCard';
import MyCardDetails from '../MyCard/myCardDetails';
import QRTemplete from '../../components/MyCard/qrDetailTemplete';
import cashoutOrderDetails from '../../components/MyCard/cashoutOrderDetails';
import DigitalMoney from '../DigitalMoney';
import SendDm from '../DigitalMoney/SendDm';
import DmTxDetail from '../DigitalMoney/DmTxDetail';
import EditProfileScreen from '../EditProfileScreen';
import { getLocalStorageAsJSON } from '../../storage';
import { LS_USER_DETAILS_KEY } from '../../App.config';
import history from '../../history';
import AppHeader from '../../components/AppHeader';
import CardCardsList from '../../components/MyCard/CardCardsList';
const FM = FormattedMessage;

class Home extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isSideMenuExpanded: true
    }

    if (!getLocalStorageAsJSON(LS_USER_DETAILS_KEY)?.ein) {
      history.push('/login')
    }
  }

  // componentDidUpdate(prevProps) {
  //   if (this.props.location !== prevProps.location) {
  //     this.onRouteChanged();
  //   }
  // }

  // onRouteChanged() {
  //   console.log("ROUTE CHANGED");
  // }

  toggleSideMenu = () => {
    this.setState({ isSideMenuExpanded: !this.state.isSideMenuExpanded });
  }

  handleClickMenuItem = () => {
    if (window.innerWidth <= 768) {
      this.toggleSideMenu();
    }
  }

  render() {
    return (
      <section class={"Dashboard-container " + (this.state.isSideMenuExpanded ? "" : "open")}>
        {/* <header>
          <div class="container-fluid padding-0">
            <div class="logo">
              <span><FM id="TG"></FM></span>
              <img src={walletImg} alt="" />
            </div>
            <div class="Menu-nv">
              <div class="hamburger hamburger--squeeze js-hamburger">
                <div class="hamburger-box" onClick={this.toggleSideMenu}>
                  <div class="hamburger-inner"></div>
                </div>
              </div>
            </div>
          </div>
        </header> */}
        <AppHeader toggleSideMenu={this.toggleSideMenu} />
        <div class="Left-Panel">
          <div class="Left-panel-c">
            <nav class="Menu">
              <ul onClick={this.handleClickMenuItem}>
                {/* class="active" */}
                <li><a role="button" href="#/send-card"><img src={sendCardImg} alt="" /> <span><FM id="Send Card"></FM></span></a></li>
                <li><a role="button" href="#/my-card"><img src={myCard} alt="" /> <span><FM id="My Card"></FM></span></a></li>
                {/* <li><a role="button" href="#/dm"><img src={myCard} alt="" /> <span><FM id="DM"></FM></span></a></li> */}
              </ul>
            </nav>
          </div>
        </div>
        <div class="Content-Panel-Main">
          <div class="Content-Container">
            <Switch>
              <Route exact path="/" component={MyCard} />
              <Route exact path="/my-card" component={MyCard} />
              <Route exact path="/send-card" component={SendCard} />
              <Route exact path="/dm" component={DigitalMoney} />
              <Route exact path="/send-dm" component={SendDm} />
              <Route exact path="/dm-tx-detail" component={DmTxDetail} />
              <Route exact path="/my-card-details" component={MyCardDetails} />
              <Route exact path="/qr-details" component={QRTemplete} />
              <Route exact path="/cashout-orders" component={cashoutOrderDetails} />
              <Route exact path="/card-cards" component={CardCardsList} />
              <Route exact path="/edit-profile" component={EditProfileScreen} />
            </Switch>
          </div>
        </div>
      </section>
    )
  }
}

// const mapStateToProps = (state, ownProps) => {
//   return {
//     isRender: state.paginationComponentReducer.isRender
//   }
// };

// const mapDispatchToProps = (dispatch) => {
//   return {
//   }
// };

// export default connect(mapStateToProps, mapDispatchToProps)(Main);
export default Home;
