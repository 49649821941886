import {SHOW_LOADER, HIDE_LOADER} from '../actionTypes';

export default function loaderReducer(state = {
  showLoader: false,
  showLoaderMessage: undefined,
}, action) {
  switch (action.type) {
    case SHOW_LOADER:
      return Object.assign({}, state, {
        showLoader: true,
        showLoaderMessage: action.data
      });
    case HIDE_LOADER:
      return Object.assign({}, state, {
        showLoader: false,
        showLoaderMessage: undefined
      });
    default:
      return state;
  }
}
