import { SET_DMR_TXS_DATA, SET_SDM_TXS_DATA } from "../actionTypes";

export default function dmReducer(
  state = {
    sdmTxsData: {
      gotResponse: false,
      txs: [],
      pageNo: 1,
      pageSize: 7,
      totalCount : 0,
    },
    dmrTxsData: {
      gotResponse: false,
      txs: [],
    },
  },
  action
) {
  switch (action.type) {
    case SET_DMR_TXS_DATA:
      return Object.assign({}, state, {
        dmrTxsData: { ...state.dmrTxsData, ...action.data },
      });
    case SET_SDM_TXS_DATA:
      return Object.assign({}, state, {
        sdmTxsData: { ...state.sdmTxsData, ...action.data },
      });

    default:
      return state;
  }
}
