import React from "react";
import BackButton from "../BackButton";
import prImg from "../../images/pr.png";
import checkedImg from "../../images/checked.png";
import { FormattedMessage } from "react-intl";
import { QrReader } from "react-qr-reader";
import { SEND_DM_SEND_STEPS } from "../../containers/DigitalMoney/SendDm";
import { toAmountFormat } from "../../services/utils";

const FM = FormattedMessage;

const SendDmPage = (props) => {
  const {
    onResultQr,
    step,
    payAmount,
    onPay,
    payDetails,
    errors,
    handleChange,
    onDone,
  } = props;

  const { shopId, shopName, shopLogo } = payDetails;
  return (
    <>
      <div class="Card-panel send-dm-qr">
        {/* <div class="Flex-col heading-col">
          <BackButton></BackButton>
          <h1></h1>
          <img src={""}></img>
        </div> */}

        {step == SEND_DM_SEND_STEPS.SCAN_QR && (
          <div class="dm-qr-reader">
            <div class="dm-qr-reader-label">
              QRコードをスキャンして <br/> お支払いください
            </div>
            <QrReader
              onResult={onResultQr}
              style={{ width: "100%", height: "100%" }}
              constraints={{
                facingMode: "environment",
              }}
            />
            <div class="dm-qr-reader-label">
              カメラ枠内にQRコードを <br/> 合わせてスキャン
            </div>
          </div>
        )}
        <div class="tab-content" id="myTabContent">
          {step == SEND_DM_SEND_STEPS.PAYING && (
            <>
              <div class="dm-PayingTo">
                <div class="dm-PayingTo-text">
                  <FM id="Paying to"></FM>
                </div>
                <img class="dm-img" src={shopLogo} height={80} width={80} ></img>
                <div class="">{shopName}</div>
                <div class="dm-amount">
                  <div class="dm-amount-currency"></div>
                  <div class="">
                    <div class="dm-amount-value">{toAmountFormat(payAmount) || 0}</div>
                    {/* <div class="">
                      <input
                        type={"number"}
                        name={"payAmount"}
                        value={payAmount}
                        onChange={handleChange}
                      />
                    </div> */}
                    <div class="dm-amount-label">
                      <FM id="Amount(JPY)"></FM>
                    </div>
                  </div>
                </div>
              </div>
              <div class="btn-footer">
                <button
                  class="btn btn-primary custom-btn big-btn"
                  onClick={onPay}
                  disabled={errors.payAmount}
                >
                  <FM id="Pay"></FM>
                </button>
              </div>
            </>
          )}
          {step == SEND_DM_SEND_STEPS.SUCCESS && (
            <div>
              <div class="dm-success">
                <div class="dm-success-msg">
                  <FM id="PAYMENT SUCCESS!"></FM>
                </div>
                <img class="dm-success-img" src={checkedImg}></img>
                <div class="dm-success-info">
                  <FM id="Your payment to the merchant was successful"></FM>
                </div>
              </div>
              <div class="btn-footer">
                <button class="btn btn-primary custom-btn" onClick={onDone}>
                  <FM id="Done"></FM>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SendDmPage;
