import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { APPINSIGHTS_KEY } from "./App.config";
let ai;
export const initAppInsights = () => {
  ai = new ApplicationInsights({
    config: {
      instrumentationKey: APPINSIGHTS_KEY(),
    },
  });
  ai.loadAppInsights();
};

export const getAppInsights = () => {
  return ai?.appInsights;
};
