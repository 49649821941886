import { LS_AUTH_DETAILS_KEY } from "../../App.config";
import {
  SET_LANGUAGE,
  SET_ORDER_CONFIG_FROM_API,
  SET_INIT_CONFIG_FROM_VBNET,
  SET_AUTH_DETAILS,
} from "../actionTypes";

// window.authDetails = {
//   sesstionId: 'z0v2knlfcbgs2bgbjouf1dpb',
//   companyId: '2',
// }

export default function appConfigReducer(
  state = {
    language: undefined, // language selection from welcome screen for i18n
    authDetails: {}, // we are reading from url to use in each api
    orderConfigFromAPI: undefined, // data from config api
    initConfigFromVbNet: undefined, // data from init call by vb.net
  },
  action
) {
  switch (action.type) {
    case SET_LANGUAGE:
      window.LANGUAGE = action.data;
      return Object.assign({}, state, { language: action.data });
    case SET_AUTH_DETAILS:
      localStorage.setItem(LS_AUTH_DETAILS_KEY, JSON.stringify(action.data));
      return Object.assign({}, state, { authDetails: action.data });
    case SET_ORDER_CONFIG_FROM_API:
      if (action.data && action.data.storeId)
        window.STORE_ID = action.data.storeId;
      return Object.assign({}, state, { orderConfigFromAPI: action.data });
    case SET_INIT_CONFIG_FROM_VBNET:
      if (action.data && action.data.kiosk_store_id)
        window.KIOSK_ID = action.data.kiosk_store_id;
      return Object.assign({}, state, { initConfigFromVbNet: action.data });
    default:
      return state;
  }
}
