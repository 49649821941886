import {SHOW_ALERT, HIDE_ALERT} from '../actionTypes';

export default function alertPopupReducer(state = {
  showAlert: false,
  showAlertMessage: undefined,
}, action) {
  switch (action.type) {
    case SHOW_ALERT:
      return Object.assign({}, state, {
        showAlert: true,
        showAlertMessage: action.data
      });
    case HIDE_ALERT:
      return Object.assign({}, state, {
        showAlert: false,
        showAlertMessage: undefined
      });
    default:
      return state;
  }
}
