import * as apiService from "../../services/apiService";
import { SET_ALL_CARDS } from "../actionTypes";
import * as loaderActions from "./loaderActions";
import * as confirmDialogActions from "./confirmDialogActions";

export function setAllCards(data) {
  return {
    type: SET_ALL_CARDS,
    data: data,
  };
}

export function getAndSetAllCards() {
  return (dispatch, store) => {
    dispatch(loaderActions.showLoader());
    apiService.getAllCards().then(
      (response) => {
        dispatch(loaderActions.hideLoader());
        response = response.data;
        if (response.cards) {
          dispatch(setAllCards(response.cards));
        } else {
          dispatch(setAllCards([]));
        }
      },
      (error) => {
        dispatch(loaderActions.hideLoader());
        dispatch(setAllCards([]));
      }
    );
  };
}

export function sendCard(cardData, callBack) {
  return (dispatch, store) => {
    dispatch(loaderActions.showLoader());
    apiService.sendCard(cardData).then(
      (response) => {
        dispatch(loaderActions.hideLoader());
        response = response.data;
        if (response?.errorMessages && response?.errorMessages?.length > 0) {
          dispatch(
            confirmDialogActions.show({
              header: response?.errorMessages[0],
              buttons: [
                {
                  label: "OK",
                },
              ],
            })
          );
        } else {
          callBack();
        }
      },
      (error) => {
        dispatch(loaderActions.hideLoader());
        alert(error);
      }
    );
  };
}
