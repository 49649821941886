import { SET_ALL_MENU_ITEMS, SET_GOT_MENU_ITEMS_RESPONSE, SET_ORDER_DATA, CLEAR_ORDER_DATA, SET_ALL_MENU_ITEMS_1 } from '../actionTypes';

export default function menuItemsReducer(state = {
    allMenuItems: undefined,
    isGotResponse: undefined,
    orderData: undefined,
    allMenuItems1: undefined,
}, action) {
    switch (action.type) {
        case SET_ALL_MENU_ITEMS:
            return Object.assign({}, state, { allMenuItems: action.data });
        case SET_ALL_MENU_ITEMS_1:
            return Object.assign({}, state, { allMenuItems1: action.data });
        case SET_GOT_MENU_ITEMS_RESPONSE:
            return Object.assign({}, state, { isGotResponse: action.data });
        case SET_ORDER_DATA:
            return Object.assign({}, state, { orderData: Object.assign(state.orderData||{}, action.data) });
        case CLEAR_ORDER_DATA:
            return Object.assign({}, state, { orderData: undefined});
        default:
            return state;
    }
}
