export const USER_LOGOUT = "USER_LOGOUT";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const SET_AUTH_DETAILS = "SET_AUTH_DETAILS";
export const SET_ALL_MENU_ITEMS = "SET_ALL_MENU_ITEMS";
export const SET_ALL_MENU_ITEMS_1 = "SET_ALL_MENU_ITEMS_1";
export const SET_GOT_MENU_ITEMS_RESPONSE = "SET_GOT_MENU_ITEMS_RESPONSE";
export const SET_ORDER_DATA = "SET_ORDER_DATA";
export const CLEAR_ORDER_DATA = "CLEAR_ORDER_DATA";
export const SET_ORDER_CONFIG_FROM_API = "SET_ORDER_CONFIG_FROM_API";
export const SET_INIT_CONFIG_FROM_VBNET = "SET_INIT_CONFIG_FROM_VBNET";
export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";
export const SHOW_ALERT = "SHOW_ALERT";
export const HIDE_ALERT = "HIDE_ALERT";
export const SHOW_CONFIRM_DIALOG = "SHOW_CONFIRM_DIALOG";
export const HIDE_CONFIRM_DIALOG = "HIDE_CONFIRM_DIALOG";

export const SET_TOP_DATA = "SET_TOP_DATA";
export const SET_TOP_DATA_GOT_RESPONSE = "SET_TOP_DATA_GOT_RESPONSE";

export const SET_TREND_CHART_DATA = "SET_TREND_CHART_DATA";
export const SET_TREND_CHART_DATA_GOT_RESPONSE = "SET_TREND_CHART_DATA_GOT_RESPONSE";

export const SET_USERS_COUNTS_DATA = "SET_USERS_COUNTS_DATA";
export const SET_USERS_COUNTS_DATA_GOT_RESPONSE = "SET_USERS_COUNTS_DATA_GOT_RESPONSE";

export const SET_TOP_EMPLOYEES_POINTS_DATA = "SET_TOP_EMPLOYEES_POINTS_DATA";
export const SET_TOP_EMPLOYEES_POINTS_DATA_GOT_RESPONSE = "SET_TOP_EMPLOYEES_POINTS_DATA_GOT_RESPONSE";

export const SET_DEPARTMENT_WISE_POINTS_DATA = "SET_DEPARTMENT_WISE_POINTS_DATA";
export const SET_DEPARTMENT_WISE_POINTS_DATA_GOT_RESPONSE = "SET_DEPARTMENT_WISE_POINTS_DATA_GOT_RESPONSE";

export const SET_EMPLOYEE_DETAILS_WITH_DEPARTMENT_WISE_DATA = "SET_EMPLOYEE_DETAILS_WITH_DEPARTMENT_WISE_DATA";
export const SET_EMPLOYEE_DETAILS_WITH_DEPARTMENT_WISE_DATA_GOT_RESPONSE = "SET_EMPLOYEE_DETAILS_WITH_DEPARTMENT_WISE_DATA_GOT_RESPONSE";

export const SET_EMPLOYEE_WISE_DEPARTMENTS_ANALYSIS_CHART_DATA = "SET_EMPLOYEE_WISE_DEPARTMENTS_ANALYSIS_CHART_DATA";
export const SET_EMPLOYEE_WISE_DEPARTMENTS_ANALYSIS_CHART_DATA_GOT_RESPONSE = "SET_EMPLOYEE_WISE_DEPARTMENTS_ANALYSIS_CHART_DATA_GOT_RESPONSE";

export const SET_DEPARTMENT_TO_DEPARTMENTS_DATA = "SET_DEPARTMENT_TO_DEPARTMENTS_DATA";
export const SET_DEPARTMENT_TO_DEPARTMENTS_DATA_GOT_RESPONSE = "SET_DEPARTMENT_TO_DEPARTMENTS_DATA_GOT_RESPONSE";

export const SET_DEPARTMENT_TO_DEPARTMENTS_ANALYSIS_DATA = "SET_DEPARTMENT_TO_DEPARTMENTS_ANALYSIS_DATA";
export const SET_DEPARTMENT_TO_DEPARTMENTS_ANALYSIS_DATA_GOT_RESPONSE = "SET_DEPARTMENT_TO_DEPARTMENTS_ANALYSIS_DATA_GOT_RESPONSE";

export const SET_EMPLOYEE_CONN_DATA = "SET_EMPLOYEE_CONN_DATA";
export const SET_EMPLOYEE_CONN_DATA_GOT_RESPONSE = "SET_EMPLOYEE_CONN_DATA_GOT_RESPONSE";

export const SET_DEPT_CONN_DATA = "SET_DEPT_CONN_DATA";
export const SET_DEPT_CONN_DATA_GOT_RESPONSE = "SET_DEPT_CONN_DATA_GOT_RESPONSE";

export const SET_TRANSACTIONS_DATA = "SET_TRANSACTIONS_DATA";
export const SET_TRANSACTIONS_DATA_GOT_RESPONSE = "SET_TRANSACTIONS_DATA_GOT_RESPONSE";

export const SET_USER_PROFILE_DATA = "SET_USER_PROFILE_DATA";

export const SET_USERS_DATA = "SET_USERS_DATA";
export const SET_USERS_DATA_GOT_RESPONSE = "SET_USERS_DATA_GOT_RESPONSE";

export const SET_TRANSACTION_DETAILS_DATA = "SET_TRANSACTION_DETAILS_DATA";
export const SET_TRANSACTION_DETAILS_DATA_GOT_RESPONSE = "SET_TRANSACTION_DETAILS_DATA_GOT_RESPONSE";

export const SET_EMPLOYEE_TREND_CHART_DATA = "SET_EMPLOYEE_TREND_CHART_DATA";
export const SET_EMPLOYEE_TREND_CHART_DATA_GOT_RESPONSE = "SET_EMPLOYEE_TREND_CHART_DATA_GOT_RESPONSE";

export const SET_DEPT_TREND_CHART_DATA = "SET_DEPT_TREND_CHART_DATA";
export const SET_DEPT_TREND_CHART_DATA_GOT_RESPONSE = "SET_DEPT_TREND_CHART_DATA_GOT_RESPONSE";

export const SET_EMPLOYEES_SEARCH_RESULT = "SET_EMPLOYEES_SEARCH_RESULT";
export const SET_EMPLOYEES_SEARCH_RESULT_GOT_RESPONSE = "SET_EMPLOYEES_SEARCH_RESULT_GOT_RESPONSE";

export const SET_ALL_EMPLOYEES = "SET_ALL_EMPLOYEES";
export const SET_ALL_EMPLOYEES_TOTAL_ROWS = "SET_ALL_EMPLOYEES_TOTAL_ROWS";
export const SET_ALL_EMPLOYEES_GOT_RESPONSE = "SET_ALL_EMPLOYEES_GOT_RESPONSE";

export const SET_DEPARTMENTS = "SET_DEPARTMENTS";
export const SET_DEPARTMENTS_GOT_RESPONSE = "SET_DEPARTMENTS_GOT_RESPONSE";

export const SET_ALL_EMPLOYEES_TO_DEPARTMENTS_DATA = "SET_ALL_EMPLOYEES_TO_DEPARTMENTS_DATA";
export const SET_ALL_EMPLOYEES_TO_DEPARTMENTS_DATA_GOT_RESPONSE = "SET_ALL_EMPLOYEES_TO_DEPARTMENTS_DATA_GOT_RESPONSE";

export const SET_ALL_EMPLOYEES_TO_DEPARTMENTS_CHART_DATA = "SET_ALL_EMPLOYEES_TO_DEPARTMENTS_CHART_DATA";
export const SET_ALL_EMPLOYEES_TO_DEPARTMENTS_CHART_DATA_GOT_RESPONSE = "SET_ALL_EMPLOYEES_TO_DEPARTMENTS_CHART_DATA_GOT_RESPONSE";

export const SET_ALL_EMPLOYEES_TREND_CHART_DATA = "SET_ALL_EMPLOYEES_TREND_CHART_DATA";
export const SET_ALL_EMPLOYEES_TREND_CHART_DATA_GOT_RESPONSE = "SET_ALL_EMPLOYEES_TREND_CHART_DATA_GOT_RESPONSE";

export const SET_ALL_EMPLOYEES_CONN_DATA = "SET_ALL_EMPLOYEES_CONN_DATA";
export const SET_ALL_EMPLOYEES_CONN_DATA_GOT_RESPONSE = "SET_ALL_EMPLOYEES_CONN_DATA_GOT_RESPONSE";

export const SET_EMPLOYEE_DIFF_TYPES_OF_POINTS = "SET_EMPLOYEE_DIFF_TYPES_OF_POINTS";
export const SET_EMPLOYEE_DIFF_TYPES_OF_POINTS_GOT_RESPONSE = "SET_EMPLOYEE_DIFF_TYPES_OF_POINTS_GOT_RESPONSE";

export const SET_MY_CARD_DATA = "SET_MY_CARD_DATA";
export const SET_CASHOUT_ORDERS = "SET_CASHOUT_ORDERS";
export const SET_RECEIVED_ORDERS = "SET_RECEIVED_ORDERS";
export const SET_QR_DETAILS = "SET_QR_DETAILS";
export const SET_EMP_STATUS = "SET_EMP_STATUS";
export const SET_MY_CARD_REDEEM_ORDER_DATA = "SET_MY_CARD_REDEEM_ORDER_DATA";
export const SET_MY_CARD_CARD_CARDS = "SET_MY_CARD_CARD_CARDS";
export const SET_MY_CARD_CARD_CARDS_SUMMARY = "SET_MY_CARD_CARD_CARDS_SUMMARY";

export const SET_ORDER_DETAILS = "SET_ORDER_DETAILS";

export const SET_ALL_CARDS = "SET_ALL_CARDS";
export const SET_ALL_CARDS_GOT_RESPONSE = "SET_ALL_CARDS_GOT_RESPONSE";

export const SET_DMR_TXS_DATA = "SET_DMR_TXS_DATA";
export const SET_SDM_TXS_DATA = "SET_SDM_TXS_DATA";

export const SET_APP_HEADER_DATA = "SET_APP_HEADER_DATA";