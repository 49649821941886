import React, { Component } from "react";
import "../../css/style.css";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as appHeaderActions from "../../redux/actions/appHeaderActions";
import history from "../../history";
import Moment from "react-moment";
import { FormattedMessage } from "react-intl";
import * as qs from "qs";
import { API_URL } from "../../App.config";

const FM = FormattedMessage;

class CardCardsList extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
    };
  }

  componentDidMount() {
    let objQueryParams = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });

    if (objQueryParams.tab && objQueryParams.tab === 'claimInitiate') {
      this.props.appHeaderActions.setData({ title: "申請済み", isBack: true });
    } else {
      this.props.appHeaderActions.setData({ title: "請求承認", isBack: true });
    }
  }

  goToMyCardDetails = (orderId) => {
    history.push({
      pathname: "/my-card-details",
      state: {
        orderId: orderId,
        isActiveCardShown: false,
      },
    });
  };

  render() {
    return (
      <>
        <div class="cashout-orders">
          <div class="Card-Detail-Grid">
            <div class="Card-Detail-Card">
              <div class="Card-Detail-Item"><FM id="Ref"></FM></div>
              <div class="Card-Detail-Value">
                {this.props.myCardCardCardsSummary.qrRefID}
              </div>
            </div>
            <div class="Card-Detail-Card">
              <div class="Card-Detail-Item"><FM id="Cards1"></FM></div>
              <div class="Card-Detail-Value">
                {this.props.myCardCardCardsSummary.totalCards}
              </div>
            </div>
            <div class="Card-Detail-Card">
              <div class="Card-Detail-Item"><FM id="Value"></FM></div>
              <div class="Card-Detail-Value">
                {this.props.myCardCardCardsSummary.value}
              </div>
            </div>
            <div class="Card-Detail-Card">
              <div class="Card-Detail-Item"><FM id="Total"></FM></div>
              <div class="Card-Detail-Value Card-Detail-Value-Total">
                ¥ {this.props.myCardCardCardsSummary.totalPointsAmount}
              </div>
            </div>
          </div>
          <div class="Card-panel">
            <div class="Cashout-list">
              <div class="My-card Contact-listing">
                <ul>
                  {this.props &&
                    Array.isArray(this.props.myCardCardCards) &&
                    this.props.myCardCardCards.length > 0 ? (
                    this.props.myCardCardCards.map((cardDetail) => {
                      return (
                        <li>
                          <div
                            class="Flex-col flex-start"
                            onClick={() => {
                              this.goToMyCardDetails(
                                cardDetail.orderId
                              );
                            }}
                          >
                            <div>
                              <div class="Card-box pink-box">
                                <div>
                                  <img
                                    height="50"
                                    width="88"
                                    src={API_URL + cardDetail.imageUrl}
                                  />
                                </div>
                              </div>
                              <div class="contact-info contact-info-flex">
                                <Moment format="YYYY-MM-DD - HH:mm">
                                  {cardDetail.createdDate}
                                </Moment>
                              </div>
                            </div>
                            <div>
                              <div class="text-right">
                                <span>
                                  <FM id="Value"></FM>
                                </span>
                                <span class="card-price">
                                  <span>¥</span>
                                  {cardDetail.defaultCardValue}
                                </span>
                              </div>
                            </div>
                          </div>
                        </li>
                      );
                    })
                  ) : (
                    <div class="No-Card">
                      <FM id="No Card Found"></FM>
                    </div>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    myCardCardCards: state.myCardReducer.myCardCardCards,
    myCardCardCardsSummary: state.myCardReducer.myCardCardCardsSummary,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    appHeaderActions: bindActionCreators(appHeaderActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CardCardsList);
