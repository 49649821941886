import { SHOW_LOADER, HIDE_LOADER } from "../actionTypes";

let loaderCounter = 0;
export function showLoader(message) {
  loaderCounter++;
  return {
    type: SHOW_LOADER,
    data: message,
  };
}
export function hideLoader(force) {
  loaderCounter--;
  return {
    type: !force && loaderCounter > 0 ? "__NO_ACTION_TYPE__" : HIDE_LOADER,
  };
}
