import React from 'react';
import {FormattedMessage} from 'react-intl';
const FM = FormattedMessage;
const AlertPopup = props => {
    return (
        <div class="modal fade show" style={{paddingRight: '17px', display: 'block', zIndex: 99999}}  id="Select-drink-Modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
            <div class="modal-body">
                <div class="Alcohol-wrapp">
                    <h5>{props.message}</h5>
                    <div class="flex-col">
                    <a href="JavaScript:Void(0);" class="modal-btn" data-dismiss="modal" aria-label="Close" onClick={props.onClickOk}><FM id="fmp.text.OK"></FM></a>
                    </div>
                </div>
            </div>
            </div>
        </div>
        <div class="modal-backdrop fade show"></div>
        </div>
    )
}

export default AlertPopup;  