// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-image-selector-root {
  height: 100px;
  width: 100px;
  position: relative;
}

.user-image-selector-img {
  height: 100%;
  width: 100%;
  position: absolute;
  border-radius: 75px;
}

.user-image-selector-file-input {
  opacity: 0;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/UserImageSelecter/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,UAAU;EACV,eAAe;AACjB","sourcesContent":[".user-image-selector-root {\r\n  height: 100px;\r\n  width: 100px;\r\n  position: relative;\r\n}\r\n\r\n.user-image-selector-img {\r\n  height: 100%;\r\n  width: 100%;\r\n  position: absolute;\r\n  border-radius: 75px;\r\n}\r\n\r\n.user-image-selector-file-input {\r\n  opacity: 0;\r\n  cursor: pointer;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
