import React from "react";
import UserImg from "../../images/user-default-img.jpeg";
import defaultGiftCardImg from "../../images/defailt-gift-card.png";
import { FormattedMessage } from "react-intl";
import BackButton from "../BackButton";
import Moment from "react-moment";
import UserIcon from "../../containers/SendCard/UserIcon";
import { API_URL } from "../../App.config";
const FM = FormattedMessage;

const MyCardDetailsTemplete = (props) => {
  return (
    <>
      <div class="Content-Panel">
        <div class="Content-Container">
          {/* <div class="Flex-col heading-col">
            <BackButton></BackButton>
            <h1></h1>
            <img src=""></img>
          </div> */}
          <div class="Card-panel">
            <div class="Card Chart-card Chard-active">
              <div class="My-card-detail">
                {!props.isSent && (
                  <div class="Card-Details">
                    <div class="My-card-detail-from">
                      <FM id="From"></FM>
                    </div>
                    <div class="My-card-detail-user">
                      <img src={UserImg}></img>
                    </div>
                    <div class="My-card-detail-time">
                      <Moment format="YYYY-MM-DD - HH:mm">
                        {props.orderDetails.order?.card?.modifiedDate}
                      </Moment>
                    </div>
                    <div class="My-card-detail-card">
                      <img
                        height="120"
                        width="auto"
                        src={
                          props.orderDetails?.order?.card?.imageUrl ? API_URL + props.orderDetails.order.card.imageUrl : defaultGiftCardImg
                        }
                      ></img>
                    </div>
                  </div>
                )}

                {props.isSent && (
                  <div class="Card-send">
                    <div
                      class="Card-send-detail"
                      style={{ backgroundColor: "transparent" }}
                    >
                      <div class="text-center">
                        <h2>
                          {props.isActiveCardShown ? (
                            <FM id="card-active"></FM>
                          ) : (
                            <FM id="card-archived"></FM>
                          )}
                        </h2>
                        <div class="My-card-detail-from">
                          <FM id="To"></FM>
                        </div>
                        <div
                          class="contact-img"
                          style={{ margin: "10px auto" }}
                        >
                          <UserIcon vcaid={props?.orderDetails?.order?.receiverEmployee?.employeeID} />
                        </div>
                        <div class="contact-info">
                          <h4>
                            {props.orderDetails &&
                              props.orderDetails.order &&
                              props.orderDetails.order.receiverEmployee &&
                              props.orderDetails.order.receiverEmployee.user &&
                              props.orderDetails.order.receiverEmployee.user
                                .lastName +
                              " " +
                              props.orderDetails.order.receiverEmployee.user
                                .firstName}
                          </h4>
                          <span>
                            <FM id="text.label.EMPID"></FM>:{" "}
                            {props.orderDetails &&
                              props.orderDetails.order &&
                              props.orderDetails.order.receiverEmployee &&
                              props.orderDetails.order.receiverEmployee
                                .employeeID}
                          </span>
                        </div>
                      </div>
                      <div class="Flex-col">
                        <div className="Card-box">
                          <img
                            src={
                              props.orderDetails?.order?.card?.imageUrl ? API_URL + props.orderDetails.order.card.imageUrl : defaultGiftCardImg
                            }
                            height={60}
                          />
                        </div>
                        <div class="Card-info-d">
                          <div>
                            <FM id="Gift card value"></FM>
                          </div>
                          <span>
                            <span>¥</span>
                            {props?.orderDetails?.order?.card?.amount || 0}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

               
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyCardDetailsTemplete;
