import * as apiService from '../../services/apiService';
import { SET_CASHOUT_ORDERS, SET_EMP_STATUS, SET_MY_CARD_CARD_CARDS, SET_MY_CARD_CARD_CARDS_SUMMARY, SET_MY_CARD_DATA, SET_MY_CARD_REDEEM_ORDER_DATA, SET_ORDER_DETAILS, SET_QR_DETAILS, SET_RECEIVED_ORDERS } from '../actionTypes';
import * as loaderActions from "./loaderActions";

export function setMyCardData(data) {
    return {
        type: SET_MY_CARD_DATA,
        data: data
    }
}

export function setCashoutOrders(data) {
    return {
        type: SET_CASHOUT_ORDERS,
        data: data
    }
}

export function setReceivedOrdersData(data) {
    return {
        type: SET_RECEIVED_ORDERS,
        data: data
    }
}

export function setQRDetails(data) {
    return {
        type: SET_QR_DETAILS,
        data: data
    }
}

export function setEmpStatus(data) {
    return {
        type: SET_EMP_STATUS,
        data: data
    }
}

export function setMyCardRedeemOrderData(data) {
    return {
        type: SET_MY_CARD_REDEEM_ORDER_DATA,
        data: data
    }
}

export function setMyCardCardCards(data) {
    return {
        type: SET_MY_CARD_CARD_CARDS,
        data: data
    }
}
export function setMyCardCardCardsSummary(data) {
    return {
        type: SET_MY_CARD_CARD_CARDS_SUMMARY,
        data: data
    }
}

export function setOrderDetails(data) {
    return {
        type: SET_ORDER_DETAILS,
        data: data
    }
}

export function getMyCardsDetails(paginationPayLoad) {
    return (dispatch, store) => {
        dispatch(loaderActions.showLoader());
        apiService.getmyCardsDetails(paginationPayLoad).then(
            response => {
                dispatch(loaderActions.hideLoader());
                response = response.data;
                if (response) {
                    dispatch(setMyCardData(response));
                } else {
                    dispatch(setMyCardData([]));
                }
            },
            error => {
                dispatch(loaderActions.hideLoader());
                dispatch(setMyCardData([]));
            }
        );
    };
}

export function getCashoutOrders(paginationPayLoad, callBackFn) {
    return (dispatch, store) => {
        dispatch(loaderActions.showLoader());
        apiService.getCashoutOrdersDetails(paginationPayLoad).then(
            response => {
                dispatch(loaderActions.hideLoader());
                response = response.data;
                if (response) {
                    dispatch(setCashoutOrders(response));
                    callBackFn && callBackFn(true);
                } else {
                    dispatch(setCashoutOrders(undefined));
                    callBackFn && callBackFn(false);
                }
            },
            error => {
                dispatch(loaderActions.hideLoader());
                dispatch(setCashoutOrders(undefined));
                callBackFn && callBackFn(false);
            }
        );
    };
}

export function getReceivedStatus() {
    return (dispatch, store) => {
        dispatch(loaderActions.showLoader());
        apiService.getStatusOfEmp().then(
            response => {
                dispatch(loaderActions.hideLoader());
                response = response.data;
                if (response) {
                    dispatch(setEmpStatus(response.receivedOrderStatus));
                } else {
                    dispatch(setEmpStatus([]));
                }
            },
            error => {
                dispatch(loaderActions.hideLoader());
                dispatch(setEmpStatus([]));
            }
        );
    };
}

export function getReceivedOrders(paginationPayLoad) {
    return (dispatch, store) => {
        dispatch(loaderActions.showLoader());
        apiService.getReceivedStatusOrders(paginationPayLoad).then(
            response => {
                dispatch(loaderActions.hideLoader());
                response = response.data;
                if (response) {
                    dispatch(setReceivedOrdersData(response));
                } else {
                    dispatch(setReceivedOrdersData(undefined));
                }
            },
            error => {
                dispatch(loaderActions.hideLoader());
                dispatch(setReceivedOrdersData(undefined));
            }
        );
    };
}

export function getQrDetails(qrId, callBackFn) {
    return (dispatch, store) => {
        dispatch(loaderActions.showLoader());
        apiService.getQrDetailsById(qrId).then(
            response => {
                dispatch(loaderActions.hideLoader());
                response = response.data;
                if (response) {
                    dispatch(setQRDetails(response));
                    callBackFn && callBackFn(true);
                } else {
                    dispatch(setQRDetails(undefined));
                    callBackFn && callBackFn(false);
                }
            },
            error => {
                dispatch(loaderActions.hideLoader());
                dispatch(setQRDetails(undefined));
                callBackFn && callBackFn(false);
            }
        );
    };
}

export function getMyCardRedeemOrders(paginationPayLoad) {
    return (dispatch, store) => {
        dispatch(loaderActions.showLoader());
        apiService.getRedeemedStatusOrder(paginationPayLoad).then(
            response => {
                dispatch(loaderActions.hideLoader());
                response = response.data;
                if (response) {
                    dispatch(setMyCardRedeemOrderData(response));
                } else {
                    dispatch(setMyCardRedeemOrderData([]));
                }
            },
            error => {
                dispatch(loaderActions.hideLoader());
                dispatch(setMyCardRedeemOrderData([]));
            }
        );
    };
}

export function getClaimCardOrders(payload) {
    return (dispatch, store) => {
        dispatch(loaderActions.showLoader());
        apiService.getClaimCardOrders(payload).then(
            response => {
                dispatch(loaderActions.hideLoader());
                response = response.data;
                if (response) {

                    // temp code, future will handle from BE
                    if (response.cashoutOrders) {
                        const cashoutOrders = response.cashoutOrders;
                        for (const coi in cashoutOrders) {
                            cashoutOrders[coi].cardValue = 100;
                            cashoutOrders[coi].totalCards = cashoutOrders[coi].totalPointsAmount / 100;
                        }
                    }
                    //
                    
                    dispatch(setMyCardRedeemOrderData(response));
                } else {
                    dispatch(setMyCardRedeemOrderData([]));
                }
            },
            error => {
                dispatch(loaderActions.hideLoader());
                dispatch(setMyCardRedeemOrderData([]));
            }
        );
    };
}

export function getClaimCardOrderCards(payload, isInitTab) {
    return (dispatch, store) => {
        dispatch(loaderActions.showLoader());
        apiService.getClaimCardOrderCards(payload).then(
            response => {
                dispatch(loaderActions.hideLoader());
                response = response.data;
                if (response) {
                    const cards = isInitTab ? response.claimInitOrders : response.claimApprovedOrders;
                    // TEMP , future will calculate from BE
                    const cardsSummary = {
                        qrRefID: payload.claimApprovedTransId || payload.refId, //display purpose
                        totalCards: cards.length,
                        value: 100,
                        totalPointsAmount: cards.length * 100,
                    }
                    ///

                    dispatch(setMyCardCardCards(cards));
                    dispatch(setMyCardCardCardsSummary(cardsSummary));

                } else {
                    dispatch(setMyCardCardCards([]));
                    dispatch(setMyCardCardCardsSummary({}));
                }
            },
            error => {
                dispatch(loaderActions.hideLoader());
                dispatch(setMyCardCardCards([]));
                dispatch(setMyCardCardCardsSummary({}));
            }
        );
    };
}


export function getOrderDetails(orderId) {
    return (dispatch, store) => {
        dispatch(loaderActions.showLoader());
        apiService.getOrderById(orderId).then(
            response => {
                dispatch(loaderActions.hideLoader());
                response = response.data;
                if (response) {
                    dispatch(setOrderDetails(response));
                } else {
                    dispatch(setOrderDetails([]));
                }
            },
            error => {
                dispatch(loaderActions.hideLoader());
                dispatch(setOrderDetails([]));
            }
        );
    };
}

export function generateQR(callBackFn) {
    return (dispatch, store) => {
        dispatch(loaderActions.showLoader());
        apiService.generateQRCode().then(
            response => {
                dispatch(loaderActions.hideLoader());
                response = response.data;
                if (response?.errorMessages.length <= 0 && response) {
                    dispatch(setQRDetails(response));
                    callBackFn && callBackFn(true, "");
                } else {
                    dispatch(setQRDetails([]));
                    callBackFn && callBackFn(false, response?.errorMessages[0]);
                }
            },
            error => {
                dispatch(loaderActions.hideLoader());
                dispatch(setQRDetails([]));
                callBackFn && callBackFn(false);
            }
        );
    };
}

export function initiateClaims(callBackFn) {
    return (dispatch, store) => {
        dispatch(loaderActions.showLoader());
        apiService.initiateClaims().then(
            response => {
                dispatch(loaderActions.hideLoader());
                response = response.data;
                if (response?.errorMessages.length <= 0 && response) {
                    callBackFn && callBackFn(true, "");
                } else {
                    callBackFn && callBackFn(false, response?.errorMessages[0]);
                }
            },
            error => {
                dispatch(loaderActions.hideLoader());
                callBackFn && callBackFn(false);
            }
        );
    };
}