import * as apiService from "../../services/apiService";
import { LANGUAGE } from "../../App.config";
import {
  SET_LANGUAGE,
  SET_ORDER_CONFIG_FROM_API,
  SET_INIT_CONFIG_FROM_VBNET,
  SET_AUTH_DETAILS,
} from "../actionTypes";

export function setLanguage(lang) {
  //code for add class in body when chinese langulage selected to change font
  if (lang == LANGUAGE.ZH) {
    document.body.classList.add("CH-Font");
  } else {
    document.body.classList.remove("CH-Font");
  }

  return {
    type: SET_LANGUAGE,
    data: lang,
  };
}

export function setAuthDetails(details) {
  return {
    type: SET_AUTH_DETAILS,
    data: details,
  };
}

export function setOrderConfigFromAPI(configs) {
  return {
    type: SET_ORDER_CONFIG_FROM_API,
    data: configs,
  };
}
export function setInitConfigFromVbNet(configs) {
  return {
    type: SET_INIT_CONFIG_FROM_VBNET,
    data: configs,
  };
}

export function getAndSetOrderConfigFromApi() {
  return (dispatch) => {
    apiService.getOrderConfigFromApi().then(
      (response) => {
        // //console.log(response.data);
        if (response && response.data && response.data.data) {
          // response.data.data.displayfamilyCard = true; //for testing and temp
          dispatch(setOrderConfigFromAPI(response.data.data));
        } else {
          dispatch(setOrderConfigFromAPI());
        }
      },
      (error) => {
        dispatch(setOrderConfigFromAPI());
      }
    );
  };
}
