import React, { Component } from "react";
import Login from "../../components/Login";
import * as authActions from '../../redux/actions/authActions';
import "../../css/style.css";
import history from "../../history";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class LoginScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      errors: {
        username: "",
        password: "",
      },
      isSubmitted: null,
    };
  }

  componentDidMount() {
    this.props.authActions.doLogout();
  }

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    this.setState({ [name]: value }, () => this.isValid());
  };

  handleSubmit = () => {
    this.setState({ isSubmitted: true }, () => {
      if (this.isValid()) {
        const { username, password } = this.state;
        this.props.authActions.doLogin({
          username,
          password,
        });
      }
    });
  };

  handleForgotPwd = (event) => {
    event.preventDefault();
    history.push("/forgot-password")
  };
  handleNewAccount = (event) => {
    event.preventDefault();
    history.push("/register")
  };

  isValid = () => {
    const { username, password, isSubmitted } = this.state;
    const errors = {};
    let valid = true;

    if (!isSubmitted) {
      return valid;
    }

    if (!username) {
      errors.username = "Require";
    }

    if (!password) {
      errors.password = "Require";
    }
    this.setState({ errors, errors });
    Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
    return valid;
  };


  render() {
    const { handleChange, handleSubmit, handleNewAccount, handleForgotPwd } = this;
    const props = {
      ...this.state,
      handleChange,
      handleSubmit,
      handleNewAccount,
      handleForgotPwd
    };
    return <Login {...props} />;

  }
}

const mapStateToProps = (state) => {
  return {

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authActions: bindActionCreators(authActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);
