import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import TextField from "../TextField";
const FM = FormattedMessage;

const ForgotPassword = (props) => {
  const { email, employeeId, errors, handleSubmit, handleChange, handleLogin } = props;
  return (
    <div className="Flex-col">
      <div className="login-card">
        <div class="Flex-col">
          <h1>
            <FM id={"Rewards App"} />
          </h1>

        </div>

        <TextField name="email"
          value={email}
          onChange={handleChange}
          errorText={errors?.email} placeholder="Eメールアドレス" />

        <TextField 
          name="employeeId"
          value={employeeId}
          onChange={handleChange}
          errorText={errors?.employeeId} placeholder="社員ID" />

        <div>
          <button onClick={handleSubmit} className="btn btn-primary custom-btn" ><FM id={"Forgot Password"} /></button>
        </div>

        <div className="login-links">
          <div>
            <a href="" onClick={handleLogin}><FM id={"Login"} /></a>
          </div>
        </div>
      </div>
    </div >
  );
};

export default injectIntl(ForgotPassword);
