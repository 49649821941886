import React, { Component } from "react";
import "../../css/style.css";
import { connect } from "react-redux";
import DmTxDetailPage from "../../components/DigitalMoneyPage/DmTxDetailPage";
import * as appHeaderActions from "../../redux/actions/appHeaderActions";
import history from "../../history";
import { bindActionCreators } from "redux";

class DmTxDetail extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      data: null,
    };
  }

  componentDidMount() {
    if (this.props.location.state) {
      this.setState({ data: this.props.location.state.data || {} });
    } else {
      history.push("dm");
    }
    this.props.appHeaderActions.setData({ title: null, isBack: true })
  }

  onGoBack = () => {
    history.goBack();
  };

  render() {
    const { data } = this.state;
    return (
      <>
        <DmTxDetailPage data={data} onGoBack={this.onGoBack} ></DmTxDetailPage>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    appHeaderActions: bindActionCreators(appHeaderActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DmTxDetail);
