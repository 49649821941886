import { SET_USERS_DATA, SET_USERS_DATA_GOT_RESPONSE, SET_USER_PROFILE_DATA } from "../actionTypes";
import { getReducerData } from "../../services/reduxCodeGenerator";

const actionTypes = [SET_USERS_DATA, SET_USERS_DATA_GOT_RESPONSE, SET_USER_PROFILE_DATA];

const { initialState, actionsProperties } = getReducerData(actionTypes);

export default function userReducer(state = initialState, action) {
  switch (true) {
    case actionTypes.indexOf(action.type) != -1:
      return Object.assign({}, state, {
        [actionsProperties[action.type]]: action.data,
      });
    default:
      return state;
  }
}
