import { SET_CASHOUT_ORDERS, SET_EMP_STATUS, SET_MY_CARD_CARD_CARDS, SET_MY_CARD_CARD_CARDS_SUMMARY, SET_MY_CARD_DATA, SET_MY_CARD_REDEEM_ORDER_DATA, SET_ORDER_DETAILS, SET_QR_DETAILS, SET_RECEIVED_ORDERS } from '../actionTypes';

export default function myCardReducer(state = {
  myCardData: [],
  receivedCardData: [],
  myCardRedeemOrderData: [],
  myCardCardCards: [],
  myCardCardCardsSummary: {},
  orderDetails: {},
  receivedStatus: undefined,
  qrDetails: undefined,
  cashoutDetails: undefined
}, action) {
  switch (action.type) {
    case SET_MY_CARD_DATA:
      return Object.assign({}, state, {
        myCardData: action.data
      });
    case SET_CASHOUT_ORDERS:
      return Object.assign({}, state, {
        cashoutDetails: action.data
      });
    case SET_QR_DETAILS:
      return Object.assign({}, state, {
        qrDetails: action.data
      });
    case SET_MY_CARD_REDEEM_ORDER_DATA:
      return Object.assign({}, state, {
        myCardRedeemOrderData: action.data
      });
    case SET_MY_CARD_CARD_CARDS:
      return Object.assign({}, state, {
        myCardCardCards: action.data
      });
    case SET_MY_CARD_CARD_CARDS_SUMMARY:
      return Object.assign({}, state, {
        myCardCardCardsSummary: action.data
      });
    case SET_RECEIVED_ORDERS:
      return Object.assign({}, state, {
        receivedCardData: action.data
      });
    case SET_EMP_STATUS:
      return Object.assign({}, state, {
        receivedStatus: action.data
      });
    case SET_ORDER_DETAILS:
      return Object.assign({}, state, {
        orderDetails: action.data
      });
    default:
      return state;
  }
}
