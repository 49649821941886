import { 
    SET_TOP_DATA,
    SET_TOP_DATA_GOT_RESPONSE,

    SET_TREND_CHART_DATA,
    SET_TREND_CHART_DATA_GOT_RESPONSE,

    SET_USERS_COUNTS_DATA,
    SET_USERS_COUNTS_DATA_GOT_RESPONSE,

    SET_TOP_EMPLOYEES_POINTS_DATA,
    SET_TOP_EMPLOYEES_POINTS_DATA_GOT_RESPONSE,

    SET_DEPARTMENT_WISE_POINTS_DATA,
    SET_DEPARTMENT_WISE_POINTS_DATA_GOT_RESPONSE,

    SET_EMPLOYEE_DETAILS_WITH_DEPARTMENT_WISE_DATA,
    SET_EMPLOYEE_DETAILS_WITH_DEPARTMENT_WISE_DATA_GOT_RESPONSE,

    SET_EMPLOYEE_WISE_DEPARTMENTS_ANALYSIS_CHART_DATA,
    SET_EMPLOYEE_WISE_DEPARTMENTS_ANALYSIS_CHART_DATA_GOT_RESPONSE,

    SET_DEPARTMENT_TO_DEPARTMENTS_DATA,
    SET_DEPARTMENT_TO_DEPARTMENTS_DATA_GOT_RESPONSE,

    SET_DEPARTMENT_TO_DEPARTMENTS_ANALYSIS_DATA,
    SET_DEPARTMENT_TO_DEPARTMENTS_ANALYSIS_DATA_GOT_RESPONSE,

    SET_EMPLOYEE_CONN_DATA,
    SET_EMPLOYEE_CONN_DATA_GOT_RESPONSE,

    SET_DEPT_CONN_DATA,
    SET_DEPT_CONN_DATA_GOT_RESPONSE,

    SET_EMPLOYEE_TREND_CHART_DATA,
    SET_EMPLOYEE_TREND_CHART_DATA_GOT_RESPONSE,

    SET_DEPT_TREND_CHART_DATA,
    SET_DEPT_TREND_CHART_DATA_GOT_RESPONSE,

    SET_EMPLOYEES_SEARCH_RESULT,
    SET_EMPLOYEES_SEARCH_RESULT_GOT_RESPONSE,

    SET_ALL_EMPLOYEES,
    SET_ALL_EMPLOYEES_TOTAL_ROWS,
    SET_ALL_EMPLOYEES_GOT_RESPONSE,

    SET_DEPARTMENTS,
    SET_DEPARTMENTS_GOT_RESPONSE,

    SET_ALL_EMPLOYEES_TO_DEPARTMENTS_DATA,
    SET_ALL_EMPLOYEES_TO_DEPARTMENTS_DATA_GOT_RESPONSE,

    SET_ALL_EMPLOYEES_TO_DEPARTMENTS_CHART_DATA,
    SET_ALL_EMPLOYEES_TO_DEPARTMENTS_CHART_DATA_GOT_RESPONSE,

    SET_ALL_EMPLOYEES_TREND_CHART_DATA,
    SET_ALL_EMPLOYEES_TREND_CHART_DATA_GOT_RESPONSE,
    
    SET_ALL_EMPLOYEES_CONN_DATA,
    SET_ALL_EMPLOYEES_CONN_DATA_GOT_RESPONSE,

    SET_EMPLOYEE_DIFF_TYPES_OF_POINTS,
    SET_EMPLOYEE_DIFF_TYPES_OF_POINTS_GOT_RESPONSE

} from '../actionTypes';
import { getReducerData } from '../../services/reduxCodeGenerator';

const actionTypes = [
    SET_TOP_DATA
    ,SET_TOP_DATA_GOT_RESPONSE

    ,SET_TREND_CHART_DATA
    ,SET_TREND_CHART_DATA_GOT_RESPONSE

    ,SET_USERS_COUNTS_DATA
    ,SET_USERS_COUNTS_DATA_GOT_RESPONSE

    ,SET_TOP_EMPLOYEES_POINTS_DATA
    ,SET_TOP_EMPLOYEES_POINTS_DATA_GOT_RESPONSE

    ,SET_DEPARTMENT_WISE_POINTS_DATA
    ,SET_DEPARTMENT_WISE_POINTS_DATA_GOT_RESPONSE

    ,SET_EMPLOYEE_DETAILS_WITH_DEPARTMENT_WISE_DATA
    ,SET_EMPLOYEE_DETAILS_WITH_DEPARTMENT_WISE_DATA_GOT_RESPONSE

    ,SET_EMPLOYEE_WISE_DEPARTMENTS_ANALYSIS_CHART_DATA
    ,SET_EMPLOYEE_WISE_DEPARTMENTS_ANALYSIS_CHART_DATA_GOT_RESPONSE

    ,SET_DEPARTMENT_TO_DEPARTMENTS_DATA
    ,SET_DEPARTMENT_TO_DEPARTMENTS_DATA_GOT_RESPONSE

    ,SET_DEPARTMENT_TO_DEPARTMENTS_ANALYSIS_DATA
    ,SET_DEPARTMENT_TO_DEPARTMENTS_ANALYSIS_DATA_GOT_RESPONSE

    ,SET_EMPLOYEE_CONN_DATA
    ,SET_EMPLOYEE_CONN_DATA_GOT_RESPONSE

    ,SET_DEPT_CONN_DATA
    ,SET_DEPT_CONN_DATA_GOT_RESPONSE

    ,SET_EMPLOYEE_TREND_CHART_DATA
    ,SET_EMPLOYEE_TREND_CHART_DATA_GOT_RESPONSE

    ,SET_DEPT_TREND_CHART_DATA
    ,SET_DEPT_TREND_CHART_DATA_GOT_RESPONSE

    ,SET_EMPLOYEES_SEARCH_RESULT
    ,SET_EMPLOYEES_SEARCH_RESULT_GOT_RESPONSE

    ,SET_ALL_EMPLOYEES
    ,SET_ALL_EMPLOYEES_TOTAL_ROWS
    ,SET_ALL_EMPLOYEES_GOT_RESPONSE

    ,SET_DEPARTMENTS
    ,SET_DEPARTMENTS_GOT_RESPONSE

    ,SET_ALL_EMPLOYEES_TO_DEPARTMENTS_DATA
    ,SET_ALL_EMPLOYEES_TO_DEPARTMENTS_DATA_GOT_RESPONSE

    ,SET_ALL_EMPLOYEES_TO_DEPARTMENTS_CHART_DATA
    ,SET_ALL_EMPLOYEES_TO_DEPARTMENTS_CHART_DATA_GOT_RESPONSE

    ,SET_ALL_EMPLOYEES_TREND_CHART_DATA
    ,SET_ALL_EMPLOYEES_TREND_CHART_DATA_GOT_RESPONSE

    ,SET_ALL_EMPLOYEES_CONN_DATA
    ,SET_ALL_EMPLOYEES_CONN_DATA_GOT_RESPONSE

    ,SET_EMPLOYEE_DIFF_TYPES_OF_POINTS
    ,SET_EMPLOYEE_DIFF_TYPES_OF_POINTS_GOT_RESPONSE
];

const {initialState, actionsProperties} = getReducerData(actionTypes);

export default function dashboardReducer(state = initialState, action) {
    /* we can use this method as well 
    if(actionTypes.indexOf(action.type) != -1){
        return Object.assign({}, state, { [actionsProperties[action.type]]: action.data })
    }
    else{
        return state;
    }
    */

    // switch (action.type) {
    switch (true) {
        case actionTypes.indexOf(action.type) != -1:
            return Object.assign({}, state, { [actionsProperties[action.type]]: action.data })
        
        // case SET_ORDER_DATA:
        //     return Object.assign({}, state, { orderData: Object.assign(state.orderData||{}, action.data) });
        // case CLEAR_ORDER_DATA:
        //     return Object.assign({}, state, { orderData: undefined});
        default:
            return state;
    }
}
