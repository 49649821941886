import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import TextField from "../TextField";
import RadioGroupField from "../RadioGroupField";
import UserImageSelecter from "../UserImageSelecter";
const FM = FormattedMessage;

const EditProfile = (props) => {
  const { employeeId, email, password, confirmPassword, firstName, lastName, phoneNumber, gender, dob, profileImage, errors, handleSubmit, handleChange, enableEdit, handleEdit } = props;
  return (<>
    <div className="content-center-card">
      <div className="d-flex justify-content-center">
        <UserImageSelecter name={'profileImage'} imageUrl={profileImage} onChange={handleChange} disabled={!enableEdit} />
      </div>
      <div style={{ marginTop: '20px' }}></div>
      <TextField name="email"
        label={<FM id={"Email Address"} />} 
        value={email}
        onChange={handleChange}
        disabled={true}
        errorText={errors?.email} placeholder={""} />

      <div style={{ marginTop: '10px' }}></div>
      {enableEdit && <>
        <TextField type="password"
          label={<FM id={'New Password'} />}
          name="password"
          value={password}
          onChange={handleChange}
          autocomplete="new-password"
          errorText={errors?.password} placeholder={""} />

        <div style={{ marginTop: '10px' }}></div>
        <TextField type="password"
          label={<FM id={'Confirm Password'} />}
          name="confirmPassword"
          value={confirmPassword}
          onChange={handleChange}
          errorText={errors?.confirmPassword} placeholder={""} />
      </>
      }

      <div style={{ marginTop: '10px' }}></div>
      <TextField type="text"
        label={<FM id={'Last Name'} />}
        name="lastName"
        value={lastName}
        onChange={handleChange}
        disabled={!enableEdit}
        errorText={errors?.lastName} placeholder={""} />

      <div style={{ marginTop: '10px' }}></div>
      <TextField type="text"
        label={<FM id={'First Name'} />}
        name="firstName"
        value={firstName}
        onChange={handleChange}
        disabled={!enableEdit}
        errorText={errors?.firstName} placeholder={""} />

      <div style={{ marginTop: '10px' }}></div>
      <TextField type="date"
        label={<FM id={'Date Of Birth'} />}
        name="dob"
        value={dob}
        onChange={handleChange}
        disabled={!enableEdit}
        errorText={errors?.dob} placeholder={""} />

      <div style={{ marginTop: '10px' }}></div>
      <RadioGroupField
        label={<FM id={'Gender'} />}
        disabled={!enableEdit}
        onChange={handleChange} name={'gender'} value={gender} errorText={errors?.gender}
        options={[{ label: <FM id={'Male'} />, value: 'male' }, { label: <FM id={'Female'} />, value: 'female' }]} />

      <div style={{ marginTop: '10px' }}></div>
      <TextField type="text"
        label={<FM id={'Phone Number'} />}
        name="phoneNumber"
        value={phoneNumber}
        onChange={handleChange}
        disabled={!enableEdit}
        errorText={errors?.phoneNumber} placeholder={""} />

      <div style={{ marginTop: '10px' }}></div>
      <TextField type="text"
        label={<FM id={'Employee ID'} />}
        name="employeeId"
        value={employeeId}
        disabled />

      <div style={{ marginTop: '20px' }}></div>
      <div>
        <button onClick={enableEdit ? handleSubmit : handleEdit} className="btn btn-primary custom-btn" >{enableEdit ? <FM id={'Save'} /> : <FM id={'Edit'} />}</button>
      </div>
    </div>


  </>
  );
};

export default injectIntl(EditProfile);
