import React from 'react';
import "./style.css";
import userDefaultImg from "../../images/user-default-img.jpeg";
import { API_URL } from '../../App.config';

const UserImageSelecter = props => {
    const { name, onChange, disabled, imageUrl } = props;
    let finalImageUrl = userDefaultImg;
    if (imageUrl) {
        if (imageUrl.startsWith('/Images')) {
            finalImageUrl = API_URL + imageUrl + "?time=" + new Date().getTime();
        } else {
            finalImageUrl = 'data:image/png;base64,' + imageUrl
        }
    }
    return (
        <div className={"user-image-selector-root"}>
            <img src={finalImageUrl} className={'user-image-selector-img'} onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = userDefaultImg;
            }} />
            {!disabled && <input type="file" onChange={onChange} name={name} disabled={disabled} className={'user-image-selector-img user-image-selector-file-input'} />}
        </div>
    )
}

export default UserImageSelecter;  