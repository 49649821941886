import React, { Component } from "react";
import * as authActions from '../../redux/actions/authActions';
import "../../css/style.css";
import history from "../../history";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ForgotPassword from "../../components/ForgotPassword";

class ForgotPasswordScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      employeeId: "",
      errors: {
        email: "",
        employeeId: "",
      },
      isSubmitted: null,
    };
  }


  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    this.setState({ [name]: value }, () => this.isValid());
  };

  handleSubmit = () => {
    this.setState({ isSubmitted: true }, () => {
      if (this.isValid()) {
        const { email, employeeId } = this.state;
        this.props.authActions.doForgotPassword({
          email,
          employeeId,
        });
      }
    });
  };

  handleLogin = (event) => {
    event.preventDefault();
    history.push("/login")
  };


  isValid = () => {
    const { email, employeeId, isSubmitted } = this.state;
    const errors = {};
    let valid = true;

    if (!isSubmitted) {
      return valid;
    }

    if (!email) {
      errors.email = "Require";
    }

    if (!employeeId) {
      errors.employeeId = "Require";
    }
    this.setState({ errors, errors });
    Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
    return valid;
  };


  render() {
    const { handleChange, handleSubmit, handleLogin } = this;
    const props = {
      ...this.state,
      handleChange,
      handleSubmit,
      handleLogin
    };
    return <ForgotPassword {...props} />;

  }
}

const mapStateToProps = (state) => {
  return {

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authActions: bindActionCreators(authActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordScreen);
