// Will be using AXIOS library for making calls to API from react...
// Documentation for:: https://www.npmjs.com/package/axios

import { getAppInsights } from "../AppInsights";
import history from "../history";

// var unAuthErrorCount = 0; //patch for U1MC-804
const axios = require("axios");
// const ApiService = require('../services/api-service');
/**
 * [request description]
 * @param  {[string]} url       URL of the API which needs to be consumed by client
 * @param  {[string]} method    Method type of the API call currently GET,POST,PUT,DELETE is supported in order suport to more methods add method name to the array -> allowedMethodTypes
 * @param  {[JSON]} payload     Payload to be provided to server for sending data
 * @param  {[string]} headers   Request Headers required by the server side to process the API call
 * @return {[JSON]}             Response provided by the server side code
 */
export function request(url, method, headers, payload, trackEvent) {
  // var str = `Hello, ${name}!`;
  // //console.log("URL=> " + url + " \nMETHOD=> " + method + " \nHEADERS=> " + JSON.stringify(headers) + " \nPAYLOAD=> " + JSON.stringify(payload));
  // //console.log(`URL=> ${url} \n METHOD=>${method} \nHEADERS=> ${JSON.stringify(headers)} \nPAYLOAD=> ${JSON.stringify(payload)}`);
  return new Promise((resolve, reject) => {
    // Check for allowed method types for making a REST API call if not valid then throw an error...
    const allowedMethodTypes = ["get", "post", "put", "delete", "patch"];
    if (allowedMethodTypes.indexOf(method.toLowerCase()) < 0) {
      throw new Error(
        `Invalid method type please provide one of these methods... \n ${allowedMethodTypes}`
      );
    } else {
      if (trackEvent && getAppInsights()) {
        getAppInsights().trackEvent({
          name: "API_CALL > REQUEST : " + url,
          properties: {
            data: JSON.stringify({
              method,
              url,
              data: payload,
              headers: { ...headers, "Accept-Language": "ja-JP" },
            }),
          },
        });
      }

      axios({
        method,
        url,
        data: payload,
        headers: { ...headers, "Accept-Language": "ja-JP" },
      })
        .then((response) => {
          if (trackEvent && getAppInsights()) {
            getAppInsights().trackEvent({
              name: "API_CALL > RESPONSE: " + url,
              properties: {
                data: JSON.stringify({
                  method,
                  url,
                  data: payload,
                  headers: { ...headers, "Accept-Language": "ja-JP" },
                  response,
                }),
              },
            });
          }

          resolve(response);
        })
        .catch((error) => {
          if (error?.response) {
            const errorResponse = error?.response;
            if ((errorResponse.status = 401)) {
              history.push("/login");
            }
          }
          reject(error);
        });
    }
  });
}

// module.exports = {
//   request
// };
