import history from "../../history";
import backImg from "../../images/back.svg";
import React from "react";
import { FormattedMessage } from "react-intl";
const FM = FormattedMessage;
const BackButton = (props) => {
  return (
    <img
      src={backImg}
      alt={<FM id="fmp.text.back"></FM>}
      height={30}
      style={{cursor: 'pointer'}}
      onClick={() => {
        if (props.onClick) {
          props.onClick();
        } else {
          history.goBack();
        }
      }}
    />
  );
};

export default BackButton;
