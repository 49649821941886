import React, { Component } from "react";
import MyCardTemplete from "../../components/MyCard";
import "../../css/style.css";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as myCardActions from "../../redux/actions/myCardActions";
import * as appHeaderActions from "../../redux/actions/appHeaderActions";
import {
  API_URL,
  MY_CARD_TAB_INFO,
  MY_CARD_TYPE,
  REDEEMED_CARD_TYPE,
} from "../../App.config";
import history from "../../history";
import QRImage from "../../images/qr-code.png";
import Moment from "react-moment";
import { FormattedMessage } from "react-intl";
import BackButton from "../BackButton";

const FM = FormattedMessage;

class CashoutOrders extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  componentDidMount() { this.props.appHeaderActions.setData({ title: "Cash Out Completed", isBack: true }) }

  goToMyCardDetails = (orderId, orderDetails) => {
    history.push({
      pathname: "/my-card-details",
      state: {
        orderId: orderId,
        isActiveCardShown: this.state.isActiveCardShown,
      },
    });
  };

  render() {
    return (
      <>
        <div class="cashout-orders">
          {/* <div class="Flex-col heading-col">
            <BackButton></BackButton>
            <h1>
              <FM id="Cash Out Completed"></FM>
            </h1>
            <img src=""></img>
          </div> */}
          <div class="Card-Detail-Grid">
            <div class="Card-Detail-Card">
              <div class="Card-Detail-Item"><FM id="Ref"></FM></div>
              <div class="Card-Detail-Value">
                {this.props.cashoutDetails.qrRefID}
              </div>
            </div>
            <div class="Card-Detail-Card">
              <div class="Card-Detail-Item"><FM id="Cards1"></FM></div>
              <div class="Card-Detail-Value">
                {this.props.cashoutDetails.totalCards}
              </div>
            </div>
            <div class="Card-Detail-Card">
              <div class="Card-Detail-Item"><FM id="Value"></FM></div>
              <div class="Card-Detail-Value">
                {this.props.cashoutDetails.value}
              </div>
            </div>
            <div class="Card-Detail-Card">
              <div class="Card-Detail-Item"><FM id="Total"></FM></div>
              <div class="Card-Detail-Value Card-Detail-Value-Total">
                ¥ {this.props.cashoutDetails.totalPointsAmount}
              </div>
            </div>
          </div>
          <div class="Card-panel">
            <div class="Cashout-list">
              <div class="My-card Contact-listing">
                <ul>
                  {this.props &&
                    Array.isArray(this.props.cashoutDetails.cards) &&
                    this.props.cashoutDetails.cards.length > 0 ? (
                    this.props.cashoutDetails.cards.map((cardDetail) => {
                      return (
                        <li>
                          <div
                            class="Flex-col flex-start"
                            onClick={() => {
                              this.goToMyCardDetails(
                                cardDetail.orderID,
                                cardDetail
                              );
                            }}
                          >
                            <div>
                              <div class="Card-box pink-box">
                                <div>
                                  <img
                                    height="50"
                                    width="88"
                                    src={API_URL + cardDetail.cardImageURL}
                                  />
                                </div>
                              </div>
                              <div class="contact-info contact-info-flex">
                                <Moment format="YYYY-MM-DD - HH:mm">
                                  {cardDetail.orderDate}
                                </Moment>
                              </div>
                            </div>
                            <div>
                              <div class="text-right">
                                <span>
                                  <FM id="Value"></FM>
                                </span>
                                <span class="card-price">
                                  <span>¥</span>
                                  {100}
                                </span>
                              </div>
                            </div>
                          </div>
                        </li>
                      );
                    })
                  ) : (
                    <div class="No-Card">
                      <FM id="No Card Found"></FM>
                    </div>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cashoutDetails: state.myCardReducer.cashoutDetails.cashoutOrders,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    mycardActions: bindActionCreators(myCardActions, dispatch),
    appHeaderActions: bindActionCreators(appHeaderActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CashoutOrders);
