import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import BackButton from '../BackButton';
import UserMenu from '../UserMenu';
import walletImg from "../../images/wallet.svg";
import { API_URL } from '../../App.config';
import * as authActions from '../../redux/actions/authActions';
import { bindActionCreators } from 'redux';

const FM = FormattedMessage;

class AppHeader extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {

        }
    }

    componentDidMount() {
        this.props.authActions.getUserProfile();
    }

    render() {
        const { toggleSideMenu, appHeaderData, user } = this.props;
        const { userProfileData } = user || {};
        const { profile } = userProfileData || {};
        const profileImageUrl = profile?.profileImage;
        let finalProfileImageUrl = "";
        if (profileImageUrl) {
            finalProfileImageUrl = API_URL + profileImageUrl + "?time=" + new Date().getTime();
        }
        return (
            // <div class="Flex-col heading-col app-header">
            //     <BackButton></BackButton>
            //     <h1>
            //         {title}
            //     </h1>
            //     <UserMenu />
            // </div>

            // <div class="Flex-col heading-col app-header">
            //     <div class="logo">
            //         <span><FM id="TG"></FM></span>
            //         <img src={walletImg} alt="" />
            //     </div>
            //     <h1>
            //         {title}
            //     </h1>
            //     <UserMenu />
            // </div>

            <header>

                <div class="container-fluid padding-0">
                    <div class="logo">
                        <span><FM id="Rewards App"></FM></span>
                        <img src={walletImg} alt="" />
                    </div>
                    <div className={'Flex-col heading-col'}>
                        <div class="Menu-nv">
                            {appHeaderData?.isBack && <BackButton onClick={appHeaderData?.backClick ? appHeaderData?.backClick : null}></BackButton>}
                            <div class="hamburger hamburger--squeeze js-hamburger">
                                <div class="hamburger-box" onClick={toggleSideMenu}>
                                    <div class="hamburger-inner"></div>
                                </div>
                            </div>
                        </div>

                        <h1>
                            {appHeaderData?.title && <FM id={appHeaderData?.title}></FM>}
                        </h1>
                        <UserMenu profileImageUrl={finalProfileImageUrl} />
                    </div>

                </div>
            </header >
        )
    }

}

const mapStateToProps = (state) => {
    return {
        appHeaderData: state.appHeaderData,
        user: state.user,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        authActions: bindActionCreators(authActions, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AppHeader));