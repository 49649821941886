
 import React from 'react';
 import {SHOW_ALERT, HIDE_ALERT} from '../actionTypes';
  import {FormattedMessage} from 'react-intl';
  const FM = FormattedMessage;
  
  export function showAlert(message) {
      return {
          type: SHOW_ALERT,
          data: message
      }
  }
  export function hideAlert() {
      return {
        type: HIDE_ALERT
      }
  }

  export function showDefaultErrorWithCode(errorCode) {
    return showAlert(
      <>
        <FM id="app.text.error"></FM> {errorCode} <br></br> 
        <FM id="app.error.default"></FM> <br></br>
        <FM id="app.error.default1"></FM>
      </>
      )
  }
  