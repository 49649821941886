import React, { Component } from "react";
import MyCardTemplete from "../../components/MyCard";
import "../../css/style.css";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as myCardActions from "../../redux/actions/myCardActions";
import * as dmActions from "../../redux/actions/dmActions";
import * as confirmDialogActions from "../../redux/actions/confirmDialogActions";
import * as appHeaderActions from "../../redux/actions/appHeaderActions";
import * as qs from "qs";
import {
  MY_CARD_TAB_INFO,
  MY_CARD_TYPE,
  REDEEMED_CARD_TYPE,
} from "../../App.config";
import history from "../../history";
import { FormattedMessage } from "react-intl";

class MyCard extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isActiveCardShown: true,
      isRedeemedSectionActiveCardShown: true,
      currentPage: 1,
      pageSize: 10,
      tabInfo: MY_CARD_TAB_INFO.RECEIVED,
    };
  }

  componentDidMount() {
    let objQueryParams = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    this.setState(
      {
        tabInfo:
          objQueryParams.tab != undefined
            ? objQueryParams.tab
            : this.state.tabInfo,
      },
      () => {
        this.onChangeTab(this.state.tabInfo);
      }
    );
    this.props.appHeaderActions.setData({ title: "My Cards", isBack: false });
  }

  updateUrl = () => {
    const onlyPath = history.location.pathname.substr(
      0,
      history.location.pathname.lastIndexOf("/")
    );
    const { tabInfo } = this.state;
    const newUrl = onlyPath + "?tab=" + tabInfo;
    history.replace(newUrl);
  };

  onChangeTab = (selectedTab) => {
    this.setState(
      {
        tabInfo: selectedTab,
        currentPage: 1,
        pageSize: 10,
      },
      () => {
        const { tabInfo } = this.state;
        if (MY_CARD_TAB_INFO.SENT == tabInfo)
          this.getMyCardsDetails(MY_CARD_TYPE.ACTIVE);
        else if (MY_CARD_TAB_INFO.RECEIVED == tabInfo) {
          this.getReceivedCardWithStatus();
        } else this.getClaimCardOrders(REDEEMED_CARD_TYPE.ACTIVE);

        this.updateUrl();
      }
    );
  };

  showQRDetaisOnReceiedDetails = (res, message) => {
    if (res) {
      history.push("qr-details");
    } else {
      if (message) {
        this.props.confirmDialogActions.show({
          header: message,
          buttons: [
            {
              label: "OK",
            },
          ],
        });
      }
    }
  };

  afterInitiateClaims = (res, message) => {
    if (res) {
      this.onChangeTab(MY_CARD_TAB_INFO.REDEEMED);
    } else {
      if (message) {
        this.props.confirmDialogActions.show({
          header: message,
          buttons: [
            {
              label: "OK",
            },
          ],
        });
      }
    }
  };

  showCashoutDetaisOnReceiedDetails = (res) => {
    if (res) {
      history.push("cashout-orders");
    } else {
      //show error
    }
  };

  goToMyCardDetails = (orderId, orderDetails) => {
    if (MY_CARD_TAB_INFO.REDEEMED == this.state.tabInfo) {
      const { claimApprocedTransId, qrRefID } = orderDetails;
      this.props.mycardActions.getClaimCardOrderCards({ claimApprovedTransId: claimApprocedTransId, refId: qrRefID }, this.state.isRedeemedSectionActiveCardShown);
      history.push(`card-cards?tab=claim${this.state.isRedeemedSectionActiveCardShown ? 'Initiate' : 'Approved'}`);
      // if (this.state.isRedeemedSectionActiveCardShown) {
      //   // this.props.mycardActions.getQrDetails(
      //   //   orderDetails.qrRefID,
      //   //   this.showQRDetaisOnReceiedDetails
      //   // );

      // } else {
      //   let paginationPayload = {
      //     pageNo: this.state.currentPage,
      //     pageSize: this.state.pageSize,
      //     qrCodeId: orderDetails.qrRefID,
      //   };
      //   this.props.mycardActions.getCashoutOrders(
      //     paginationPayload,
      //     this.showCashoutDetaisOnReceiedDetails
      //   );
      // }
    } else {
      history.push({
        pathname: "/my-card-details",
        state: {
          orderId: orderId,
          isActiveCardShown: this.state.isActiveCardShown,
          isSent: MY_CARD_TAB_INFO.SENT == this.state.tabInfo,
        },
      });
    }
  };

  getMyCardsDetails = (type) => {
    this.setState(
      {
        isActiveCardShown: type,
      },
      () => {
        let paginationPayload = {
          pageNo: this.state.currentPage,
          pageSize: this.state.pageSize,
        };
        this.props.mycardActions.getMyCardsDetails(paginationPayload);
      }
    );
  };

  getReceivedCardWithStatus = () => {
    this.props.mycardActions.getReceivedStatus();

    let paginationPayload = {
      pageNo: this.state.currentPage,
      pageSize: this.state.pageSize,
    };
    this.props.mycardActions.getReceivedOrders(paginationPayload);
  };

  getRedeemCardOrder = (type) => {
    this.setState(
      {
        isRedeemedSectionActiveCardShown: type,
      },
      () => {
        let paginationPayload = {
          pageNo: this.state.currentPage,
          pageSize: this.state.pageSize,
          qrCodeId: 0,
        };
        this.props.mycardActions.getMyCardRedeemOrders(paginationPayload);
      }
    );
  };

  getClaimCardOrders = (type) => {
    this.setState(
      {
        isRedeemedSectionActiveCardShown: type,
      },
      () => {
        let paginationPayload = {
          pageNo: this.state.currentPage,
          pageSize: this.state.pageSize,
          qrCodeId: 0,
        };
        this.props.mycardActions.getClaimCardOrders(paginationPayload);
      }
    );
  };

  // onChange = (props) => {
  //   console.log("cal")
  //   this.setState({ [props.target.name]: props.target.value }, () => {
  //     if (props.name == "currentPage") {
  //       if (MY_CARD_TAB_INFO.SENT == this.state.tabInfo)
  //         this.getMyCardsDetails(
  //           this.state.isActiveCardShown
  //             ? MY_CARD_TYPE.ACTIVE
  //             : MY_CARD_TYPE.ARCHIVE
  //         );
  //       else if (MY_CARD_TAB_INFO.RECEIVED == this.state.tabInfo) {
  //         this.getReceivedCardWithStatus();
  //       } else
  //         // this.getRedeemCardOrder(
  //         //   this.state.isRedeemedSectionActiveCardShown
  //         //     ? REDEEMED_CARD_TYPE.ACTIVE
  //         //     : REDEEMED_CARD_TYPE.CASH_OUT_COMPLETED
  //         // );
  //         this.getClaimCardOrders(
  //           this.state.isRedeemedSectionActiveCardShown
  //             ? REDEEMED_CARD_TYPE.ACTIVE
  //             : REDEEMED_CARD_TYPE.CASH_OUT_COMPLETED
  //         );
  //     }
  //   });
  // };

  // onChange = (props) => {
  //   console.log("cal")
  //   this.setState({ [props.target.name]: props.target.value }, () => {
  //     if (MY_CARD_TAB_INFO.SENT == this.state.tabInfo)
  //       this.getMyCardsDetails(
  //         this.state.isActiveCardShown
  //           ? MY_CARD_TYPE.ACTIVE
  //           : MY_CARD_TYPE.ARCHIVE
  //       );
  //     else if (MY_CARD_TAB_INFO.RECEIVED == this.state.tabInfo) {
  //       this.getReceivedCardWithStatus();
  //     } else
  //       // this.getRedeemCardOrder(
  //       //   this.state.isRedeemedSectionActiveCardShown
  //       //     ? REDEEMED_CARD_TYPE.ACTIVE
  //       //     : REDEEMED_CARD_TYPE.CASH_OUT_COMPLETED
  //       // );
  //       this.getClaimCardOrders(
  //         this.state.isRedeemedSectionActiveCardShown
  //           ? REDEEMED_CARD_TYPE.ACTIVE
  //           : REDEEMED_CARD_TYPE.CASH_OUT_COMPLETED
  //       );
  //   });
  // };

  promptConfirmToGenerateQR = () => {
    // let text = "Are you sure to generate QR Code?";
    this.props.confirmDialogActions.show({
      header: "QRコードを生成してもよろしいですか？",
      buttons: [
        {
          label: "いいえ",
        },
        {
          label: "はい",
          onClick: () => {
            this.props.mycardActions.generateQR(
              this.showQRDetaisOnReceiedDetails
            );
          },
        },
      ],
    });
  };

  promptConfirmToInitiateClaims = () => {
    // let text = "Are you sure want to initiate claims?";
    this.props.confirmDialogActions.show({
      header: "本当に申請を開始してもよろしいですか?",
      buttons: [
        {
          label: "いいえ",
        },
        {
          label: "はい",
          onClick: () => {
            this.props.mycardActions.initiateClaims(this.afterInitiateClaims);
          },
        },
      ],
    });
  };

  doConvertToDM = () => {
    const { receivedStatus } = this.props;
    // let text = "Are you sure to convert to DM?";
    this.props.confirmDialogActions.show({
      header: "地域マネーに交換しても良いですか？",
      buttons: [
        {
          label: "いいえ",
        },
        {
          label: "はい",
          onClick: () => {
            // stop from front end if PR = 0 and prevent to call API
            // if (receivedStatus?.PR != undefined && parseFloat(receivedStatus.PR) <= 0) {
            //   this.props.confirmDialogActions.show({
            //     header: "message",
            //     buttons: [
            //       {
            //         label: "わかった",
            //       },
            //     ],
            //   });
            // } else {
            this.props.dmActions.doConvertToDM((result, message) => {
              if (result) {
                // history.push("dm");
                this.onChangeTab(this.state.tabInfo);
                this.props.confirmDialogActions.show({
                  header: "地域マネーへの変換が正常に行われました",
                  buttons: [
                    {
                      label: "確認",
                    },
                  ],
                });
              } else {
                if (message) {
                  this.props.confirmDialogActions.show({
                    header: message,
                    buttons: [
                      {
                        label: "戻る",
                      },
                    ],
                  });
                }
              }
            });
            // }
          },
        },
      ],
    });
  };

  render() {
    return (
      <>
        <MyCardTemplete
          tabInfo={this.state.tabInfo}
          onChangeTab={this.onChangeTab}
          myCardDetails={
            this.state.isActiveCardShown
              ? this.props.myCardDetails.sentOrders
              : this.props.myCardDetails.senderArchivedOrders
          }
          redeemCardDetails={
            this.state.isRedeemedSectionActiveCardShown
              ? this.props?.myCardRedeemOrderData?.redeemedOrders
              : this.props?.myCardRedeemOrderData?.cashoutOrders
          }
          getMyCardsDetails={this.getMyCardsDetails}
          getRedeemCardOrder={this.getRedeemCardOrder}
          getClaimCardOrders={this.getClaimCardOrders}
          isActiveCardShown={this.state.isActiveCardShown}
          isRedeemedSectionActiveCardShown={
            this.state.isRedeemedSectionActiveCardShown
          }
          totalRows={
            this.state.isActiveCardShown
              ? this.props.myCardDetails.sentTotalCount
              : this.props.myCardDetails.archivedTotalCount
          }
          redeemTotalRows={
            this.state.isRedeemedSectionActiveCardShown
              ? this.props?.myCardRedeemOrderData?.redeemTotalCount
              : this.props?.myCardRedeemOrderData?.cashoutTotalCount
          }
          currentPage={this.state.currentPage}
          pageSize={this.state.pageSize}
          // onChange={(props) => {
          //   this.onChange(props);
          // }}
          goToMyCardDetails={(orderId, cardDetails) => {
            this.goToMyCardDetails(orderId, cardDetails);
          }}
          receivedStatus={this.props.receivedStatus}
          receivedCardDetails={this.props.receivedCardDetails}
          promptConfirmToGenerateQR={this.promptConfirmToGenerateQR}
          promptConfirmToInitiateClaims={this.promptConfirmToInitiateClaims}
          doConvertToDM={this.doConvertToDM}
        ></MyCardTemplete>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    myCardDetails: state.myCardReducer.myCardData,
    myCardRedeemOrderData: state.myCardReducer.myCardRedeemOrderData,
    receivedStatus: state.myCardReducer.receivedStatus,
    receivedCardDetails: state.myCardReducer.receivedCardData.receivedOrders,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    mycardActions: bindActionCreators(myCardActions, dispatch),
    dmActions: bindActionCreators(dmActions, dispatch),
    confirmDialogActions: bindActionCreators(confirmDialogActions, dispatch),
    appHeaderActions: bindActionCreators(appHeaderActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyCard);
