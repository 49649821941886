import { combineReducers } from "redux";
import appConfigReducer from "./reducers/appConfigReducer";
import menuItemsReducer from "./reducers/menuItemsReducer";
import loaderReducer from "./reducers/loaderReducer";
import alertPopupReducer from "./reducers/alertPopupReducer";
import dashboardReducer from "./reducers/dashboardReducer";
import transactionReducer from "./reducers/transactionReducer";
import userReducer from "./reducers/userReducer";
import myCardReducer from './reducers/myCardReducer';
import sendCardReducer from './reducers/sendCardReducer';
import dmReducer from "./reducers/dmReducer";
import confirmDialogReducer from "./reducers/confirmDialogReducer";
import appHeaderReducer from "./reducers/appHeaderReducer";

const rootReducer = combineReducers({
  config: appConfigReducer,
  menuItems: menuItemsReducer,
  loader: loaderReducer,
  alertPopup: alertPopupReducer,
  dashboard: dashboardReducer,
  transaction: transactionReducer,
  user: userReducer,
  myCardReducer: myCardReducer,
  sendCardData: sendCardReducer,
  dmData: dmReducer,
  confirmDialog: confirmDialogReducer,
  appHeaderData: appHeaderReducer,
});

export default (state, action) => {  
    return rootReducer(state, action);
};
