import React, { useState } from 'react'
import UserImg from "../../images/user-default-img.jpeg";
import * as apiService from "../../services/apiService";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const UserIcon = React.memo((props) => {
    const { vcaid } = props;
    const [Image, setImage] = useState('');

    apiService.getProfileImage(vcaid).then(
        (response) => {
          response = response.data;
          if (response) {
            setImage(response);
          } else {
            setImage(null);
          }
        },
        (error) => {
            setImage(null);
        }
    );

    return (
        <LazyLoadImage
            src={Image ? `data:image/png;base64, ${Image}` : UserImg}
            style={{ width: '100%' }}
        />
    );
});

export default UserIcon;