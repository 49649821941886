import React, { Component } from 'react';
import {FormattedMessage} from 'react-intl';
import ring from '../../images/rings.svg';
const FM = FormattedMessage;

class Search extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            value: this.props.searchString ?  this.props.searchString : "",
            isResultsOpen: undefined,
            results : this.props.results,
            isInitValueSet: false,
            isSelected: false,
            isSearchInProgress: false,
        }
    }

    onChange = (event) => {
        this.setState({ value: event.target.value,  isResultsOpen: event.target.value ? this.state.isResultsOpen : false}, ()=> {
            if(this.props.searchOnChange) {
                this.onSubmit(event, true);
            }
        });
    }

    onSubmit = (event, fromOnChange = false) => {
        event.preventDefault();
        // if(!this.state.value) { return }
        this.setState({isSelected : false, /*isSearchInProgress:true*/}, ()=> {
            this.props.doSearch({ searchString : this.state.value, fromEnter: !fromOnChange });
        });
    }

    onSelect = (item) => {
        this.setState({isSelected: true, /*isSearchInProgress: false,*/ isResultsOpen : false, value: this.props.getDisplayItem(item)}, ()=> {
            this.props.onSelect(item);
        })
    }

    static getDerivedStateFromProps(props, state) {
        let newState = {};
        if(props.initialValue && !state.isInitValueSet) {
            newState.value = props.initialValue;
            newState.isInitValueSet = true;
        }
        newState.results = props.results;
        if(state.isSelected) {
            newState.isResultsOpen = false;
        } else {
            newState.isResultsOpen = true;
        }
        if(JSON.stringify(props.results) != JSON.stringify(state.results)) {
            // newState.isSearchInProgress = false;
        }
        return newState;
    }

    render() {
        return (
            <div className="Search-col">
                <div className="Serach-fl">
                    <form onSubmit={this.onSubmit}>
                        <button className="search-button" type="submit"></button>
                        <input type="text" 
                            value={this.state.value} 
                            placeholder={this.props.placeholder} 
                            onChange={this.onChange} />
                        {this.state.isSearchInProgress && 
                        <img src={ring} className="img-responsive search-loader-img" />}
                    </form>
                </div>
                {this.state.isResultsOpen && this.state.results && this.state.results.length>0 && 
                <div class="dropdown open">
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    {
                        this.state.results.map((item)=> {
                            return(<a class="dropdown-item" onClick={()=>{this.onSelect(item)}}>{this.props.getDisplayItem(item)}</a>)
                        })
                    }
                </div> 
                </div>}
            </div>
        )
    }
}

export default Search;  