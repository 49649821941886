import React from "react";
import BackButton from "../BackButton";
import { FormattedMessage } from "react-intl";

import prImg from "../../images/pr.png";
import ltpImg from "../../images/ltp.png";
import Moment from "react-moment";
import { DM_LIST_TABS } from "../../containers/DigitalMoney";

const FM = FormattedMessage;

const DigitalMoneyPage = (props) => {
  const {
    receivedStatus,
    dmData,
    activeTab,
    onChangeTab,
    goToDetail,
    doLoadMore,
  } = props;
  const { dmrTxsData, sdmTxsData } = dmData;
  const { dmConsumed, dmReceived } = receivedStatus || {};

  let txs = [];
  let totalCount = 0;
  let ACTIVE_TAB_RECEIVED, ACTIVE_TAB_CONSUMED, NO_RECORD;
  if (activeTab == DM_LIST_TABS.RECEIVED) {
    txs = dmrTxsData.txs;
    ACTIVE_TAB_RECEIVED = true;
    NO_RECORD = dmrTxsData.gotResponse && (!txs || txs.length == 0);
  } else if (activeTab == DM_LIST_TABS.CONSUMED) {
    txs = sdmTxsData.txs;
    totalCount = sdmTxsData.totalCount;
    ACTIVE_TAB_CONSUMED = true;
    NO_RECORD = sdmTxsData.gotResponse && (!txs || txs.length == 0);
  }

  const isLoadMore = txs.length < totalCount;

  return (
    <>
      <div class="Card-panel dm-screen">
        {/* <div class="Flex-col heading-col">
          <BackButton></BackButton>
          <h1>
            <FM id="My Cards"></FM>
          </h1>
          <img src={""}></img>
        </div> */}
        <div class="Tab-Container">
          <div class="tab-content" id="myTabContent">
            <div
              class="tab-pane fade show active"
              id="home"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <div class="My-card Contact-listing">
                <div class="Received-Grid">
                  <div class="Received-Grid-Card">
                    <div class="Received-Card-points">
                      <img class="Received-Card-Icon" src={prImg}></img>
                      <span>{dmReceived || 0}</span>
                    </div>
                    <div>
                      <FM id="Digital Money Received"></FM>
                    </div>
                  </div>
                  <div class="Received-Grid-Card">
                    <div class="Received-Card-points">
                      <img class="Received-Card-Icon" src={ltpImg}></img>
                      <span>{dmConsumed || 0}</span>
                    </div>
                    <div>
                      <FM id="Consumed Accumulated Digital Money"></FM>
                    </div>
                  </div>
                </div>
                <div class="tab-content dm-content" id="myTabContent">
                  <div
                    class="tab-pane fade show active"
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <div class="My-card-btn">
                      <button
                        class={ACTIVE_TAB_RECEIVED ? "active" : ""}
                        onClick={() => onChangeTab(DM_LIST_TABS.RECEIVED)}
                      >
                        <FM id="RECEIVED"></FM>
                      </button>
                      <button
                        class={ACTIVE_TAB_CONSUMED ? "active" : ""}
                        onClick={() => onChangeTab(DM_LIST_TABS.CONSUMED)}
                      >
                        <FM id="CONSUMED"></FM>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="dm-received-money">
                  <span>
                    <FM id="Digital Money Received from other colleagues"></FM>
                  </span>
                </div>
                {NO_RECORD && (
                  <div class="dm-noCards">
                    <span>
                      <FM id="No cards available"></FM>
                    </span>
                  </div>
                )}
                {!NO_RECORD && (
                  <ul class="dm-contact-listing">
                    {txs.map((tx) => (
                      <li>
                        <div
                          class="Flex-col flex-start"
                          onClick={() => goToDetail(tx)}
                        >
                          <div>
                            <div class="Card-box pink-box">
                              <div>
                                <img height="50" width="88" src={tx.cardURL} />
                              </div>
                            </div>
                            <div class="contact-info">
                              <Moment format="YYYY-MM-DD - HH:mm">
                                {tx.transactionDateTime}
                              </Moment>
                              <span>
                                <FM id="Ref"></FM>: {tx.transactionDetailsId}
                              </span>
                            </div>
                          </div>
                          <div>
                            <div class="text-right">
                              <span class="card-price">
                                <span>¥</span>
                                {tx.amount}
                              </span>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
                {/* {ACTIVE_TAB_CONSUMED && isLoadMore && (
                  <div class="btn-footer">
                    <button
                      class="dm-load-more"
                      onClick={doLoadMore}
                      disabled={false}
                    >
                      <FM id="Load More"></FM>
                    </button>
                  </div>
                )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DigitalMoneyPage;
