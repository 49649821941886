import React, { Component } from "react";
// import image401 from "../../images/image401_1.png";
import { FormattedMessage } from "react-intl";
const FM = FormattedMessage;

class Unauthorized extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isSideMenuExpanded: false,
    };
  }

  toggleSideMenu = () => {
    this.setState({ isSideMenuExpanded: !this.state.isSideMenuExpanded });
  };

  render() {
    return (
      <section class={"container"}>
        <div className="error-block">
          {/* <div className="col-sm-10 col-md-8 col-lg-6 col-xl-5 m-auto"> */}
            <div className="text-center">
              <div>
                {/* <img src={image401} className="w-100" /> */}
                <h1>401</h1>
              </div>
              <div className="mt-2">
                <h6>
                  <FM id="Authentication was not allowed."></FM> <br />{" "}
                  {/* <hr/> */}
                  <FM id="Please log in again from the administrator screen."></FM>
                </h6>
              </div>
              <div className="mt-2">
                {/* <button
                  className="btn btn-primary"
                  onClick={() => window.close()}
                >
                  <FM id="fmp.text.back"></FM>
                </button> */}
              </div>
            </div>
          {/* </div> */}
        </div>
      </section>
    );
  }
}
export default Unauthorized;
