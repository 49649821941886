import { SET_ALL_CARDS } from '../actionTypes';

export default function sendCardReducer(state = {
  allCards: []
}, action) {
  switch (action.type) {
    case SET_ALL_CARDS:
      return Object.assign({}, state, {
        allCards: action.data
      });
    default:
      return state;
  }
}
