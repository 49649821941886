import React from 'react';
import "./style.css";

const TextField = props => {
    const { label, errorText, name, value, type = 'text', placeholder, onChange, disabled, autocomplete } = props;
    return (
        <div className='text-field-root'>
            {label && <label>{label}</label>}
            <input type={type}
                className='text-field-input'
                name={name}
                value={value}
                onChange={onChange}
                placeholder={placeholder} disabled={disabled} autocomplete={autocomplete} />
            {errorText && <div className='error-text'>{errorText}</div>}
        </div>
    )
}

export default TextField;  