import React from "react";
import {
  API_URL,
  MY_CARD_TAB_INFO,
  MY_CARD_TYPE,
  REDEEMED_CARD_TYPE,
} from "../../App.config";
import defaultGiftCardImg from "../../images/defailt-gift-card.png";
import prImg from "../../images/pr.png";
import vapImg from "../../images/vap.png";
import ltpImg from "../../images/ltp.png";
import capImg from "../../images/cap.png";
import HandImg from "../../images/hand.png";
import QRImg from "../../images/qr.png";
import { FormattedMessage } from "react-intl";
import Moment from "react-moment";
const FM = FormattedMessage;

const MyCardTemplete = (props) => {
  let {
    onChangeTab,
    tabInfo,
    redeemTotalRows,
    redeemCardDetails,
    doConvertToDM,
  } = props;

  const ltp = parseInt(
    parseInt(props?.receivedStatus?.LTP || 0) +
    parseInt(props?.receivedStatus?.dmConsumed || 0) +
    parseInt(props?.receivedStatus?.dmReceived || 0)
  );

  return (
    <>
      <div class="Card-panel">
        {/* <div class="Flex-col heading-col">
          <BackButton></BackButton>
          <h1>
            <FM id="My Cards"></FM>
          </h1>
          <UserMenu />
        </div> */}
        <div class="Tab-Container">
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
              <span
                onClick={() => {
                  onChangeTab(MY_CARD_TAB_INFO.SENT);
                }}
                class={
                  tabInfo == MY_CARD_TAB_INFO.SENT
                    ? "nav-link active"
                    : "nav-link"
                }
                id="home-tab"
                data-toggle="tab"
                href="#home"
                role="tab"
                aria-controls="home"
                aria-selected="true"
              >
                <FM id="text.label.Sent"></FM>
              </span>
            </li>
            <li class="nav-item">
              <span
                onClick={() => {
                  onChangeTab(MY_CARD_TAB_INFO.RECEIVED);
                }}
                class={
                  tabInfo == MY_CARD_TAB_INFO.RECEIVED
                    ? "nav-link active"
                    : "nav-link"
                }
                id="home-tab"
                data-toggle="tab"
                href="#home"
                role="tab"
                aria-controls="home"
                aria-selected="true"
              >
                <FM id="text.label.Received"></FM>
              </span>
            </li>
            <li class="nav-item">
              <span
                onClick={() => {
                  onChangeTab(MY_CARD_TAB_INFO.REDEEMED);
                }}
                class={
                  tabInfo == MY_CARD_TAB_INFO.REDEEMED
                    ? "nav-link active"
                    : "nav-link"
                }
                id="home-tab"
                data-toggle="tab"
                href="#home"
                role="tab"
                aria-controls="home"
                aria-selected="true"
              >
                <FM id="Redeemed"></FM>
              </span>
            </li>
          </ul>
          {tabInfo == MY_CARD_TAB_INFO.SENT ? (
            <>
              <div class="tab-content" id="myTabContent">
                <div
                  class="tab-pane fade show active"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <div class="My-card-btn">
                    <button
                      onClick={() => {
                        props.getMyCardsDetails(MY_CARD_TYPE.ACTIVE);
                      }}
                      class={props.isActiveCardShown ? "active" : ""}
                    >
                      <FM id="Active"></FM>
                    </button>
                    <button
                      onClick={() => {
                        props.getMyCardsDetails(MY_CARD_TYPE.ARCHIVE);
                      }}
                      class={props.isActiveCardShown ? "" : "active"}
                    >
                      <FM id="Archived"></FM>
                    </button>
                  </div>
                  <div class="My-card Contact-listing">
                    <ul>
                      {props &&
                        Array.isArray(props.myCardDetails) &&
                        props.myCardDetails.length > 0 ? (
                        props.myCardDetails.map((cardDetail) => {
                          return (
                            <li>
                              <div
                                class="Flex-col flex-start"
                                onClick={() => {
                                  props.goToMyCardDetails(
                                    cardDetail.orderID,
                                    cardDetail
                                  );
                                }}
                              >
                                <div>
                                  <div class="Card-box pink-box">
                                    <div>
                                      <img
                                        height="50"
                                        width="88"
                                        src={API_URL + cardDetail.cardImageURL}
                                      />
                                    </div>
                                  </div>
                                  <div class="contact-info">
                                    <h4>
                                      {cardDetail.receiverEmployeeLastName}{" "}
                                      {cardDetail.receiverEmployeeFirstName}
                                    </h4>
                                    <span>
                                      <FM id="text.label.EMPID"></FM>:{" "}
                                      {cardDetail.receiverEmployeeID}
                                    </span>
                                    {/* <span>25 April 2019 - 17:50</span> */}
                                    <Moment format="YYYY-MM-DD - HH:mm">
                                      {cardDetail.date}
                                    </Moment>
                                  </div>
                                </div>
                                <div>
                                  <div class="text-right">
                                    <span>
                                      <FM id="Value"></FM>
                                    </span>
                                    <span class="card-price">
                                      <span>¥</span>
                                      {cardDetail.cardAmount}
                                    </span>
                                    <span>
                                      <FM id="Ref"></FM>: {cardDetail.orderID}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </li>
                          );
                        })
                      ) : (
                        <div class="No-Card">
                          <FM id="No Card Found"></FM>
                        </div>
                      )}
                    </ul>
                  </div>
                  {/* <Pagination
                                            currentPage={props.currentPage}
                                            pageSize={props.pageSize}
                                            totalRows={props.totalRows}
                                            onClickPre={(pageNo) => {
                                                props.onChange({
                                                    target: {
                                                        name: "currentPage",
                                                        value: pageNo
                                                    }
                                                })
                                            }}
                                            onClickNext={(pageNo) => {
                                                props.onChange({
                                                    target: {
                                                        name: "currentPage",
                                                        value: pageNo
                                                    }
                                                })
                                            }}
                                            onClickPage={(pageNo) => {
                                                props.onChange({
                                                    target: {
                                                        name: "currentPage",
                                                        value: pageNo
                                                    }
                                                })
                                            }}
                                        ></Pagination> */}
                </div>
              </div>
            </>
          ) : tabInfo == MY_CARD_TAB_INFO.RECEIVED ? (
            <>
              <div class="tab-content" id="myTabContent">
                <div
                  class="tab-pane fade show active"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  {/* <div class="My-card-btn">
                                                <a onClick={() => { props.getMyCardsDetails(MY_CARD_TYPE.ACTIVE) }} class={props.isActiveCardShown ? "active" : ""}><FM id="Active"></FM></a>
                                                <a onClick={() => { props.getMyCardsDetails(MY_CARD_TYPE.ARCHIVE) }} class={props.isActiveCardShown ? "" : "active"}><FM id="Archived"></FM></a>
                                            </div> */}
                  <div class="My-card Contact-listing">
                    <div class="Received-Grid">
                      <div class="Received-Grid-Card">
                        <div class="Received-Card-points">
                          <img src={prImg} class="Received-Card-Icon"></img>
                          <span>{props?.receivedStatus?.PR || 0}</span>
                        </div>
                        <div>
                          <FM id="text.label.PointReceived"></FM>
                        </div>
                      </div>
                      <div class="Received-Grid-Card">
                        <div class="Received-Card-points">
                          <img src={vapImg} class="Received-Card-Icon"></img>
                          <span>{props?.receivedStatus?.VAP || 0}</span>
                        </div>
                        <div>
                          <FM id="text.label.ValidAccumulatedPoints"></FM>
                        </div>
                      </div>
                      <div class="Received-Grid-Card">
                        <div class="Received-Card-points">
                          <img src={capImg} class="Received-Card-Icon"></img>
                          <span>{props?.receivedStatus?.CAP || 0}</span>
                        </div>
                        <div>
                          <FM id="text.label.Consumedaccumulatedpoints"></FM>
                        </div>
                      </div>
                      <div class="Received-Grid-Card">
                        <div class="Received-Card-points">
                          <img src={ltpImg} class="Received-Card-Icon"></img>
                          <span>{ltp}</span>
                        </div>
                        <div>
                          <FM id="text.label.LifeTimePoints"></FM>
                        </div>
                      </div>
                    </div>
                    <div class="Received-Card-Buttons">
                      {/* {props?.receivedStatus?.isDmConfigured && (
                        <button class="DM-Button" onClick={doConvertToDM}>
                          <img src={HandImg}></img>
                          <span>
                            <FM id="text.label.ConvertToDM"></FM>
                          </span>
                        </button>
                      )} */}
                      {/* {props?.receivedStatus?.isQRConfigured && (
                        <button
                          class="QR-Code"
                          onClick={() => {
                            props.promptConfirmToGenerateQR();
                          }}
                        >
                          <img src={QRImg} class=""></img>
                          <span>
                            <FM id="Generate QR Code"></FM>
                          </span>
                        </button>
                      )} */}
                      {props?.receivedStatus?.isQRConfigured && (
                        <button
                          style={{ "max-width": "250px" }}
                          class="QR-Code"
                          onClick={() => {
                            props.promptConfirmToInitiateClaims();
                          }}
                        >
                          {/* <img src={QRImg} class=""></img> */}
                          <span>
                            <FM id="Start to Claim"></FM>
                          </span>
                        </button>
                      )}
                    </div>

                    <div class="Colleague-Cards">
                      <FM id="Cards Received from other colleagues"></FM>
                    </div>
                    <ul>
                      {props &&
                        Array.isArray(props.receivedCardDetails) &&
                        props.receivedCardDetails.length > 0 ? (
                        props.receivedCardDetails.map((cardDetail) => {
                          return (
                            <li>
                              <div
                                class="Flex-col flex-start"
                                onClick={() => {
                                  props.goToMyCardDetails(
                                    cardDetail.orderID,
                                    cardDetail
                                  );
                                }}
                              >
                                <div>
                                  <div class="Card-box pink-box">
                                    <div>
                                      <img
                                        height="50"
                                        width="88"
                                        src={API_URL + cardDetail.cardImageURL}
                                      />
                                    </div>
                                  </div>
                                  <div class="contact-info contact-info-flex">
                                    {/* <h4>
                                      {cardDetail.receiverEmployeeFirstName}
                                      {cardDetail.receiverEmployeeLastName}
                                    </h4>
                                    <span>
                                      <FM id="text.label.EMPID"></FM>:{" "}
                                      {cardDetail.receiverEmployeeID}
                                    </span> */}
                                    {/* <span>25 April 2019 - 17:50</span> */}
                                    <Moment format="YYYY-MM-DD - HH:mm">
                                      {cardDetail.date}
                                    </Moment>
                                  </div>
                                </div>
                                <div>
                                  <div class="text-right"></div>
                                </div>
                              </div>
                            </li>
                          );
                        })
                      ) : (
                        <div class="No-Card">
                          <FM id="No Card Found"></FM>
                        </div>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div class="tab-content" id="myTabContent">
                <div
                  class="tab-pane fade show active"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <div class="My-card-btn">
                    <button
                      onClick={() => {
                        props.getClaimCardOrders(REDEEMED_CARD_TYPE.ACTIVE);
                      }}
                      class={
                        props.isRedeemedSectionActiveCardShown ? "active" : ""
                      }
                    >
                      <FM id="Claims Initiated"></FM>
                    </button>
                    <button
                      onClick={() => {
                        props.getClaimCardOrders(
                          REDEEMED_CARD_TYPE.CASH_OUT_COMPLETED
                        );
                      }}
                      class={
                        props.isRedeemedSectionActiveCardShown ? "" : "active"
                      }
                    >
                      <FM id="Claims Approved"></FM>
                    </button>
                  </div>
                  <div class="My-card Contact-listing">
                    <ul>
                      {props &&
                        Array.isArray(props.redeemCardDetails) &&
                        props.redeemCardDetails.length > 0 ? (
                        redeemCardDetails.map((cardDetail) => {
                          return (
                            <li>
                              <div
                                class="Flex-col flex-start"
                                onClick={() => {
                                  props.goToMyCardDetails(
                                    cardDetail.orderID,
                                    cardDetail
                                  );
                                }}
                              >
                                <div>
                                  <div class="Card-box pink-box">
                                    <div>
                                      <img
                                        height="50"
                                        width="88"
                                        src={cardDetail.imageURL ? (API_URL + cardDetail.imageURL) : defaultGiftCardImg}
                                      />
                                    </div>
                                  </div>
                                  <div class="contact-info">
                                    {/* <h4>{cardDetail.receiverEmployeeFirstName}{cardDetail.receiverEmployeeLastName}</h4> */}

                                    {/* <span>25 April 2019 - 17:50</span> */}
                                    <Moment format="YYYY-MM-DD - HH:mm">
                                      {cardDetail.transactionDate}
                                    </Moment>
                                    <span>
                                      <FM id="Ref"></FM>: {cardDetail.claimApprocedTransId || cardDetail.qrRefID}
                                    </span>
                                  </div>
                                </div>
                                <div>
                                  <div class="text-right">
                                    <span>
                                      <FM id="Cards"></FM>(
                                      {cardDetail.totalCards})
                                    </span>

                                    <span>
                                      <FM id="Value"></FM>:{" "}
                                      {cardDetail.cardValue}
                                    </span>
                                    <span class="card-price">
                                      <span>¥</span>
                                      {cardDetail.totalPointsAmount}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </li>
                          );
                        })
                      ) : (
                        <div class="No-Card">
                          <FM id="No Card Found"></FM>
                        </div>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default MyCardTemplete;
