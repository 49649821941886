import React from "react";
import cardSendSuccessImg from "../../images/cardsent_sucess.svg";
import BackButton from "../BackButton";
import UserImg from "../../images/user-default-img.jpeg";
import giftImg from "../../images/gift.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { FormattedMessage, injectIntl } from "react-intl";
import Search from "../Search";
import { filterEmployeeList } from "../../containers/SendCard";
import UserIcon from "../../containers/SendCard/UserIcon";
import UserMenu from "../UserMenu";
import { API_URL } from "../../App.config";
const FM = FormattedMessage;

const SendCardPage = (props) => {
  var settings = {
    dots: true,
    className: "center",
    centerMode: true,
    infinite: true,
    initialSlide: props.selectedCardIndex,
    centerPadding: "10px",
    slidesToShow: 3,
    slidetToScroll: 1,
    afterChange: props.afterChangeCard,
    // speed: 500,
    // responsive: [
    //   {
    //     breakpoint: 768,
    //     settings: {
    //       slidesToShow: 5,
    //       slidesToScroll: 5,
    //     }
    //   }]
  };
  const getFormattedMessage = (id = "-") => {
    return props.intl.formatMessage({ id: id });
  };

  const windowHeight = window.innerHeight;
  const emloyeeListHeight = windowHeight - 300;

  return (
    <>
      {true && (
        <div class="Content-Panel">
          <div class="Content-Container">
            {props.cardSendStep == 1 && (
              <>
                {/* <div class="Flex-col heading-col dark-header">
                  <BackButton onClick={() => { }}></BackButton>
                  <h1>
                    <FM id="Select"></FM>
                  </h1>
                  <div>
                    <img src={giftImg} onClick={props.goToMycards}></img>
                    <span>&nbsp; &nbsp;</span>
                    <UserMenu />
                  </div>
                </div> */}
                <div class="Card-panel">
                  <div class="Card Chart-card">
                    <div class="Card-list">
                      {/* <h2>
                        <FM id="Select Card To Send"></FM>
                      </h2> */}
                      <Slider {...settings}>
                        {/* <div class="tg-slide-card tg-slide-card-dummy">
                          <div class="Card-box-col">
                            <div className="Card-box">
                              <img src="" />
                            </div>

                            <div class="Card-radio-btn">
                              <input
                                type="radio"
                                id="test1"
                                name="radio-group"
                                checked="false"
                              />
                              <label for="test1"></label>
                            </div>
                          </div>
                        </div> */}
                        {props.allCards &&
                          props.allCards.map((card, cardIndex) => {
                            return (
                              <div class="tg-slide-card">
                                <div class="Card-box-col">
                                  <div className="Card-box">
                                    <img src={API_URL + card.imageUrl} />
                                  </div>

                                  <div class="Card-radio-btn">
                                    <input
                                      type="radio"
                                      id="test1"
                                      name="radio-group"
                                      checked={
                                        props.selectedCardIndex == cardIndex
                                          ? true
                                          : false
                                      }
                                    />
                                    <label for="test1"></label>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        {/* <div class="tg-slide-card tg-slide-card-dummy">
                          <div class="Card-box-col">
                            <div className="Card-box">
                              <img src="" />
                            </div>

                            <div class="Card-radio-btn">
                              <input
                                type="radio"
                                id="test1"
                                name="radio-group"
                                checked="false"
                              />
                              <label for="test1"></label>
                            </div>
                          </div>
                        </div> */}
                      </Slider>
                    </div>
                  </div>
                  <div class="btn-footer">
                    <button
                      class="btn btn-primary custom-btn"
                      onClick={() => props.chageCardSendStep(2)}
                      disabled={props.selectedCardIndex == undefined}
                    >
                      <FM id="Send"></FM>
                    </button>
                  </div>
                </div>
              </>
            )}

            {props.cardSendStep == 2 &&
              props.selectedCardIndex != undefined && (
                <div class="Card-panel">
                  <div class="">
                    {/* <div class="Flex-col heading-col">
                      <BackButton
                        onClick={() => props.chageCardSendStep(1)}
                      ></BackButton>
                      <h1>
                        <FM id="Contact"></FM>
                      </h1>
                      <UserMenu />
                    </div> */}
                    <div class="Contact-listing send-card-list">
                      <div
                        className="Search-Filter"
                        style={{ position: "relative" }}
                      >
                        <Search
                          initialValue={props.employeeSearchString}
                          placeholder={getFormattedMessage("label.Search")}
                          doSearch={({ searchString, fromEnter }) => {
                            props.doSearchEmployees(searchString, fromEnter);
                          }}
                          searchOnChange={true}
                        ></Search>
                      </div>
                      <ul style={{ height: emloyeeListHeight, overflow: "auto" }}>
                        {props.allEmployees &&
                          filterEmployeeList(
                            props.allEmployees,
                            props.employeeSearchString
                          ).map((employee, empIndex) => (
                            <li>
                              <div
                                class="Flex-col flex2"
                                onClick={() =>
                                  props.onChangeSelectedEmployee(employee)
                                }
                              >
                                <div className="flex2">
                                  <div class="contact-img">
                                    <UserIcon vcaid={employee?.user?.vwUserID} />
                                  </div>
                                  <div class="contact-info">
                                    <h4>
                                      {employee?.user?.lastName}{" "}
                                      {employee?.user?.firstName}
                                    </h4>
                                    <span>
                                      <FM id="text.label.EMPID"></FM>:{" "}
                                      {employee?.employeeID}
                                    </span>
                                  </div>
                                </div>
                                <div class="Card-radio-btn">
                                  <input
                                    type="radio"
                                    id="empSelRadio"
                                    name={`empSelRadio${empIndex}`}
                                    checked={
                                      props.selectedEmployee?.ein == employee?.ein
                                        ? true
                                        : false
                                    }
                                  />
                                  <label for={`empSelRadio${empIndex}`}>
                                    {" "}
                                  </label>
                                </div>
                              </div>
                            </li>
                          ))}
                      </ul>

                      {/*
                    <Pagination
                      currentPage={props.employeeListCurrentPage}
                      pageSize={props.employeeListPageSize}
                      totalRows={props.employeeListTotalRows}
                      onClickPre={(pageNo) =>
                        props.onChangeEmployeeListPageNumber(pageNo)
                      }
                      onClickNext={(pageNo) =>
                        props.onChangeEmployeeListPageNumber(pageNo)
                      }
                      onClickPage={(pageNo) =>
                        props.onChangeEmployeeListPageNumber(pageNo)
                      }
                    ></Pagination>
                    */}
                    </div>
                  </div>

                  <div style={{ marginTop: 20 }}></div>
                  <div class="btn-footer">
                    <button
                      class="btn btn-primary custom-btn"
                      onClick={() => props.chageCardSendStep(3)}
                      disabled={
                        props.selectedCardIndex == undefined ||
                        !props.selectedEmployee
                      }
                    >
                      <FM id="CONTINUE"></FM>
                    </button>
                  </div>
                </div>
              )}

            {props.cardSendStep == 3 &&
              props.selectedCardIndex != undefined &&
              props.selectedEmployee && (
                <div class="Card-panel">
                  <div class="Card Chart-card">
                    {/* <div class="Flex-col heading-col dark-header">
                      <BackButton
                        onClick={() => props.chageCardSendStep(2)}
                      ></BackButton>
                      <h1>
                        <FM id="Review"></FM>
                      </h1>
                      <UserMenu />
                    </div> */}
                    <div class="Card-send">
                      <div class="Card-send-detail">
                        <div class="text-center">
                          <h2>
                            <FM id="To"></FM>
                          </h2>
                          <div class="contact-img">
                            <UserIcon vcaid={props.selectedEmployee?.user?.vwUserID} />
                          </div>
                          <div class="contact-info">
                            <h4>
                              {
                                props.selectedEmployee?.user?.lastName
                              }{" "}
                              {
                                props.selectedEmployee?.user?.firstName
                              }
                            </h4>
                            <span>
                              <FM id="text.label.EMPID"></FM>:{" "}
                              {
                                props.selectedEmployee?.employeeID
                              }
                            </span>
                          </div>
                        </div>
                        <div class="Flex-col">
                          <div className="Card-box">
                            <img
                              src={
                                API_URL + props.allCards[props.selectedCardIndex]
                                  ?.imageUrl
                              }
                              height={60}
                            />
                          </div>
                          <div class="Card-info-d">
                            <div>
                              <FM id="Gift card value"></FM>
                            </div>
                            <span>
                              <span>¥</span>
                              {props.allCards[props.selectedCardIndex]?.amount}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="btn-footer">
                    <button
                      class="btn btn-primary custom-btn"
                      onClick={() => props.chageCardSendStep(4)}
                      disabled={
                        props.selectedCardIndex == undefined ||
                        !props.selectedEmployee
                      }
                    >
                      <FM id="CONTINUE"></FM>
                    </button>
                  </div>
                </div>
              )}

            {props.cardSendStep == 4 &&
              props.selectedCardIndex != undefined &&
              props.selectedEmployee && (
                <div class="Card-panel">
                  <div class="Card Chart-card">
                    {/* <div class="Flex-col heading-col">
                      <BackButton
                        onClick={() => props.chageCardSendStep(3)}
                      ></BackButton>
                      <h1>
                        <FM id="Card Sent"></FM>
                      </h1>
                      <img alt=""></img>
                    </div> */}

                    <div class="Card-sent-succesfully text-center">
                      <h2>
                        <FM id="HOORAY!"></FM>
                      </h2>
                      <img src={cardSendSuccessImg} alt="" />
                      <h3>
                        <FM id="Card sent successfully"></FM> <FM id="to"></FM>{" "}
                        {
                          props.selectedEmployee?.user?.lastName
                        }{" "}
                        {
                          props.selectedEmployee?.user?.firstName
                        }
                      </h3>
                      <div style={{ marginTop: 20 }}></div>
                      <div class="btn-footer">
                        <button
                          class="btn btn-primary custom-btn"
                          onClick={() => props.chageCardSendStep(1)}
                        >
                          <FM id="Done"></FM>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
      )}
    </>
  );
};

export default injectIntl(SendCardPage);
