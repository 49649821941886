const months = [ "January", "February", "March", "April", "May", "June", 
           "July", "August", "September", "October", "November", "December" ];
const monthsShort = [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", 
           "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];

export function getShortMonthNameFromNumber(number) {
    return monthsShort[number-1];
}
export function getMonthNameFromNumber(number) {
    return months[number-1];
}
export function amtToK(num) {
    return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num)
}

export function toAmountFormat(x) {
    if(x != undefined) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } 
    return "";
}

export function getOnlyDateStr(date) {
    date = new Date(date);
    return date.getFullYear() + '-' + ( date.getMonth() + 1 ) + '-' +date.getDate();
}

export function toQueryParams(obj) {
    let str = "";
    for (let key in obj) {
        if (str != "") {
            str += "&";
        } else {
            str += "?";
        }
        str += key + "=" + encodeURIComponent(obj[key]);
    }
    return str;
}