import React, { Component } from "react";
import "../../css/style.css";
import { connect } from "react-redux";
import SendDmPage from "../../components/DigitalMoneyPage/SendDmPage";
import * as dmActions from "../../redux/actions/dmActions";
import * as appHeaderActions from "../../redux/actions/appHeaderActions";
import { bindActionCreators } from "redux";
import history from "../../history";

export const SEND_DM_SEND_STEPS = {
  SCAN_QR: "SCAN_QR",
  PAYING: "PAYING",
  SUCCESS: "SUCCESS",
};
class SendDm extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      cardData: {},
      step: SEND_DM_SEND_STEPS.SCAN_QR,
      payDetails: {},
      payAmount: null,
      errors: {},
    };
  }

  componentDidMount() {
    if (this.props.location.state?.data) {
      const receivedCardData = this.props.location.state.data;
      this.setState({
        cardData: receivedCardData,
        payAmount: receivedCardData.amount,
      });
    } else {
      history.push("dm");
    }
    this.props.appHeaderActions.setData({ title: null, isBack: false })
  }

  onPay = () => {
    if (!this.isValid()) {
      return;
    }
    const { payDetails, payAmount } = this.state;
    const { shopId, shopName } = payDetails;
    this.props.dmActions.doPaySdm(
      { shopId, shopName, amount: payAmount },
      (success, response) => {
        if (success) {
          this.setState({
            step: SEND_DM_SEND_STEPS.SUCCESS,
          });
        }
      }
    );
  };

  onDone = () => {
    history.push("dm?tab=CONSUMED");
  };

  isValid = () => {
    const { payAmount, payDetails } = this.state;
    const errors = {};
    let valid = true;

    if (!payDetails || !payDetails.shopId || !payDetails.shopName) {
      errors.payDetails = "Require";
    }
    if (!payAmount) {
      errors.payAmount = "Require";
    }

    if (payAmount && isNaN(payAmount)) {
      errors.payAmount = "Invalid";
    }

    this.setState({ errors, errors });
    Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
    return valid;
  };

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    this.setState({ [name]: value }, () => this.isValid());
  };

  onResultQr = (result, error) => {
    if (!!result) {
      if (result?.text) {
        this.props.dmActions.doValidateShopQR(result?.text, (result) => {
          if (result?.data?.response) {
            const shopDetail = result?.data?.response;
            this.setState({
              payDetails: {
                ...shopDetail,
              },
              step: SEND_DM_SEND_STEPS.PAYING,
            });
          }
        });
      }
    }

    if (!!error) {
      // console.info(error);
    }
  };

  render() {
    const { onResultQr, onPay, handleChange, onDone, state } = this;
    const propsForward = {
      onResultQr,
      onPay,
      handleChange,
      onDone,
      ...state,
    };
    return (
      <>
        <SendDmPage {...propsForward}></SendDmPage>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    dmActions: bindActionCreators(dmActions, dispatch),
    appHeaderActions: bindActionCreators(appHeaderActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SendDm);
