import * as apiService from "../../services/apiService";
import { getActionCreators } from "../../services/reduxCodeGenerator";
import { ORDER_TYPE } from "../../App.config";
import {
  SET_ALL_MENU_ITEMS,
  SET_GOT_MENU_ITEMS_RESPONSE,
  SET_ORDER_DATA,
  CLEAR_ORDER_DATA,
  SET_ALL_MENU_ITEMS_1,
  SET_TOP_DATA,
  SET_TOP_DATA_GOT_RESPONSE,
  SET_TREND_CHART_DATA,
  SET_TREND_CHART_DATA_GOT_RESPONSE,
  SET_USERS_COUNTS_DATA,
  SET_USERS_COUNTS_DATA_GOT_RESPONSE,
  SET_TOP_EMPLOYEES_POINTS_DATA,
  SET_TOP_EMPLOYEES_POINTS_DATA_GOT_RESPONSE,
  SET_DEPARTMENT_WISE_POINTS_DATA,
  SET_DEPARTMENT_WISE_POINTS_DATA_GOT_RESPONSE,
  SET_EMPLOYEE_DETAILS_WITH_DEPARTMENT_WISE_DATA,
  SET_EMPLOYEE_DETAILS_WITH_DEPARTMENT_WISE_DATA_GOT_RESPONSE,
  SET_EMPLOYEE_WISE_DEPARTMENTS_ANALYSIS_CHART_DATA,
  SET_EMPLOYEE_WISE_DEPARTMENTS_ANALYSIS_CHART_DATA_GOT_RESPONSE,
  SET_DEPARTMENT_TO_DEPARTMENTS_DATA,
  SET_DEPARTMENT_TO_DEPARTMENTS_DATA_GOT_RESPONSE,
  SET_DEPARTMENT_TO_DEPARTMENTS_ANALYSIS_DATA,
  SET_DEPARTMENT_TO_DEPARTMENTS_ANALYSIS_DATA_GOT_RESPONSE,
  SET_EMPLOYEE_CONN_DATA,
  SET_EMPLOYEE_CONN_DATA_GOT_RESPONSE,
  SET_DEPT_CONN_DATA,
  SET_DEPT_CONN_DATA_GOT_RESPONSE,
  SET_EMPLOYEE_TREND_CHART_DATA,
  SET_EMPLOYEE_TREND_CHART_DATA_GOT_RESPONSE,
  SET_DEPT_TREND_CHART_DATA,
  SET_DEPT_TREND_CHART_DATA_GOT_RESPONSE,
  SET_EMPLOYEES_SEARCH_RESULT,
  SET_EMPLOYEES_SEARCH_RESULT_GOT_RESPONSE,
  SET_ALL_EMPLOYEES,
  SET_ALL_EMPLOYEES_TOTAL_ROWS,
  SET_ALL_EMPLOYEES_GOT_RESPONSE,
  SET_DEPARTMENTS,
  SET_DEPARTMENTS_GOT_RESPONSE,
  SET_ALL_EMPLOYEES_TO_DEPARTMENTS_DATA,
  SET_ALL_EMPLOYEES_TO_DEPARTMENTS_DATA_GOT_RESPONSE,
  SET_ALL_EMPLOYEES_TO_DEPARTMENTS_CHART_DATA,
  SET_ALL_EMPLOYEES_TO_DEPARTMENTS_CHART_DATA_GOT_RESPONSE,
  SET_ALL_EMPLOYEES_TREND_CHART_DATA,
  SET_ALL_EMPLOYEES_TREND_CHART_DATA_GOT_RESPONSE,
  SET_ALL_EMPLOYEES_CONN_DATA,
  SET_ALL_EMPLOYEES_CONN_DATA_GOT_RESPONSE,
  SET_EMPLOYEE_DIFF_TYPES_OF_POINTS,
  SET_EMPLOYEE_DIFF_TYPES_OF_POINTS_GOT_RESPONSE,
} from "../actionTypes";

import * as alertPopupActions from "../actions/alertPopupActions";
import * as loaderActions from "../actions/loaderActions";

const actionTypes = [
  SET_TOP_DATA,
  SET_TOP_DATA_GOT_RESPONSE,

  SET_TREND_CHART_DATA,
  SET_TREND_CHART_DATA_GOT_RESPONSE,

  SET_USERS_COUNTS_DATA,
  SET_USERS_COUNTS_DATA_GOT_RESPONSE,

  SET_TOP_EMPLOYEES_POINTS_DATA,
  SET_TOP_EMPLOYEES_POINTS_DATA_GOT_RESPONSE,

  SET_DEPARTMENT_WISE_POINTS_DATA,
  SET_DEPARTMENT_WISE_POINTS_DATA_GOT_RESPONSE,

  SET_EMPLOYEE_DETAILS_WITH_DEPARTMENT_WISE_DATA,
  SET_EMPLOYEE_DETAILS_WITH_DEPARTMENT_WISE_DATA_GOT_RESPONSE,

  SET_EMPLOYEE_WISE_DEPARTMENTS_ANALYSIS_CHART_DATA,
  SET_EMPLOYEE_WISE_DEPARTMENTS_ANALYSIS_CHART_DATA_GOT_RESPONSE,

  SET_DEPARTMENT_TO_DEPARTMENTS_DATA,
  SET_DEPARTMENT_TO_DEPARTMENTS_DATA_GOT_RESPONSE,

  SET_DEPARTMENT_TO_DEPARTMENTS_ANALYSIS_DATA,
  SET_DEPARTMENT_TO_DEPARTMENTS_ANALYSIS_DATA_GOT_RESPONSE,

  SET_EMPLOYEE_CONN_DATA,
  SET_EMPLOYEE_CONN_DATA_GOT_RESPONSE,

  SET_DEPT_CONN_DATA,
  SET_DEPT_CONN_DATA_GOT_RESPONSE,

  SET_EMPLOYEE_TREND_CHART_DATA,
  SET_EMPLOYEE_TREND_CHART_DATA_GOT_RESPONSE,

  SET_DEPT_TREND_CHART_DATA,
  SET_DEPT_TREND_CHART_DATA_GOT_RESPONSE,

  SET_EMPLOYEES_SEARCH_RESULT,
  SET_EMPLOYEES_SEARCH_RESULT_GOT_RESPONSE,

  SET_ALL_EMPLOYEES,
  SET_ALL_EMPLOYEES_TOTAL_ROWS,
  SET_ALL_EMPLOYEES_GOT_RESPONSE,

  SET_DEPARTMENTS,
  SET_DEPARTMENTS_GOT_RESPONSE,

  SET_ALL_EMPLOYEES_TO_DEPARTMENTS_DATA,
  SET_ALL_EMPLOYEES_TO_DEPARTMENTS_DATA_GOT_RESPONSE,

  SET_ALL_EMPLOYEES_TO_DEPARTMENTS_CHART_DATA,
  SET_ALL_EMPLOYEES_TO_DEPARTMENTS_CHART_DATA_GOT_RESPONSE,

  SET_ALL_EMPLOYEES_TREND_CHART_DATA,
  SET_ALL_EMPLOYEES_TREND_CHART_DATA_GOT_RESPONSE,

  SET_ALL_EMPLOYEES_CONN_DATA,
  SET_ALL_EMPLOYEES_CONN_DATA_GOT_RESPONSE,

  SET_EMPLOYEE_DIFF_TYPES_OF_POINTS,
  SET_EMPLOYEE_DIFF_TYPES_OF_POINTS_GOT_RESPONSE,
];
const actions = getActionCreators(actionTypes);
// //console.log(actions)

/*
export function setTopData(data) {
    return {
        type: SET_TOP_DATA,
        data: data
    }
}

export function setTopDataGotResponse(flag) {
    return {
        type: SET_TOP_DATA_GOT_RESPONSE,
        data: flag
    }
}

export function setTrendChartData(data) {
    return {
        type: SET_TREND_CHART_DATA,
        data: data
    }
}

export function setTrendChartDataGotResponse(flag) {
    return {
        type: SET_TREND_CHART_DATA_GOT_RESPONSE,
        data: flag
    }
}
*/

export function setAllMenuItems(items) {
  return {
    type: SET_ALL_MENU_ITEMS,
    data: items,
  };
}
export function setAllMenuItems1(data) {
  return {
    type: SET_ALL_MENU_ITEMS_1,
    data: data,
  };
}
export function setGotResponse(flag) {
  return {
    type: SET_GOT_MENU_ITEMS_RESPONSE,
    data: flag,
  };
}

export function setOrderData(data) {
  return {
    type: SET_ORDER_DATA,
    data: data,
  };
}

export function clearOrderData() {
  return {
    type: CLEAR_ORDER_DATA,
  };
}

export function getPaymentTenderByKid(kId) {
  return apiService.getPaymentTenderByKid(kId).then(
    (response) => {
      return response.data;
    },
    (error) => {
      return error;
    }
  );
}

export function getAndSetTopData(duration) {
  return (dispatch, store) => {
    apiService.getTopDashboardData(duration).then(
      (response) => {
        dispatch(actions.setTopDataGotResponse(true));
        response = response.data;
        if (response.response) {
          dispatch(actions.setTopData(response.response));
        } else {
          dispatch(actions.setTopData());
        }
      },
      (error) => {
        dispatch(actions.setTopData());
      }
    );
  };
}

export function getAndSetTrendChartData(duration) {
  return (dispatch, store) => {
    apiService.getTrendChartData(duration).then(
      (response) => {
        dispatch(actions.setTrendChartDataGotResponse(true));
        response = response.data;
        // //console.log(response.response);
        if (response.response) {
          dispatch(actions.setTrendChartData(response.response));
        } else {
          dispatch(actions.setTrendChartData());
        }
      },
      (error) => {
        dispatch(actions.setTrendChartData());
      }
    );
  };
}

export function getAndSetUsersCountsData(duration) {
  return (dispatch, store) => {
    apiService.getUsersCountsData(duration).then(
      (response) => {
        dispatch(actions.setUsersCountsDataGotResponse(true));
        response = response.data;
        // //console.log(response.response);
        if (response) {
          dispatch(actions.setUsersCountsData(response));
        } else {
          dispatch(actions.setUsersCountsData());
        }
      },
      (error) => {
        dispatch(actions.setUsersCountsData());
      }
    );
  };
}

export function getAndSetTopEmployeesPointsData(
  noOfEmployees,
  durationSent,
  durationReceived
) {
  return (dispatch, store) => {
    apiService
      .getTopEmployeesPointsData(noOfEmployees, durationSent, durationReceived)
      .then(
        (response) => {
          //console.log(response)
          dispatch(actions.setTopEmployeesPointsDataGotResponse(true));
          response = response.data;
          if (response) {
            dispatch(actions.setTopEmployeesPointsData(response));
          } else {
            dispatch(actions.setTopEmployeesPointsData());
          }
        },
        (error) => {
          dispatch(actions.setTopEmployeesPointsData());
        }
      );
  };
}

export function getAndSetDepartmentWisePointsData(
  durationSent,
  durationReceived
) {
  return (dispatch, store) => {
    apiService.getDepartmentWisePointsData(durationSent, durationReceived).then(
      (response) => {
        dispatch(actions.setDepartmentWisePointsDataGotResponse(true));
        response = response.data;
        if (response) {
          dispatch(actions.setDepartmentWisePointsData(response));
        } else {
          dispatch(actions.setDepartmentWisePointsData());
        }
      },
      (error) => {
        dispatch(actions.setDepartmentWisePointsData());
      }
    );
  };
}

export function getandSetAllEmployeesToDepartmentsData(duration) {
  return (dispatch, store) => {
    apiService.getAllEmployeesToDepartmentsData(duration).then(
      (response) => {
        dispatch(actions.setAllEmployeesToDepartmentsDataGotResponse(true));
        response = response.data;
        //console.log(response);
        if (
          response.response &&
          response.response.receivedDetails &&
          response.response.sentDetails
        ) {
          dispatch(actions.setAllEmployeesToDepartmentsData(response.response));
        } else {
          dispatch(actions.setAllEmployeesToDepartmentsData());
        }
      },
      (error) => {
        dispatch(actions.setAllEmployeesToDepartmentsData());
      }
    );
  };
}

export function getAndSetAllEmployeesToDepartmentsChartData(duration) {
  return (dispatch, store) => {
    apiService.getAllEmployeesToDepartmentsChartData(duration).then(
      (response) => {
        dispatch(
          actions.setAllEmployeesToDepartmentsChartDataGotResponse(true)
        );
        response = response.data;
        //console.log(response);
        if (
          response.response &&
          response.response.receivedTransactionsData &&
          response.response.sentTransactionsData
        ) {
          dispatch(
            actions.setAllEmployeesToDepartmentsChartData(response.response)
          );
        } else {
          dispatch(actions.setAllEmployeesToDepartmentsChartData());
        }
      },
      (error) => {
        dispatch(actions.setAllEmployeesToDepartmentsChartData());
      }
    );
  };
}

export function getAndSetAllEmployeesTrendChartData(duration) {
  return (dispatch, store) => {
    apiService.getAllEmployeesTrendChartData(duration).then(
      (response) => {
        dispatch(actions.setAllEmployeesTrendChartDataGotResponse(true));
        response = response.data;
        //console.log(response);
        if (response) {
          dispatch(actions.setAllEmployeesTrendChartData(response));
        } else {
          dispatch(actions.setAllEmployeesTrendChartData());
        }
      },
      (error) => {
        dispatch(actions.setAllEmployeesTrendChartData());
      }
    );
  };
}

export function getAndSetEmployeeDetailsWithDepartmentWiseData(
  employeeId,
  duration
) {
  return (dispatch, store) => {
    dispatch(loaderActions.showLoader());
    apiService
      .getEmployeeDetailsWithDepartmentWiseData(employeeId, duration)
      .then(
        (response) => {
          dispatch(loaderActions.hideLoader());
          dispatch(
            actions.setEmployeeDetailsWithDepartmentWiseDataGotResponse(true)
          );
          response = response.data;
          //console.log(response);
          if (
            response.response &&
            response.response.receivedDetails &&
            response.response.sentDetails
          ) {
            dispatch(
              actions.setEmployeeDetailsWithDepartmentWiseData(
                response.response
              )
            );
          } else {
            dispatch(actions.setEmployeeDetailsWithDepartmentWiseData());
          }
        },
        (error) => {
          dispatch(loaderActions.hideLoader());
          dispatch(actions.setEmployeeDetailsWithDepartmentWiseData());
        }
      );
  };
}

export function getAndSetEmployeeWiseDepartmentsAnalysisChartData(
  employeeId,
  duration
) {
  return (dispatch, store) => {
    apiService
      .getEmployeeWiseDepartmentsAnalysisChartData(employeeId, duration)
      .then(
        (response) => {
          dispatch(
            actions.setEmployeeWiseDepartmentsAnalysisChartDataGotResponse(true)
          );
          response = response.data;
          //console.log(response);
          if (
            response.response &&
            response.response.receivedTransactionsData &&
            response.response.sentTransactionsData
          ) {
            dispatch(
              actions.setEmployeeWiseDepartmentsAnalysisChartData(
                response.response
              )
            );
          } else {
            dispatch(actions.setEmployeeWiseDepartmentsAnalysisChartData());
          }
        },
        (error) => {
          dispatch(actions.setEmployeeWiseDepartmentsAnalysisChartData());
        }
      );
  };
}

export function getAndSetEmployeeTrendChartData(employeeId, duration) {
  return (dispatch, store) => {
    apiService.getEmployeeTrendChartData(employeeId, duration).then(
      (response) => {
        dispatch(actions.setEmployeeTrendChartDataGotResponse(true));
        response = response.data;
        //console.log(response);
        if (response) {
          dispatch(actions.setEmployeeTrendChartData(response));
        } else {
          dispatch(actions.setEmployeeTrendChartData());
        }
      },
      (error) => {
        dispatch(actions.setEmployeeTrendChartData());
      }
    );
  };
}

export function getAndSetDeptTrendChartData(deptId, duration) {
  return (dispatch, store) => {
    apiService.getDeptTrendChartData(deptId, duration).then(
      (response) => {
        dispatch(actions.setDeptTrendChartDataGotResponse(true));
        response = response.data;
        //console.log(response);
        if (response) {
          dispatch(actions.setDeptTrendChartData(response));
        } else {
          dispatch(actions.setDeptTrendChartData());
        }
      },
      (error) => {
        dispatch(actions.setDeptTrendChartData());
      }
    );
  };
}

export function getAndSetDepartmentToDepartmentsData(deptId, duration) {
  return (dispatch, store) => {
    apiService.getDepartmentToDepartmentsData(deptId, duration).then(
      (response) => {
        dispatch(actions.setDepartmentToDepartmentsDataGotResponse(true));
        response = response.data;
        //console.log(response);
        if (response.response) {
          dispatch(actions.setDepartmentToDepartmentsData(response.response));
        } else {
          dispatch(actions.setDepartmentToDepartmentsData());
        }
      },
      (error) => {
        dispatch(actions.setDepartmentToDepartmentsData());
      }
    );
  };
}

export function getAndSetDepartmentToDepartmentsAnalysisData(deptId, duration) {
  return (dispatch, store) => {
    apiService.getDepartmentToDepartmentsAnalysisData(deptId, duration).then(
      (response) => {
        dispatch(
          actions.setDepartmentToDepartmentsAnalysisDataGotResponse(true)
        );
        response = response.data;
        //console.log(response);
        if (response && response.response) {
          dispatch(
            actions.setDepartmentToDepartmentsAnalysisData(response.response)
          );
        } else {
          dispatch(actions.setDepartmentToDepartmentsAnalysisData());
        }
      },
      (error) => {
        dispatch(actions.setDepartmentToDepartmentsAnalysisData());
      }
    );
  };
}

export function getAndSetEmployeeDiffTypesOfPoints(employeeId) {
  return (dispatch, store) => {
    apiService.getEmployeeDiffTypesOfPoints(employeeId).then(
      (response) => {
        dispatch(actions.setEmployeeDiffTypesOfPointsGotResponse(true));
        response = response.data;
        //console.log(response);
        if (response && response.receivedOrderStatus) {
          dispatch(
            actions.setEmployeeDiffTypesOfPoints(response.receivedOrderStatus)
          );
        } else {
          dispatch(actions.setEmployeeDiffTypesOfPoints());
        }
      },
      (error) => {
        dispatch(actions.setEmployeeDiffTypesOfPoints());
      }
    );
  };
}

export function getAndSetEmployeeConnData(employeeId, duration) {
  return (dispatch, store) => {
    dispatch(loaderActions.showLoader());
    apiService.getEmployeeConnData(employeeId, duration).then(
      (response) => {
        dispatch(loaderActions.hideLoader());
        dispatch(actions.setEmployeeConnDataGotResponse(true));
        response = response.data;
        //console.log(response);
        if (response) {
          dispatch(actions.setEmployeeConnData(response));
        } else {
          dispatch(actions.setEmployeeConnData());
        }
      },
      (error) => {
        dispatch(loaderActions.hideLoader());
        dispatch(actions.setEmployeeConnData());
      }
    );
  };
}

export function getAndSetAllEmployeesConnData(duration) {
  return (dispatch, store) => {
    apiService.getAllEmployeesConnData(duration).then(
      (response) => {
        dispatch(actions.setAllEmployeesConnDataGotResponse(true));
        response = response.data;
        //console.log(response);
        if (response) {
          dispatch(actions.setAllEmployeesConnData(response));
        } else {
          dispatch(actions.setAllEmployeesConnData());
        }
      },
      (error) => {
        dispatch(actions.setAllEmployeesConnData());
      }
    );
  };
}

export function getAndSetDeptConnData(deptId, duration) {
  return (dispatch, store) => {
    apiService.getDeptConnData(deptId, duration).then(
      (response) => {
        dispatch(actions.setDeptConnDataGotResponse(true));
        response = response.data;
        //console.log(response);
        if (response) {
          dispatch(actions.setDeptConnData(response));
        } else {
          dispatch(actions.setDeptConnData());
        }
      },
      (error) => {
        dispatch(actions.setDeptConnData());
      }
    );
  };
}

export const clearEmployeeSearchResult = () => {
  return (dispatch, store) => {
    dispatch(actions.setEmployeesSearchResultGotResponse(false));
    dispatch(actions.setEmployeesSearchResult());
  };
};

export function doValidateUSer(vwUserId, callBack) {
  return (dispatch, store) => {
    dispatch(loaderActions.showLoader());
    apiService.validateUser(vwUserId).then(
      (response) => {
        dispatch(loaderActions.hideLoader());
        callBack(response);
      },
      (error) => {
        dispatch(loaderActions.hideLoader());
        callBack(error);
      }
    );
  };
}

export function getHashData(data, callback) {
  return (dispatch) => {
    dispatch(loaderActions.showLoader());
    apiService.getHashData(data).then(
      (response) => {
        dispatch(loaderActions.hideLoader());
        response = response.data;
        callback(true, response);
      },
      (error) => {
        dispatch(loaderActions.hideLoader());
        callback(false, error);
      }
    );
  };
}

export function getAndSetAllEmployees(params) {
  return (dispatch, store) => {
    dispatch(loaderActions.showLoader());
    // apiService.getAllEmployeesSearch(params).then(
    apiService.getAllEmployees(params).then(
      (response) => {
        dispatch(loaderActions.hideLoader());
        dispatch(actions.setAllEmployeesGotResponse(true));
        response = response.data;
        //console.log(response);
        if (response && response.employees) {
          dispatch(actions.setAllEmployees(response.employees));
          dispatch(actions.setAllEmployeesTotalRows(response.totalCount || 0));
        } else {
          dispatch(actions.setAllEmployees());
          dispatch(actions.setAllEmployeesTotalRows(0));
        }
      },
      (error) => {
        dispatch(loaderActions.hideLoader());
        dispatch(actions.setAllEmployees());
        dispatch(actions.setAllEmployeesTotalRows(0));
      }
    );
  };
}

export function getAndSetEmployeesSearchResult(str) {
  return (dispatch, store) => {
    apiService.searchEmployees(str).then(
      (response) => {
        dispatch(actions.setEmployeesSearchResultGotResponse(true));
        response = response.data;
        //console.log(response);
        if (response && response.response) {
          dispatch(actions.setEmployeesSearchResult(response.response));
        } else {
          dispatch(actions.setEmployeesSearchResult());
        }
      },
      (error) => {
        dispatch(actions.setDeptConnData());
      }
    );
  };
}

export function getAndSetDepartments() {
  return (dispatch, store) => {
    apiService.getDepartments().then(
      (response) => {
        dispatch(actions.setDepartmentsGotResponse(true));
        response = response.data;
        //console.log(response);
        if (response && response.response) {
          dispatch(actions.setDepartments(response.response));
        } else {
          dispatch(actions.setDepartments());
        }
      },
      (error) => {
        dispatch(actions.setDepartments());
      }
    );
  };
}

export function getAndSetAllMenuItems() {
  return (dispatch, store) => {
    apiService.getAllMenuItems().then(
      (response) => {
        dispatch(setGotResponse(true));
        response = response.data;
        if (
          response &&
          response.data &&
          response.data[0] &&
          response.data[0].ikeaStoreItems &&
          response.data[0].ikeaStoreItems.length > 0
        ) {
          //here we are doing identification of which amount to be display with item to user
          //it could be based on eatin, familyMember, other any other basis
          let allItems = response.data[0].ikeaStoreItems;
          //getting current order data from store
          if (store().menuItems && store().menuItems.orderData) {
            let orderData = store().menuItems.orderData;
            for (let i = 0; i < allItems.length; i++) {
              if (
                allItems[i].ikeaStoreItemDtls &&
                allItems[i].ikeaStoreItemDtls[0]
              ) {
                let itemDetails = allItems[i].ikeaStoreItemDtls[0];
                //console.log(itemDetails)
                if (orderData.cardNumber && itemDetails.familyPrice) {
                  itemDetails.amount = itemDetails.familyPrice;
                }
                if (
                  orderData.cardNumber &&
                  orderData.orderType == ORDER_TYPE.EAT_IN &&
                  itemDetails.familyEatInPrice
                ) {
                  itemDetails.amount = itemDetails.familyEatInPrice;
                }
              }
            }
          }
          dispatch(setAllMenuItems(allItems));
        } else {
          dispatch(setAllMenuItems([]));
        }
      },
      (error) => {
        //console.log(error);
      }
    );
  };
}

export function getAndSetAllMenuItems1() {
  let categories = [];
  let recommended = [];
  let sideMenuItem = [];
  let addOnDrink = [];
  return (dispatch, store) => {
    apiService.getAllMenuItems1().then(
      (response) => {
        //console.log(response.data);
        dispatch(setGotResponse(true));
        response = response.data;
        if (
          response &&
          response.data &&
          response.data.itemMaster &&
          store().menuItems &&
          store().menuItems.orderData &&
          store().config &&
          store().config.orderConfigFromAPI &&
          store().config.orderConfigFromAPI.categoryList
        ) {
          let allItems = response.data.itemMaster;
          let orderData = store().menuItems.orderData;
          categories = JSON.parse(
            JSON.stringify(store().config.orderConfigFromAPI.categoryList)
          );
          recommended = [];
          sideMenuItem = [];
          addOnDrink = [];
          for (let i = 0; i < allItems.length; i++) {
            //here we are doing identification of which amount to be display with item to user
            //it could be based on eatin, familyMember, other any other basis
            if (orderData.cardNumber && allItems[i].familyPrice) {
              allItems[i].amount = allItems[i].familyPrice;
            }
            if (
              orderData.cardNumber &&
              orderData.orderType == ORDER_TYPE.EAT_IN &&
              allItems[i].familyEatInPrice
            ) {
              allItems[i].amount = allItems[i].familyEatInPrice;
            }

            //we are checking if item sold out
            if (response.data.itemSoldOut) {
              let itemSoldOut = response.data.itemSoldOut;
              for (let m = 0; m < itemSoldOut.length; m++) {
                if (itemSoldOut[m].itemId == allItems[i].posItemID) {
                  allItems[i].soldout = true;
                }
              }
            }

            let weGotItemAndStore = false;
            //find this item in itemSubcategoryWise
            if (response.data.itemSubcategoryWise) {
              let subCategoryWiseItems = response.data.itemSubcategoryWise;
              for (let k = 0; k < subCategoryWiseItems.length; k++) {
                if (subCategoryWiseItems[k].itemId == allItems[i].posItemID) {
                  //here we got this item in this categoryWiseitem
                  //so need to find and put this item with category
                  for (let l = 0; l < categories.length; l++) {
                    if (categories[l].subcategory) {
                      for (
                        let m = 0;
                        m < categories[l].subcategory.length;
                        m++
                      ) {
                        if (
                          categories[l].subcategory[m].subCategoryId ==
                          subCategoryWiseItems[k].subCategoryId
                        ) {
                          if (!categories[l].subcategory[m].items) {
                            categories[l].subcategory[m].items = [];
                          }

                          let itemToBeStore = JSON.parse(
                            JSON.stringify(allItems[i])
                          );
                          itemToBeStore.displayOrder =
                            subCategoryWiseItems[k].displayOrder;
                          categories[l].subcategory[m].items.push(
                            itemToBeStore
                          );
                          weGotItemAndStore = true;
                          break;
                        }
                      }
                    }
                    if (weGotItemAndStore) break;
                  }
                }
                if (weGotItemAndStore) break;
              }
              // if(weGotItemAndStore) {continue}
            }
            //we still not get the item so now we will add this items in diff additional on categories

            //sidemenu
            if (response.data.itemSideMenu) {
              let items = response.data.itemSideMenu;
              for (let k = 0; k < items.length; k++) {
                if (items[k].itemId == allItems[i].posItemID) {
                  let itemToBeStore = JSON.parse(JSON.stringify(allItems[i]));
                  itemToBeStore.displayOrder = items[k].displayOrder;
                  sideMenuItem.push(itemToBeStore);
                  weGotItemAndStore = true;
                  break;
                }
              }
              // if(weGotItemAndStore) {continue}
            }

            //recommended
            if (response.data.itemRecommanded) {
              let items = response.data.itemRecommanded;
              for (let k = 0; k < items.length; k++) {
                if (items[k].itemId == allItems[i].posItemID) {
                  let itemToBeStore = JSON.parse(JSON.stringify(allItems[i]));
                  itemToBeStore.displayOrder = items[k].displayOrder;
                  recommended.push(itemToBeStore);
                  weGotItemAndStore = true;
                  break;
                }
              }
              // if(weGotItemAndStore) {continue}
            }

            //addondrinks
            if (response.data.itemAddOnDrink) {
              let items = response.data.itemAddOnDrink;
              for (let k = 0; k < items.length; k++) {
                if (items[k].itemId == allItems[i].posItemID) {
                  let itemToBeStore = JSON.parse(JSON.stringify(allItems[i]));
                  itemToBeStore.displayOrder = items[k].displayOrder;
                  addOnDrink.push(itemToBeStore);
                  weGotItemAndStore = true;
                  break;
                }
              }
              // if(weGotItemAndStore) {continue}
            }
          }

          //so now will do sorting on that data
          for (let l = 0; l < categories.length; l++) {
            if (categories[l].subcategory) {
              for (let m = 0; m < categories[l].subcategory.length; m++) {
                if (
                  categories[l].subcategory[m] &&
                  categories[l].subcategory[m].items
                ) {
                  categories[l].subcategory[m].items.sort((a, b) =>
                    a.displayOrder > b.displayOrder
                      ? 1
                      : a.displayOrder === b.displayOrder
                      ? a.itemId > b.itemId
                        ? 1
                        : -1
                      : -1
                  );
                }
              }
            }
          }
          recommended.sort((a, b) =>
            a.displayOrder > b.displayOrder
              ? 1
              : a.displayOrder === b.displayOrder
              ? a.itemId > b.itemId
                ? 1
                : -1
              : -1
          );
          addOnDrink.sort((a, b) =>
            a.displayOrder > b.displayOrder
              ? 1
              : a.displayOrder === b.displayOrder
              ? a.itemId > b.itemId
                ? 1
                : -1
              : -1
          );
          sideMenuItem.sort((a, b) =>
            a.displayOrder > b.displayOrder
              ? 1
              : a.displayOrder === b.displayOrder
              ? a.itemId > b.itemId
                ? 1
                : -1
              : -1
          );

          //console.log(categories);
          //console.log(recommended);
          //console.log(addOnDrink);
          //console.log(sideMenuItem);
          dispatch(
            setAllMenuItems1({
              categories,
              recommended,
              addOnDrink,
              sideMenuItem,
            })
          );
        } else {
          dispatch(
            setAllMenuItems1({
              categories,
              recommended,
              addOnDrink,
              sideMenuItem,
            })
          );
        }
      },
      (error) => {
        // //console.log(error);
        dispatch(
          setAllMenuItems1({
            categories,
            recommended,
            addOnDrink,
            sideMenuItem,
          })
        );
      }
    );
  };
}

export function confirmOrder(data, callBack) {
  let requstData = {};
  return (dispatch) => {
    if (data.orderId) {
      if (!data.isOrderConfirmed) {
        //if order already confirmed then no need to confirm again
        requstData.orderId = data.orderId;
        apiService.confirmOrder(requstData).then(
          (response) => {
            response = response.data;
            if (response && response.data && response.data.total != undefined) {
              dispatch(
                setOrderData({
                  orderTotal: response.data.total || 0,
                  orderDiscount: response.data.discount || 0,
                  isOrderConfirmed: true,
                })
              );
              callBack();
            }
          },
          (error) => {
            //console.log(error);
            if (
              error &&
              error.response &&
              error.response &&
              error.response.data &&
              error.response.data.status
            ) {
              dispatch(
                alertPopupActions.showDefaultErrorWithCode(
                  error.response.data.status
                )
              ); //error.response.data.message
            }
          }
        );
      } else {
        callBack();
      }
    }
  };
}

export function confirmPayment(data, paymentStatus, callBack) {
  //console.log(data)
  //paymentStatus can be [CANCEL_BY_USER, PAID]
  let requstData = {};
  return (dispatch) => {
    if (data.orderId) {
      requstData.orderId = data.orderId;
      // requstData.externalId = "CHARGE4"; //data.externalOrderId;
      requstData.paymentStatus = paymentStatus;
      requstData.total = data.orderTotal;

      if (data.paymentResponse) {
        // requstData.total = parseFloat(data.paymentResponse.Amount);
        requstData.tokenId = data.paymentResponse["Order_No"];
        requstData.externalId = data.paymentResponse["selectedPaymentTender"]; //refector
        // requstData.additionalInfoValue = data.paymentResponse["additionalInfovalue"]; //refector
      }

      //console.log(requstData)
      apiService.confirmPayment(requstData).then(
        (response) => {
          response = response.data;
          //console.log(response)
          if (response && response.data && response.data.printBuffer) {
            dispatch(
              setOrderData({
                orderReceiptData: response.data,
              })
            );
            callBack();
          }
        },
        (error) => {
          //console.log(error);
          if (
            error &&
            error.response &&
            error.response &&
            error.response.data &&
            error.response.data.status
          ) {
            dispatch(
              setOrderData({
                orderReceiptData: error.response.data,
              })
            );
            callBack();
            setTimeout(() => {
              dispatch(
                alertPopupActions.showDefaultErrorWithCode(
                  error.response.data.status
                )
              ); //error.response.data.message
            }, 300);
          }
        }
      );
    }
  };
}

export function saveOrder(data, callBack) {
  let requstData = {
    languageCode: data.language.toUpperCase(),
    // "isFamilyCardNumber": true,
    salesCondition: data.orderType,
    // "ikeaFamilyCardNumber": "6275980120150907113"
  };

  if (data.cardNumber) {
    requstData.ikeaFamilyCardNumber = data.cardNumber;
    requstData.familyCardNumber = true;
  }

  requstData.posItems = [];

  if (data.orderItems && data.orderItems.length > 0) {
    for (let i = 0; i < data.orderItems.length; i++) {
      requstData.posItems.push({
        posItemId: data.orderItems[i].posItemID,
        quantity: data.orderItems[i].quantity,
      });
    }
  }

  //console.log(requstData);
  return (dispatch) => {
    if (data.orderId && !data.isOrderConfirmed) {
      // once order confirmed then not update
      requstData.orderId = data.orderId;
      requstData.externalOrderId = data.externalOrderId;

      apiService.updateOrder(requstData).then(
        (response) => {
          //console.log(response);
          response = response.data;
          if (response && response.data && response.data.orderId) {
            dispatch(
              setOrderData({
                orderId: response.data.orderId,
                externalOrderId: response.data.externalOrderId,
                orderTotal: response.data.total || 0,
                orderDiscount: response.data.discount || 0,
              })
            );
            callBack();
          }
        },
        (error) => {
          //console.log(error);
          if (
            error &&
            error.response &&
            error.response &&
            error.response.data &&
            error.response.data.status
          ) {
            dispatch(
              alertPopupActions.showDefaultErrorWithCode(
                error.response.data.status
              )
            ); //error.response.data.message
          }
        }
      );
    } else {
      apiService.createOrder(requstData).then(
        (response) => {
          //console.log(response);
          response = response.data;
          if (response && response.data && response.data.orderId) {
            dispatch(
              setOrderData({
                orderId: response.data.orderId,
                externalOrderId: response.data.externalOrderId,
                orderTotal: response.data.total || 0,
                orderDiscount: response.data.discount || 0,
                isOrderConfirmed: undefined, //unset this property
              })
            );
            callBack();
          }
        },
        (error) => {
          //console.log(error);
          if (
            error &&
            error.response &&
            error.response &&
            error.response.data &&
            error.response.data.status
          ) {
            dispatch(
              alertPopupActions.showDefaultErrorWithCode(
                error.response.data.status
              )
            ); //error.response.data.message
          }
        }
      );
    }
  };
}
