import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ring from '../../images/rolling.svg';
 
class Loader extends Component {
  componentWillMount() {
  }

  constructor(props, context) {
    super(props);
  }

  render() {
    return (
      this.props.showLoader &&
      <div className='loader'>
        <div className="loader-cell">
        {this.props.showLoaderMessage && 
        <span>
          {this.props.showLoaderMessage}
        </span>}
        <img src={ring} className="img-responsive" />
        <span>お待ちください...</span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    showLoader: state.loader.showLoader,
    showLoaderMessage: state.loader.showLoaderMessage
  }
};

const mapDispatchToProps = (dispatch) => {
  return {}
};

Loader.contextTypes = {
  t: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(Loader);
