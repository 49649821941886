import React, { Component } from "react";
import Signup from "../../components/Signup";
import * as authActions from '../../redux/actions/authActions';
import "../../css/style.css";
import history from "../../history";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class SignupScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      confirmPassword: "",
      firstName: "",
      lastName: "",
      dob: "",
      gender: "",
      phoneNumber: "",

      errors: {
        username: "",
        password: "",
        confirmPassword: "",
        firstName: "",
        dob: "",
        gender: "",
        phoneNumber: "",
      },
      isSubmitted: null,
    };
  }

  componentDidMount() {
    // this.props.authActions.doLogout();
  }

  handleChange = (event) => {
    // event.preventDefault();
    const { name, value } = event.target;
    this.setState({ [name]: value }, () => this.isValid());
  };

  handleSubmit = () => {
    this.setState({ isSubmitted: true }, () => {
      if (this.isValid()) {
        const { username, password, confirmPassword, firstName, lastName, dob, gender, phoneNumber } = this.state;
        const user = { username, password, confirmPassword, firstName, lastName, dob, gender, phoneNumber };
        this.props.authActions.doRegister({
          user
        });
      }
    });
  };

  isValid = () => {
    const { username, password, confirmPassword, firstName, lastName, dob, gender, phoneNumber, isSubmitted } = this.state;
    const errors = {};
    let valid = true;

    if (!isSubmitted) {
      return valid;
    }

    if (!username) {
      errors.username = "Require";
    }
    if (!password) {
      errors.password = "Require";
    }
    if (password != confirmPassword) {
      errors.confirmPassword = "Password and confirm password must be same";
    }
    if (!firstName) {
      errors.firstName = "Require";
    }
    if (!lastName) {
      errors.lastName = "Require";
    }

    this.setState({ errors, errors });
    Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
    return valid;
  };


  render() {
    const { handleChange, handleSubmit } = this;
    const props = {
      ...this.state,
      handleChange,
      handleSubmit,
    };
    return <Signup {...props} />;

  }
}

const mapStateToProps = (state) => {
  return {

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authActions: bindActionCreators(authActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignupScreen);
