import React, { Component } from "react";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import "./css/bootstrap.min.css";
import "./css/style.css";
import "react-datepicker/dist/react-datepicker.css";
import { LANGUAGE } from "./App.config";
import { Route, Switch, Router } from "react-router-dom";
import { bindActionCreators } from "redux";
import history from "./history";
import Home from "./containers/Home";
import Unauthorized from "./containers/Unauthorized";
import * as appConfigActions from "./redux/actions/appConfigActions";
import * as alertPopupActions from "./redux/actions/alertPopupActions";
import * as confirmDialogActions from "./redux/actions/confirmDialogActions";
import Loader from "./components/Loader";
import AlertPopup from "./components/AlertPopup";
import messages_ja from "./translations/ja.json";
import messages_en from "./translations/en.json";
import messages_ko from "./translations/ko.json";
import messages_zh from "./translations/zh.json";
import { initAppInsights } from "./AppInsights";
import LoginScreen from "./containers/LoginScreen";
import SignupScreen from "./containers/SignupScreen";
import ForgotPasswordScreen from "./containers/ForgotPasswordScreen";

const messages = {
  ja: messages_ja,
  en: messages_en,
  ko: messages_ko,
  zh: messages_zh,
};

// const language = navigator.language.split(/[-_]/)[0];  // language without region code
const language = LANGUAGE.JA;

class App extends Component {
  constructor(props, context) {
    super(props, context);
    this.props.appConfigActions.setLanguage(language);

    history.listen((event) => {
      // console.log('listen', event.pathname);
      window.scrollTo(0, 0);
    });

    //initialize app insights
    initAppInsights();
  }

  render() {
    const { data } = this.props.confirmDialog;
    const confirmDialogData = data;
    return (
      <IntlProvider
        locale={this.props.config.language}
        messages={messages[this.props.config.language]}
      >
        <Loader />
        {this.props.showAlert && (
          <AlertPopup
            message={this.props.showAlertMessage}
            onClickOk={() => {
              this.props.alertPopupActions.hideAlert();
            }}
          />
        )}
        <div className="main">
          <div>
            <Router history={history}>
              <Switch>
                {/* <Route exact path="/" component={Login} />
                <Route exact path="/forgot-password" component={ForgotPassword} />
                <Route exact path="/change-password" component={ChangePassword} />
                <Route exact path="/reset-password" component={ResetPassword} />
                <Route exact path="/verify" component={ResetPassword} />
                <Route exact path="/faqs" component={Faqs} />*/}
                <Route path="/unauthorized" component={Unauthorized}></Route>
                <Route path="/login" component={LoginScreen}></Route>
                <Route path="/register" component={SignupScreen}></Route>
                <Route path="/forgot-password" component={ForgotPasswordScreen}></Route>
                <Route component={Home} />
              </Switch>
            </Router>
          </div>
        </div>

        {confirmDialogData && (
          <div>
            <div class="tg-backdrop"></div>
            <div class="tg-model">
              <div class="tg-model-title">{confirmDialogData.header || ""}</div>
              <div class="tg-model-body">{confirmDialogData.detail || ""}</div>
              {confirmDialogData.buttons && (
                <div class="tg-model-footer">
                  {confirmDialogData.buttons.map((button) => {
                    return (
                      <button
                        onClick={() => {
                          this.props.confirmDialogActions.hide();
                          button.onClick && button.onClick();
                        }}
                      >
                        {button.label}
                      </button>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        )}
      </IntlProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
    showAlert: state.alertPopup.showAlert,
    showAlertMessage: state.alertPopup.showAlertMessage,
    confirmDialog: state.confirmDialog,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    appConfigActions: bindActionCreators(appConfigActions, dispatch),
    alertPopupActions: bindActionCreators(alertPopupActions, dispatch),
    confirmDialogActions: bindActionCreators(confirmDialogActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
