import { SHOW_CONFIRM_DIALOG, HIDE_CONFIRM_DIALOG } from "../actionTypes";

export default function confirmDialogReducer(
  state = {
    data: null,
  },
  action
) {
  switch (action.type) {
    case SHOW_CONFIRM_DIALOG:
      return Object.assign({}, state, {
        data: action.data,
      });
    case HIDE_CONFIRM_DIALOG:
      return Object.assign({}, state, {
        data: null,
      });
    default:
      return state;
  }
}
