import React, { Component } from "react";
import "../../css/style.css";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as myCardActions from "../../redux/actions/myCardActions";
import history from "../../history";
import { FormattedMessage } from "react-intl";
import QRCode from "react-qr-code";
import * as appHeaderActions from "../../redux/actions/appHeaderActions";

const FM = FormattedMessage;

class QRTemplete extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  componentDidMount() {
    this.props.appHeaderActions.setData({ title: "Scan QR Code", isBack: true });
  }

  onDoneClick = () => {
    history.goBack();
  };

  render() {
    return (
      <>
        <div>
          {/* <div class="Flex-col heading-col">
            <BackButton></BackButton>
            <h1>
              <FM id="Scan QR Code"></FM>
            </h1>
            <img src=""></img>
          </div> */}
          <div class="Card-Detail-Grid">
            <div class="Card-Detail-Card">
              <div class="Card-Detail-Item"><FM id="Ref"></FM></div>
              <div class="Card-Detail-Value">{this.props.qrDetails.refNo}</div>
            </div>
            <div class="Card-Detail-Card">
              <div class="Card-Detail-Item"><FM id="Cards1"></FM></div>
              <div class="Card-Detail-Value">
                {this.props.qrDetails.totalCards}
              </div>
            </div>
            <div class="Card-Detail-Card">
              <div class="Card-Detail-Item"><FM id="Value"></FM></div>
              <div class="Card-Detail-Value">{this.props.qrDetails.value}</div>
            </div>
            <div class="Card-Detail-Card">
              <div class="Card-Detail-Item"><FM id="Total"></FM></div>
              <div class="Card-Detail-Value Card-Detail-Value-Total">
                ¥ {this.props.qrDetails.totalAmount}
              </div>
            </div>
          </div>
          <div>
            <div class="Scan-QR-Code">
              <FM id="Scan the QR Code and Redeem your gift."></FM>
            </div>
            <div class="Scan-QR-Code">
              {/* <img src={QRImage}></img> */}
              {this.props.qrDetails.qrDetails && (
                <QRCode value={this.props.qrDetails.qrDetails} />
              )}
            </div>
          </div>

          <div class="btn-footer">
            <button
              class="btn btn-primary custom-btn"
              onClick={this.onDoneClick}
            >
              <FM id="Done"></FM>
            </button>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    qrDetails: state.myCardReducer.qrDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    mycardActions: bindActionCreators(myCardActions, dispatch),
    appHeaderActions: bindActionCreators(appHeaderActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(QRTemplete);
