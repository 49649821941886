import React from 'react';
import "./style.css";

const RadioGroupField = props => {
    const { errorText, options, name, value, onChange, disabled } = props;

    return (
        <div className='radio-group-field-root'>
            <div className='radio-group-field-radio-group-container'>
                {options && options.map((option) =>
                    <div className='radio-group-field-radio-option' key={option.value}>
                        <input type="radio"
                            disabled={disabled}
                            id={option.value}
                            name={name}
                            value={option.value} onChange={onChange} checked={value === option.value} />
                        <label for={option.value}>{option.label}</label>
                    </div>
                )}
            </div>
            {errorText && <div className='error-text'>{errorText}</div>}
        </div>
    )
}

export default RadioGroupField;  